import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getUserSettings, updateUserSetting } from '../../../actions';

import Spinner from '../../../global/Spinner';
import Icon from '../../../global/Icon';
import { Constants, maskSsn } from '../../../global/Helpers';

export class UserSettingsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            email: '',
            phoneNumber: '',
            emailIsValid: true,
            phoneNumberIsValid: true,
        };

        this.contactEmailInput = React.createRef();
        this.contactPhoneInput = React.createRef();

        this.editClick = this.editClick.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.cancelClick = this.cancelClick.bind(this);
    }

    editClick() {
        this.setState(
            {
                edit: !this.state.edit,
                email: this.props.user.details.emailAddress,
                phoneNumber: this.props.user.details.phoneNumber,
                emailIsValid: true,
                phoneNumberIsValid: true,
            },
            () => {
                this.contactEmailInput.current.focus();
            }
        );
    }

    handleChange(name, event) {
        let propName = name;
        let input = event.target;
        this.setState((state) => {
            state[propName] = input.value;
            return state;
        });
    }

    cancelClick() {
        this.setState({
            edit: !this.state.edit,
            email: this.props.user.details.emailAddress,
            phoneNumber: this.props.user.details.phoneNumber,
        });
    }

    saveClick() {
        if (!this.state.emailIsValid || !this.state.phoneNumberIsValid) {
            return;
        }
        this.setState({
            edit: !this.state.edit,
        });
        this.props.updateUserSetting({
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
        });
    }

    validateInput(event) {
        let inputValue = event.target.value;
        switch (event.target.id) {
            case 'email':
                const emailRegex = Constants.emailRegex();
                this.setState((state) => {
                    state.emailIsValid = !inputValue || inputValue === '' || (inputValue && emailRegex.test(inputValue));
                    return state;
                });
                break;
            case 'phoneNumber':
                const phoneRegex = Constants.phoneNumberRegex();
                this.setState((state) => {
                    state.phoneNumberIsValid = !inputValue || inputValue === '' || (inputValue && phoneRegex.test(inputValue));
                    return state;
                });
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className="settings__detail">
                <div className="settings__user-name">
                    <div className="settings__panel">
                        <p className="paragraph">
                            <strong className="title title--h3">{this.props.userProfile.name}</strong>
                        </p>
                        <p className="paragraph">
                            <small>{maskSsn(this.props.userProfile.sub)}</small>
                        </p>
                        {this.props.user.isLoading ? <Spinner /> : null}
                    </div>
                </div>
                {this.props.user.isLoading ? null : (
                    <React.Fragment>
                        <div className="settings__editable">
                            <div className="settings__panel">
                                <p className="paragraph">
                                    <small>Epost</small>
                                </p>
                                {this.state.edit ? (
                                    <div className="input">
                                        <input
                                            id="email"
                                            ref={this.contactEmailInput}
                                            value={this.state.email}
                                            type="email"
                                            onBlur={this.validateInput.bind(this)}
                                            onChange={this.handleChange.bind(this, 'email')}
                                            className="input__field"
                                            placeholder="Ange epost (mail@exempel.se)"
                                        />
                                        {!this.state.emailIsValid ? <div className="input__required-information">Ange en giltig email-adress</div> : null}
                                    </div>
                                ) : (
                                    <p className="paragraph">
                                        {' '}
                                        {this.props.user.details && this.props.user.details.emailAddress ? (
                                            <strong>{this.props.user.details.emailAddress}</strong>
                                        ) : (
                                            <i>saknas</i>
                                        )}{' '}
                                    </p>
                                )}
                            </div>
                            <div className="settings__panel">
                                <p className="paragraph">
                                    <small>Telefonnummer</small>
                                </p>
                                {this.state.edit ? (
                                    <div className="input">
                                        <input
                                            id="phoneNumber"
                                            ref={this.contactPhoneInput}
                                            value={this.state.phoneNumber}
                                            type="tel"
                                            onBlur={this.validateInput.bind(this)}
                                            onChange={this.handleChange.bind(this, 'phoneNumber')}
                                            className="input__field"
                                            placeholder="Ange telefonnummer"
                                        />
                                        {!this.state.phoneNumberIsValid ? <div className="input__required-information">Endast siffror är tillåtna</div> : null}
                                    </div>
                                ) : (
                                    <p className="paragraph">
                                        {' '}
                                        {this.props.user.details && this.props.user.details.phoneNumber ? (
                                            <strong>{this.props.user.details.phoneNumber}</strong>
                                        ) : (
                                            <i>saknas</i>
                                        )}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="settings__editable--actions">
                            {this.state.edit ? (
                                <div>
                                    <button className="button button--warning" onClick={this.cancelClick}>
                                        Avbryt
                                    </button>
                                    <button className="button" onClick={this.saveClick}>
                                        Spara
                                    </button>
                                </div>
                            ) : (
                                <button className="button button--function" onClick={this.editClick}>
                                    Redigera <Icon name="edit" />
                                </button>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userProfile: state.oidc.user.profile,
});

const mapDispatchToProps = { getUserSettings, updateUserSetting };

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsDetail);
