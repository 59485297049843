import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Motion, spring } from 'react-motion';
import { getTokenRequest } from '../../global/Firebase';
import Icon from '../../global/Icon';
import Spinner from '../../global/Spinner';
import { store } from '../../store';
import {
    modalTrigger,
    pushNotificationDecline,
    pushNotificationSaveToken,
    pushNotificationResetAnswer,
    pushNotificationSaveTokenExternal,
} from '../../actions';

import { isAndroid, isIOS, isChrome, isEdge, isFirefox, isAndroidWebView } from '../Helpers';

export class PushNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initRequest: false,
            informationRequest: false,
            error: null,
        };
        this.decline = this.decline.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showInformation = this.showInformation.bind(this);
        this.back = this.back.bind(this);
        this.requestToken = this.requestToken.bind(this);
        window.reduxActions = bindActionCreators({ pushNotificationSaveTokenExternal }, store.dispatch);
    }
    componentDidMount() {
        this.props.pushNotificationResetAnswer();
    }
    closeModal(event) {
        if (event) event.preventDefault();
        this.props.modalTrigger(false);
    }
    decline(event) {
        event.preventDefault();
        this.props.pushNotificationDecline();
        this.closeModal(event);
    }
    showInformation(event) {
        event.preventDefault();
        if (isAndroid && isAndroidWebView()) {
            return this.requestToken(event);
        } else {
            this.setState({ informationRequest: true, error: null });
        }
    }
    back(event) {
        event.preventDefault();
        this.setState({ informationRequest: false });
    }
    requestToken(event) {
        event.preventDefault();
        if ((isAndroid && isAndroidWebView()) || isIOS) {
            const methodCall = {
                function: 'getFirebasePushToken',
                callbackFunction: 'didRecieveFirebasePushToken',
            };
            // iOS
            if (window.webkit?.messageHandlers?.appmostCallback) {
                window.webkit.messageHandlers.appmostCallback.postMessage(methodCall);
            }
            // Android
            else if (window.appmostCallback?.postMessage) {
                window.appmostCallback.postMessage(JSON.stringify(methodCall));
            }
            return;
        }
        getTokenRequest()
            .then((userToken) => {
                this.props.pushNotificationSaveToken(userToken);
            })
            .catch((error) => {
                this.setState({ error: error.message });
            });
    }
    componentDidUpdate() {
        if (!this.props.pushNotification.showPush) {
            this.closeModal();
        }
    }
    render() {
        return (
            <div className="push-notification">
                {this.state.informationRequest && (
                    <div className="push-notification__content">
                        <h2 className="title title--h2">Aktivera pushnotiser</h2>
                        {isIOS && (
                            <div>
                                <p className="paragraph">
                                    För Iphone-användare behöver ni godkänna i er enhet att vi skickar notiser. Detta görs genom att trycka på "OK" när er
                                    telefon eller surfplatta frågar om detta.
                                </p>
                                <img
                                    src={require('../../img/activate_push_apple.png')}
                                    alt="Exempel för aktivera push"
                                    className="push-notification__screenshots"
                                />
                            </div>
                        )}
                        {isChrome && (
                            <div>
                                <p className="paragraph">När du klickar på "Aktivera" så kan din webbläsare (Chrome) visa följande i adressfältet</p>
                                <p className="paragraph">
                                    <img
                                        src={require('../../img/chrome-block.png')}
                                        className="push-notification__screenshots"
                                        alt="Exempel för Chrome notifiering"
                                    />
                                    <span className="caption caption--spacer-top">
                                        Observera att texten endast visas i någon sekund, efter det visas bara en ikon
                                    </span>
                                </p>
                                <p className="paragraph">
                                    För att du ska få notifieringar måste du först klicka på ikonen i adressfältet och därefter välja{' '}
                                    <strong>"Tillåt för den här webbplatsen"</strong>
                                </p>
                                <p className="paragraph">
                                    <img
                                        src={require('../../img/chrome-allow.png')}
                                        alt="Exempel för aktivera push"
                                        className="push-notification__screenshots"
                                    />
                                    <span className="caption caption--spacer-top">
                                        Om du inte ser ikonen måste du öppna inställningar i Chrome för att tillåta oss att skicka notiser,{' '}
                                        <a
                                            href="https://support.google.com/chrome/answer/3220216?hl=sv&co=GENIE.Platform%3DDesktop"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="link"
                                        >
                                            läs mer på Google
                                        </a>
                                    </span>
                                </p>
                            </div>
                        )}
                        {(isEdge || isFirefox) && (
                            <div>
                                <p className="paragraph">När du klickar på "Aktivera" så visar din webbläsare ett meddelande om att aktivera notifieringar</p>
                                <p className="paragraph">
                                    För att du ska få notifieringar måste du först klicka välja <strong>"Tillåt"</strong>
                                </p>
                            </div>
                        )}
                        {this.props.pushNotification.saving ? (
                            <div>
                                <Spinner />
                            </div>
                        ) : (
                            <div className="push-notification__actions">
                                <div>
                                    <button className="button button--function button--marginleft" onClick={this.back}>
                                        <Icon name="left" />
                                        Tillbaka
                                    </button>

                                    <button type="button" className="button button--right" onClick={this.requestToken}>
                                        Aktivera
                                    </button>
                                </div>
                            </div>
                        )}
                        {this.state.error && (
                            <div>
                                <Motion defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }} style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}>
                                    {(value) => (
                                        <div
                                            className="form__async-status form__async-status--error"
                                            style={{
                                                opacity: value.opacity,
                                                top: value.top,
                                                transform: `scale(${value.scale})`,
                                            }}
                                        >
                                            {this.state.error}
                                        </div>
                                    )}
                                </Motion>
                            </div>
                        )}
                    </div>
                )}
                {!this.state.informationRequest && (
                    <div className="push-notification__content">
                        <h2 className="title title--h2">Vill du aktivera pushnotiser?</h2>
                        <p className="paragraph">Ibland vill vi kunna skicka information till dig som vi tror kan vara till nytta.</p>
                        <p className="paragraph">
                            Om du aktiverar notiser kommer detta ske för alla dina företag som du har hos oss. Du kan själv ändra vilka notiser du vill ha under
                            "Inställningar".
                        </p>
                        <p className="paragraph">
                            Du kan alltid aktivera vid ett senare tillfälle - gå bara in på "Inställningar" i huvudmeny för att komma tillbaka hit!
                        </p>
                        <div className="push-notification__actions">
                            <div>
                                <button type="button" className="button button--function button--marginleft" onClick={this.decline}>
                                    Kanske sen
                                </button>
                                <button type="button" className="button button--right" onClick={this.showInformation}>
                                    Ja tack!
                                </button>
                            </div>
                        </div>
                        {this.state.error && (
                            <div>
                                <Motion defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }} style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}>
                                    {(value) => (
                                        <div
                                            className="form__async-status form__async-status--error"
                                            style={{
                                                opacity: value.opacity,
                                                top: value.top,
                                                transform: `scale(${value.scale})`,
                                            }}
                                        >
                                            {this.state.error}
                                        </div>
                                    )}
                                </Motion>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        pushNotification: state.pushNotification,
    };
};
const mapDispatchToProps = {
    modalTrigger,
    pushNotificationDecline,
    pushNotificationSaveToken,
    pushNotificationResetAnswer,
    pushNotificationSaveTokenExternal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PushNotification);
