import {
    IMPORT_UPDATE,
    IMPORT_UPDATE_LIST,
    IMPORT_INVOICES_TRANSFER,
    IMPORT_INVOICE_SET_SERVICE,
    IMPORT_INVOICE_SET_REMOVE,
    IMPORT_INVOICE_ADD_COMMENT,
    IMPORT_INVOICE_UPDATE_EMAIL,
} from '../actions/index';

import { USER_EXPIRED, SILENT_RENEW_ERROR } from 'redux-oidc';

const initialState = {
    invoices: [],
    loading: false,
};

const findIndex = (invoices, id) => {
    let index = invoices.findIndex((invoice) => {
        if (invoice && invoice.invoiceNumber) {
            return invoice.invoiceNumber === id;
        }
        return false;
    });

    return index;
};

export default function rootReducer(state = initialState, action) {
    let index;
    switch (action.type) {
        case IMPORT_UPDATE:
            return {
                ...state,
                ...action.payload,
            };
        case IMPORT_UPDATE_LIST:
            return {
                ...state,
                invoices: [...action.payload],
            };
        case IMPORT_INVOICES_TRANSFER:
            return { ...state, ...action.payload };
        case IMPORT_INVOICE_SET_SERVICE: {
            index = findIndex(state.invoices, action.payload.id);

            if (index > -1) {
                state.invoices[index].sell = !state.invoices[index].sell;
            }
            return { ...state };
        }
        case IMPORT_INVOICE_SET_REMOVE: {
            index = findIndex(state.invoices, action.payload.id);

            if (index > -1) {
                state.invoices[index].import = !state.invoices[index].import;
            }
            return { ...state };
        }
        case IMPORT_INVOICE_ADD_COMMENT: {
            index = findIndex(state.invoices, action.payload.id);

            if (index > -1) {
                state.invoices[index].comment = action.payload.comment;
            }
            return { ...state };
        }
        case IMPORT_INVOICE_UPDATE_EMAIL: {
            index = findIndex(state.invoices, action.payload.id);

            if (index > -1) {
                state.invoices[index].email = action.payload.email;
            }
            return { ...state };
        }
        case USER_EXPIRED:
        case SILENT_RENEW_ERROR:
            return {
                ...initialState,
            };
        default:
            return { ...state };
    }
}
