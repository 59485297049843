import { HISTORY_UPDATE, HISTORY_INIT, HISTORY_INIT_ERROR, HISTORY_CLEAR } from '../actions/index';

import { USER_EXPIRED, SILENT_RENEW_ERROR } from 'redux-oidc';

const initialState = {
    invoices: [],
    loading: false,
    filter: {
        dueDate: {
            from: null,
            to: null,
        },
        invoiceDate: {
            from: null,
            to: null,
        },
        createdDate: {
            from: null,
            to: null,
        },
        invoiceAmountRange: {
            min: null,
            max: null,
        },
        states: null,
    },
    aggregations: {
        global: {
            invoiceAmountRange: {
                min: null,
                max: null,
            },
            invoiceDate: {
                from: null,
                to: null,
            },
            dueDate: {
                from: null,
                to: null,
            },
            createdDate: {
                from: null,
                to: null,
            },
            states: null,
        },
    },
    query: null,
    page: 1,
    size: 20,
    scrollPosition: null,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case HISTORY_UPDATE:
            return { ...state, ...action.payload };
        case HISTORY_CLEAR:
            return initialState;
        case HISTORY_INIT:
        case USER_EXPIRED:
        case SILENT_RENEW_ERROR:
            return {
                ...initialState,
            };
        case HISTORY_INIT_ERROR:
        default:
            return { ...state };
    }
}
