import { concat, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, switchMap, catchError } from 'rxjs/operators';

import { MATURITY_STRUCTURE_FETCH, maturityStructureUpdate } from '../actions';

let endpoint = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    endpoint = process.env.REACT_APP_API_URL;
}

export const maturityStructureFetchEpic = (action$, store) =>
    action$.ofType(MATURITY_STRUCTURE_FETCH).pipe(
        map((action) => action.payload),
        switchMap((payload) => {
            return concat(
                of(maturityStructureUpdate({ loading: true })),
                ajax
                    .post(`${endpoint}api/invoice/unpaidinvoices`, payload, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        switchMap((data) => {
                            // Keep check of how many invoices we have and total cost of those
                            let invoiceCount = 0;
                            let invoiceCost = 0;
                            if (data && data.invoiceAggregationItems) {
                                data.invoiceAggregationItems.forEach((group, index) => {
                                    invoiceCount += group.count;
                                    invoiceCost += group.amount;
                                });
                            }

                            // Tell store that this is the new state
                            return concat(
                                of(
                                    maturityStructureUpdate({
                                        totalInvoiceCost: invoiceCost,
                                        numberOfInvoices: invoiceCount,
                                        groups: data ? data.invoiceAggregationItems : [],
                                        loading: false,
                                    })
                                )
                            );
                        }),
                        catchError((error) => {
                            console.log("Couldn't fetch maturity structure", error);
                            return of(maturityStructureUpdate({ error: true, loading: false }));
                        })
                    )
            );
        })
    );
