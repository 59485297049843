import { of, concat } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import {
    INTEGRATION_COMPLETE,
    INTEGRATION_HAS_COMPLETED,
    INTEGRATION_INITIATE_ONBOARDING,
    integrationUpdate,
} from '../actions';

let endpoint = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    endpoint = process.env.REACT_APP_API_URL;
}

export const integrationInitiateOnboardingEpic = (action$, store) =>
    action$.ofType(INTEGRATION_INITIATE_ONBOARDING).pipe(
        switchMap((action) => {
            let { oidc, organisation } = store.value;
            return concat(
                of(integrationUpdate({urlLoading: true, onboardingLink: ''})),
                ajax
                    .post(`${endpoint}api/integration/onboarding/initiate`, 
                    {
                        Application: action.payload.partner,
                        InvoiceStartDate: action.payload.invoicesDate,
                        AutoPilot: action.payload.integrationMode === 'Autopilot'
                    },
                    {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${oidc.user.access_token}`,
                        'organisation-number': organisation.organisationNumber,
                    }
                )
                .pipe(
                    switchMap((data) => {
                        if (data.status === 200 && data.response.success) {
                            return of(
                                integrationUpdate({
                                    onboardingLink: data.response.onboardingLink,
                                    resolved: true,
                                    urlLoading: false,
                                })
                            );
                        }
                        return of(
                            integrationUpdate({
                                resolved: false,
                                urlLoading: false,
                            })
                        );
                    })
                )
            );
        })
    )

export const integrationSetCompleteEpic = (action$, store) =>
    action$.ofType(INTEGRATION_COMPLETE).pipe(
        switchMap((action) => {
            let { oidc, organisation } = store.value;
            const onboardingId = action.payload.onboardingId;

            return concat(
                of(integrationUpdate({ loading: true })),
                ajax
                    .get(`${endpoint}api/integration/onboarding/${onboardingId}/complete`, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${oidc.user.access_token}`,
                        'organisation-number': organisation.organisationNumber,
                    })
                    .pipe(
                        switchMap((data) => {
                            if (data.status === 200) {
                                return of(
                                    integrationUpdate({
                                        completed: data.response.success,
                                        connectionState: data.response.connectionState,
                                        loading: false,
                                    })
                                );
                            }
                            return of(
                                integrationUpdate({
                                    loading: false,
                                })
                            );
                        }),
                        catchError(() => {
                            return of(
                                integrationUpdate({
                                    loading: false,
                                })
                            );
                        })
                    )
            );
        })
    );


export const integrationHasCompletedEpic = (action$, store) =>
    action$.ofType(INTEGRATION_HAS_COMPLETED).pipe(
        switchMap(() => {
            let { oidc, organisation } = store.value;
            return concat(
                of(integrationUpdate({ loading: true })),
                ajax
                    .get(`${endpoint}api/integration/onboarding/status`, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${oidc.user.access_token}`,
                        'organisation-number': organisation.organisationNumber,
                    })
                    .pipe(
                        switchMap((data) => {
                            if (data.status === 200) {
                                return of(
                                    integrationUpdate({
                                        completed: data.response.connectionState === 'Open',
                                        partner: data.response.application,
                                        autoPilot: data.response.autoPilot,
                                        loading: false,
                                    })
                                );
                            }
                            return of(
                                integrationUpdate({
                                    loading: false,
                                })
                            );
                        }),
                        catchError(() => {
                            return of(
                                integrationUpdate({
                                    loading: false,
                                })
                            );
                        })
                    )
            );
        })
    );
