export const RECIPIENTS_UPDATE = 'RECIPIENTS_UPDATE';
export const RECIPIENTS_INIT = 'RECIPIENTS_INIT';
export const RECIPIENTS_FILTER = 'RECIPIENTS_FILTER';
export const RECIPIENTS_CLEAR_ERRORS = 'RECIPIENTS_CLEAR_ERRORS';
export const RECIPIENTS_PAGINATE = 'RECIPIENTS_PAGINATE';
export const RECIPIENTS_CLEAR = 'RECIPIENTS_CLEAR';

export const recipientsUpdate = (data) => ({
    type: RECIPIENTS_UPDATE,
    payload: data,
});

export const recipientsInit = (initObj) => {
    return {
        type: RECIPIENTS_INIT,
        payload: initObj,
    };
};

export const recipientsFilter = (query) => {
    return {
        type: RECIPIENTS_FILTER,
        payload: {
            query: query,
        },
    };
};

export const recipientsClearErrors = () => {
    return {
        type: RECIPIENTS_CLEAR_ERRORS,
    };
};

export const recipientsPaginate = () => {
    return {
        type: RECIPIENTS_PAGINATE,
    };
};

export const recipientsClear = () => {
    return {
        type: RECIPIENTS_CLEAR,
    };
};
