import { NOTIFICATION_ROUTE_FETCH, NOTIFICATION_ROUTE_UPDATE } from '../actions/index';

const initialState = {
    loading: false,
    resolved: false,
    routeUrl: '',
    messageId: '',
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATION_ROUTE_FETCH:
            return { ...state, messageId: action.payload };
        case NOTIFICATION_ROUTE_UPDATE:
            return { ...state, ...action.payload };
        default:
            return { ...state };
    }
}
