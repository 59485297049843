import { PUSH_NOTIFICATION_UPDATE_SETTINGS } from '../actions/index';

const initialState = {
    showPush: false,
    userToken: '',
    declined: false,
    initRequest: false,
    saving: false,
    mutePush: false,
    hasSavedUserToken: false,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case PUSH_NOTIFICATION_UPDATE_SETTINGS:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return { ...state };
    }
}
