import React, { Component } from 'react';
import { connect } from 'react-redux';

import { stateMapper } from './State';
import Spinner from './Spinner';
import Icon from './Icon';

class Messages extends Component {
    componentWillUnmount() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }
    closeModal(event) {
        event.preventDefault();
        event.persist();
        this.props.modalTrigger(false);
    }
    render() {
        let { history } = this.props;
        let { details } = this.props.history;
        return (
            <div className="messages">
                <h2 className="title title--h2">Händelser</h2>
                {history.loading || !details ? (
                    <React.Fragment>
                        {history.loading ? (
                            <div className="messages__container messages__container--loader">
                                <Spinner />
                            </div>
                        ) : null}
                        {!history.loading && !details ? <p className="caption">Kunde inte hämta information</p> : null}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {[...details.states].reverse().map((state, index) => (
                            <div className="messages__container" key={index}>
                                <div className="messages__author">
                                    {state.addedBy === 'Keeros' ? 'FG' : state.addedBy}
                                    <span className="messages__timestamp">
                                        {new Intl.DateTimeFormat('sv', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(state.timestamp))}
                                    </span>
                                </div>
                                <div
                                    className={(() => {
                                        if (stateMapper[state.state].class === 'wait') {
                                            return 'messages__item messages__item--wait';
                                        }
                                        if (stateMapper[state.state].class === 'warning') {
                                            return 'messages__item messages__item--warning';
                                        }
                                        if (stateMapper[state.state].class === 'function') {
                                            return 'messages__item messages__item--function';
                                        }
                                        if (stateMapper[state.state].class === 'approve') {
                                            return 'messages__item messages__item--approve';
                                        }
                                        return 'messages__item';
                                    })()}
                                >
                                    {!state.comment && state.state ? (
                                        <React.Fragment>
                                            <div className="paragraph">
                                                {(() => {
                                                    switch (state.state) {
                                                        case 'Registered':
                                                            return <React.Fragment>Fakturan registrerades</React.Fragment>;
                                                        case 'Reserved':
                                                                return <React.Fragment>Hantering av fakturan</React.Fragment>;                                                            
                                                        case 'Rejected':
                                                            return <React.Fragment>Fakturan har avvisats av Factoringgruppen</React.Fragment>;
                                                        case 'Approved':
                                                            return <React.Fragment>Fakturan godkändes</React.Fragment>;
                                                        case 'PendingRecipientApproval':
                                                            return <React.Fragment>Fakturan inväntar godkännande från mottagare</React.Fragment>;
                                                        case 'Payed':
                                                            return <React.Fragment>Fakturan betalades</React.Fragment>;
                                                        case 'Reminder1':
                                                            return <React.Fragment>Första påminnelsen skickad till mottagare</React.Fragment>;
                                                        case 'Reminder2':
                                                            return <React.Fragment>Andra påminnelsen skickad till mottagare</React.Fragment>;
                                                        case 'DebtCollector':
                                                            return <React.Fragment>Fakturan är skickad till inkasso</React.Fragment>;
                                                        case 'Clearing':
                                                            return <React.Fragment>Fakturan är avräknad</React.Fragment>;
                                                        case 'PendingRepurchasing':
                                                            return <React.Fragment>Fakturan är återförd till er</React.Fragment>;
                                                        case 'Ready':
                                                            return <React.Fragment>Allt är klart!</React.Fragment>;
                                                        case 'PaymentIssuerApplication':
                                                            return <React.Fragment>Fordran har skickats till kronofogdemyndigheten</React.Fragment>;
                                                        case 'PaymentPlan':
                                                            return <React.Fragment>Mottagaren har ansökt om betalplan för att betala fordran</React.Fragment>;
                                                        case 'Recalled':
                                                            return <React.Fragment>Fakturan är återkallad</React.Fragment>;
                                                        case 'PaymentIssuerDecision':
                                                            return <React.Fragment>{stateMapper['PaymentIssuerDecision'].text}</React.Fragment>;
                                                        case 'PaymentIssuerDistraint':
                                                            return <React.Fragment>{stateMapper['PaymentIssuerDistraint'].text}</React.Fragment>;
                                                        case 'DebtCollectorWarning':
                                                            return <React.Fragment>Varning om att fordran skickas till inkasso har skickats</React.Fragment>;
                                                        case 'ApprovedForDelivery':
                                                            return <React.Fragment>Leverantör har godkänd fakturan</React.Fragment>;
                                                        case 'RejectedByRecipient':
                                                            return <React.Fragment>Mottagare har avvisat fakturan</React.Fragment>;
                                                        case 'EnforcementAuthorityWarning':
                                                            return (
                                                                <React.Fragment>
                                                                    Varning om att fordran skickas till kronofogdemyndigheten har skickats
                                                                </React.Fragment>
                                                            );
                                                        case 'MessageAdded':
                                                            return (
                                                                <React.Fragment>
                                                                    {state.title}
                                                                    <div className="messages__system-info">{state.message}</div>
                                                                </React.Fragment>
                                                            );
                                                        default:
                                                            return <React.Fragment>Okänd status</React.Fragment>;
                                                    }
                                                })()}
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                    {state.addedBy === 'Keeros' || state.addedBy === 'FG' || state.addedBy === null ? (
                                        <React.Fragment>
                                            {stateMapper[state.state].class === 'wait' ? (
                                                <div className="messages__icon">
                                                    <Icon name="wait-status" />
                                                </div>
                                            ) : null}
                                            {stateMapper[state.state].class === 'approve' ? (
                                                <div className="messages__icon">
                                                    <Icon name="corner-icon" />
                                                </div>
                                            ) : null}
                                            {stateMapper[state.state].class === 'warning' ? (
                                                <div className="messages__icon">
                                                    <Icon name="corner-icon" />
                                                </div>
                                            ) : null}
                                            {stateMapper[state.state].class === 'function' ? (
                                                <div className="messages__icon">
                                                    <Icon name="corner-icon" />
                                                </div>
                                            ) : null}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {stateMapper[state.state].class === 'function' ? (
                                                <div className="messages__icon">
                                                    <Icon name="corner-icon" />
                                                </div>
                                            ) : null}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        ))}
                        {[...details.states].length === 0 ? <div className="caption">Hittade inga händelser</div> : null}
                    </React.Fragment>
                )}
                {/*
                    // Kept here for reference only. This is how user generated content will look like.
                    <div className="messages__container messages__container--user">
                        <div className="messages__author">
                            Namn Namnsson
                            <span className="messages__timestamp">
                                1 minut sedan
                            </span>
                        </div>
                        <div className="messages__item">
                            Lorem ipsum
                            <div className="messages__system-info">
                                (System information goes here)
                            </div>
                        </div>
                    </div>
                */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        history: state.history,
    };
};

Messages = connect(mapStateToProps)(Messages);

export default Messages;
