import React, { Component } from 'react';
import { connect } from 'react-redux';

import { modalTrigger, organisationGetCommitments, organisationChooseCommitment } from '../../actions';
import Spinner from '../Spinner';
import Icon from '../Icon';
import UserHandler from '../UserHandler';
import AddContactInformation from './AddContactInformation';
import { isWebView, Constants } from '../Helpers';

// The first modal the user sees when loggin in. This is where they initially picks what commitment they want to work with now.
class ChooseCommitment extends Component {
    constructor(props) {
        super(props);
        if (props.oidc.user && props.organisation.list.length === 0) {
            props.organisationGetCommitments();
        }
    }

    closeModal(event) {
        event.preventDefault();
        this.props.modalTrigger(false);
    }

    getOnBoardingUrl(commitment) {
        if (commitment.status !== 'CurrentlyOnboarding') {
            return null;
        }

        if(isWebView())
        {
            return  process.env.REACT_APP_REGISTER_URL + Constants.webkitTargetNativeBrowserUrlHash();
        }

        return process.env.REACT_APP_REGISTER_URL;
    }

    chooseCommitment(commitment, event) {
        if (commitment.status !== 'CurrentlyOnboarding') {
            event.preventDefault();
            this.props.organisationChooseCommitment(commitment);
        }
    }

    render() {
        let { organisation } = this.props;
        return (
            <React.Fragment>
                <div className="commitment-picker">
                    <UserHandler></UserHandler>
                    {organisation.showContactInfoModal ? (
                        <AddContactInformation></AddContactInformation>
                    ) : (
                        <React.Fragment>
                            {organisation.list.length > 0 ? (
                                <React.Fragment>
                                    <h2 className="title title--h2">
                                        Dina företag
                                        {organisation.chooseCommitmentIsLoading ? (
                                            <div className="commitment-picker__inline-spinner">
                                                <Spinner />
                                            </div>
                                        ) : null}
                                    </h2>
                                    <div className="commitment-picker__list">
                                        {organisation.list.map((commitment, index) => {
                                            return (
                                                <a href={this.getOnBoardingUrl(commitment)} target="_blank" rel="noopener noreferrer">
                                                    <button
                                                        disabled={organisation.chooseCommitmentIsLoading}
                                                        className={
                                                            commitment.status === 'NonActive' ||
                                                            commitment.status === 'Active' ||
                                                            commitment.status === 'CurrentlyOnboarding'
                                                                ? 'commitment-picker__item'
                                                                : 'commitment-picker__item commitment-picker__item--disabled'
                                                        }
                                                        key={index}
                                                        onClick={this.chooseCommitment.bind(this, commitment)}
                                                    >
                                                        <div className="commitment-picker__panel">
                                                            <p className="paragraph">
                                                                <strong>{commitment.name}</strong>
                                                            </p>
                                                            <p className="caption">{commitment.organisationNumber}</p>
                                                        </div>
                                                        <div className="commitment-picker__panel">
                                                            {(() => {
                                                                switch (commitment.status) {
                                                                    case 'NotYetCustomer':
                                                                        return <p className="caption">Ej kund ännu</p>;
                                                                    case 'CompletedOnboarding':
                                                                        return <p className="caption">Har genomfört registrering</p>;
                                                                    case 'IncompleteOnboarding':
                                                                        return <p className="caption">Har genomfört registrering</p>;
                                                                    case 'CurrentlyOnboarding':
                                                                        return <p className="caption">Under registrering</p>;
                                                                    case 'NonActive':
                                                                        return <p className="caption">Är kund, ej aktiv</p>;
                                                                    case 'Active':
                                                                        return <p className="caption">Är kund</p>;
                                                                    case 'CantBecomeCustomer':
                                                                        return <p className="caption">Kan inte bli kund</p>;
                                                                    default:
                                                                        return <p className="caption">Ej kund ännu</p>;
                                                                }
                                                            })()}
                                                        </div>
                                                        <div className="commitment-picker__panel">
                                                            {commitment.status === 'NonActive' || commitment.status === 'Active' ? <Icon name="right" /> : null}
                                                            {commitment.status === 'CurrentlyOnboarding' ? <Icon name="external" /> : null}
                                                        </div>
                                                    </button>
                                                </a>
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            ) : (!organisation.isLoading && organisation.error && organisation.error.type === 'FETCHFAIL') ||
                              (!organisation.isLoading && organisation.list.length === 0) ? (
                                <React.Fragment>
                                    <h2 className="title title--h2">Hm, du verkar inte vara kopplad till något företag.</h2>
                                    <p className="paragraph">
                                        Om det företag du är aktiv inom inte syns här kan det bero på att det är nystartat eller att våra system inte kunde
                                        hitta det. Kontakta{' '}
                                        <a className="link" href="mailto:kund@fg.se">
                                            kund@fg.se
                                        </a>{' '}
                                        för personlig support.
                                    </p>
                                </React.Fragment>
                            ) : null}
                        </React.Fragment>
                    )}

                    {organisation.error && organisation.error.message && organisation.error.type === 'GENERIC' ? (
                        <p className="title title--h4">{organisation.error.message}</p>
                    ) : null}
                    {organisation.isLoading && !organisation.organisationNumber ? (
                        <React.Fragment>
                            <h2 className="title title--h2">Hämtar företag</h2>
                            <Spinner />
                        </React.Fragment>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        validateAccount: state.validateAccount,
        organisation: state.organisation,
        oidc: state.oidc,
    };
};

ChooseCommitment = connect(mapStateToProps, {
    modalTrigger,
    organisationGetCommitments,
    organisationChooseCommitment,
})(ChooseCommitment);

export default ChooseCommitment;
