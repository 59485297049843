import { of, concat } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { NOTIFICATION_ROUTE_FETCH, notificationRouteUpdate } from '../actions';

let endpoint = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    endpoint = process.env.REACT_APP_API_URL;
}
export const notificationRouteFetchEpic = (action$, store) =>
    action$.ofType(NOTIFICATION_ROUTE_FETCH).pipe(
        switchMap((action) => {
            let { oidc, organisation } = store.value;
            const request = {
                messagePartitionKey: oidc.user.profile.sub,
                messageId: action.payload,
            };
            return concat(
                of(notificationRouteUpdate({ loading: true })),
                ajax
                    .post(`${endpoint}api/notification/getnotification`, request, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${oidc.user.access_token}`,
                        'organisation-number': organisation.organisationNumber,
                    })
                    .pipe(
                        switchMap((data) => {
                            if (data.status === 200) {
                                let validCompany = false;
                                let routerUrl = '/hem';
                                let routeParams = '';
                                switch (data.response.template) {
                                    case 'NotificationMessageFromOperations':
                                        if (data.response.templateData.InvoiceNumber) {
                                            routerUrl = `/historik/fakturor/${data.response.templateData.InvoiceNumber}`;
                                            validCompany = organisation.list.some((a) => a.organisationNumber === data.response.organizationNumber);
                                        }
                                        break;
                                    case 'NotificationInvoicePurchased':
                                        routerUrl = '/historik/fakturor';
                                        routeParams = `status=${data.response.templateData.InvoiceState}`;
                                        validCompany = organisation.list.some((a) => a.organisationNumber === data.response.organizationNumber);
                                        break;
                                    default:
                                        routerUrl = '/hem';
                                        validCompany = true;
                                        break;
                                }
                                return of(
                                    notificationRouteUpdate({
                                        resolved: validCompany,
                                        loading: false,
                                        switchToCommiment:
                                            data.response.organizationNumber === organisation.organisationNumber
                                                ? null
                                                : organisation.list.find((a) => a.organisationNumber === data.response.organizationNumber),
                                        routeUrl: routerUrl,
                                        routeParams: routeParams,
                                    })
                                );
                            }
                            return of(
                                notificationRouteUpdate({
                                    resolved: false,
                                    loading: false,
                                })
                            );
                        }),
                        catchError(() => {
                            return of(
                                notificationRouteUpdate({
                                    resolved: false,
                                    loading: false,
                                })
                            );
                        })
                    )
            );
        })
    );
