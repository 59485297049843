import { of, concat } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { VERSION_CHECK_GET } from '../actions';

let endpoint = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    endpoint = process.env.REACT_APP_API_URL;
}
export const checkForNewVersionEpic = (action$) =>
    action$.ofType(VERSION_CHECK_GET).pipe(
        switchMap(() => {
            return concat(
                ajax
                    .post(
                        `${endpoint}api/version/checkversion`,
                        {
                            app: process.env.REACT_APP_NAME,
                            currentVersion: process.env.REACT_APP_VERSION,
                        },
                        {
                            'Content-Type': 'application/json',
                        }
                    )
                    .pipe(
                        switchMap((data) => {
                            if (data.status != 200 || data.response) refreshApp();
                            return of();
                        }),
                        catchError(() => {
                            return of();
                        })
                    )
            );
        })
    );
function refreshApp() {
    console.log('exec refreshApp');
    const methodCall = {
        function: 'clearWebViewCacheWithReload',
    };
    // iOS
    if (window.webkit?.messageHandlers?.appmostCallback) {
        window.webkit.messageHandlers.appmostCallback.postMessage(methodCall);
    }
}
