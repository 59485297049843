import {
    UPLOAD_UPDATE,
    UPLOAD_ADD_FILES,
    UPLOAD_UPLOAD_FILE,
    UPLOAD_UPLOAD_PROGRESS,
    UPLOAD_UPLOAD_SUCCESS,
    UPLOAD_UPLOAD_FAILURE,
    UPLOAD_UPDATED_FILE_LIST,
    UPLOAD_VALIDATE_INVOICE,
    UPLOAD_VALIDATE_FAIL,
    UPLOAD_VALIDATE_SUCCESS,
    UPLOAD_FIND_EXISTING_UPLOADS,
    UPLOAD_INVOICES_TRANSFER,
    UPLOAD_FILE_INVOICE_SET_SERVICE,
    UPLOAD_INVOICES_TRANSFER_FAILURE,
    UPLOAD_FILE_ADD_COMMENT,
    UPLOAD_FILE_UPDATE_EMAIL,
    UPLOAD_TRANSFER_SET_CHANGE,
    UPLOAD_ADD_ATTACHMENT,
    UPLOAD_REMOVED_ATTACHMENT,
} from '../actions/index';
import { USER_EXPIRED, SILENT_RENEW_ERROR } from 'redux-oidc';
const initialState = {
    files: [],
    transfer: {},
    isLoading: false,
};

const findIndex = (files, interpretationId) => {
    let index = files.findIndex((fileObj) => {
        if (fileObj.invoice && fileObj.invoice.interpretationId) {
            return fileObj.invoice.interpretationId === interpretationId;
        }
        if (fileObj.invoice && fileObj.invoice.id) {
            return fileObj.invoice.id === interpretationId;
        }
        if (fileObj.interpretationId) {
            return fileObj.interpretationId === interpretationId;
        }
        if (fileObj.id) {
            return fileObj.id === interpretationId;
        }
        return false;
    });

    return index;
};

export default function rootReducer(state = initialState, action) {
    let index;
    let files;
    switch (action.type) {
        case UPLOAD_UPDATE:
            return { ...state, ...action.payload };
        case UPLOAD_ADD_FILES:
            return {
                files: [...state.files, ...action.payload],
            };
        case UPLOAD_UPLOAD_FILE:
            return {
                files: [...state.files, action.payload],
            };
        case UPLOAD_UPDATED_FILE_LIST:
            return {
                files: [...action.payload],
            };
        case UPLOAD_UPLOAD_PROGRESS:
        case UPLOAD_UPLOAD_SUCCESS:
        case UPLOAD_UPLOAD_FAILURE:
            files = state.files.map((fileObj) => {
                if ((action.payload.interpretationId && action.payload.interpretationId === fileObj.interpretationId) || fileObj.file === action.payload.file) {
                    return { ...fileObj, ...action.payload };
                }
                return fileObj;
            });
            return {
                files: [...files],
            };
        case UPLOAD_VALIDATE_INVOICE:
            index = findIndex(state.files, action.payload.id);

            if (index > -1) {
                state.files[index].isLoading = true;
            }
            return { ...state };
        case UPLOAD_VALIDATE_FAIL:
            index = findIndex(state.files, action.payload.id);

            if (index > -1) {
                state.files[index].errorFields = [...action.payload.errors];
                state.files[index].isLoading = false;
            }
            return { ...state };
        case UPLOAD_VALIDATE_SUCCESS:
            index = findIndex(state.files, action.payload.id);

            if (index > -1) {
                state.files[index].invoice.invoice = action.payload.invoice;
                state.files[index].isLoading = false;
                if (state.files[index].errorFields) {
                    delete state.files[index].errorFields;
                }
            }
            return { ...state };
        case UPLOAD_FIND_EXISTING_UPLOADS:
            return {
                ...state,
                files: [],
            };
        case UPLOAD_INVOICES_TRANSFER:
            return {
                ...state,
                transfer: {},
            };
        case UPLOAD_FILE_INVOICE_SET_SERVICE:
            const interpretationId = action.payload.interpretationId;
            index = findIndex(state.files, interpretationId);

            if (index > -1) {
                state.files[index].sell = !state.files[index].sell;
            }
            return { ...state };
        case UPLOAD_INVOICES_TRANSFER_FAILURE:
            return {
                ...state,
                ...action.payload,
            };
        case UPLOAD_FILE_ADD_COMMENT: {
            const index = action.payload.index;
            if (index > -1 && state.files[index]) {
                state.files[index].comment = action.payload.comment;
            }
            return { ...state };
        }
        case UPLOAD_FILE_UPDATE_EMAIL: {
            const index = action.payload.index;
            if (index > -1 && state.files[index]) {
                state.files[index].email = action.payload.email;
            }
            return { ...state };
        }
        case UPLOAD_ADD_ATTACHMENT: {
            if (action.payload.attachmentErrorText && action.payload.attachmentErrorText.length > 0) {
                return {
                    ...state,
                    attachmentErrorText: action.payload.attachmentErrorText,
                };
            }
            index = action.payload.index;
            files = state.files.map((fileObj, i) => {
                if (i === index) {
                    if (fileObj.attachments == null) {
                        fileObj.attachments = [];
                    }
                    fileObj.attachmentErrorText = '';
                    fileObj.attachments.push(action.payload.file.name);
                    return { ...fileObj };
                }

                return fileObj;
            });

            return {
                files: [...files],
                attachmentErrorText: '',
            };
            //return {...state}
        }
        case UPLOAD_REMOVED_ATTACHMENT: {
            if (action.payload.attachmentErrorText && action.payload.attachmentErrorText.length > 0) {
                return {
                    ...state,
                    attachmentErrorText: action.payload.attachmentErrorText,
                };
            }
            index = findIndex(state.files, action.payload.interpretationId);
            files = state.files.map((fileObj, i) => {
                if (i === index) {
                    if (fileObj.attachments == null) {
                        fileObj.attachments = [];
                    }
                    fileObj.attachmentErrorText = '';
                    fileObj.attachments = fileObj.attachments.filter(function (item) {
                        return item !== action.payload.fileName;
                    });
                    return { ...fileObj };
                }
                return fileObj;
            });

            return {
                files: [...files],
                attachmentErrorText: '',
            };
        }
        case UPLOAD_TRANSFER_SET_CHANGE: {
            const index = action.payload.index;
            if (index > -1 && state.files[index]) {
                state.files[index].transfer = !state.files[index].transfer;
            }
            return { ...state };
        }
        case USER_EXPIRED:
        case SILENT_RENEW_ERROR:
            return {
                ...initialState,
            };
        default:
            return { ...state };
    }
}
