import { concat, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import { IMPORT_GET_NON_IMPORTED, IMPORT_INVOICES_TRANSFER, importUpdate, importUpdateList, importGetNonImported } from '../actions';

let endpoint = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    endpoint = process.env.REACT_APP_API_URL;
}

export const importGetNonImportedEpic = (action$, store) =>
    action$.ofType(IMPORT_GET_NON_IMPORTED).pipe(
        switchMap((action) => {
            // Tells application that imported invoices is loading, and starts fetching imported invoices.
            return concat(
                of(importUpdate({ loading: true })),
                ajax({
                    method: 'GET',
                    url: `${endpoint}api/invoiceImport/list`,
                    headers: {
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    },
                }).pipe(
                    map((ajaxResponse) => ajaxResponse.response),
                    switchMap((data) => {
                        return concat(of(importUpdate({ loading: false })), of(importUpdateList([...data])));
                    }),
                    catchError((error) => {
                        console.log("Couldn't fetch invoices", error);
                        return of(importUpdate({ error: true, loading: false }));
                    })
                )
            );
        })
    );

export const importInvoicesTransferEpic = (action$, store) =>
    action$.ofType(IMPORT_INVOICES_TRANSFER).pipe(
        switchMap((action) => {
            const invoices = action.payload;
            let isDeletion = invoices.every((s) => !s.import && !s.sell);
            return concat(
                of(importUpdate({ loading: true, successfullyTransferred: false, isDeletion: false })),
                ajax
                    .post(`${endpoint}api/invoiceImport/save`, invoices, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        switchMap((response) => {
                            return concat(
                                of(importUpdate({ loading: false, successfullyTransferred: true, isDeletion: isDeletion })),
                                of(importGetNonImported())
                            );
                        })
                    )
            );
        }),
        catchError((error) => {
            return of(
                importUpdate({
                    loading: false,
                    successfullyTransferred: false,
                    error: {
                        message:
                            'Något gick fel när fakturorna skulle överföras. Detta kan bero på nätverksfel eller att något gick fel i våra system. Vänligen försök igen.',
                        type: 'HANDLEIMPORTINVOICETERROR',
                    },
                })
            );
        })
    );
