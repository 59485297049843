import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import HistoryTransfercostsFilter from './components/HistoryTransfercostsFilter';
import HistoryTransfercostsDetails from './components/HistoryTransfercostsDetails';

class HistoryTransfercostsPage extends Component {
    constructor(props) {
        super(props);
        let state = {
            detailsWillOpenInModal: false,
        };
        if (window.matchMedia('(max-width: 1080px)').matches) {
            state.detailsWillOpenInModal = true;
        }

        this.state = state;

        const resizeObs = fromEvent(window, 'resize');
        this.subscriber = [];
        this.subscriber.push(resizeObs.pipe(debounceTime(25)).subscribe(this.setOpenInModalState.bind(this)));
    }
    componentWillUnmount() {
        this.subscriber.forEach((sub) => {
            sub.unsubscribe();
        });
    }
    setOpenInModalState() {
        this.setState((state) => {
            if (window.matchMedia('(max-width: 1080px)').matches) {
                state.detailsWillOpenInModal = true;
            } else {
                state.detailsWillOpenInModal = false;
            }
            return state;
        });
    }
    render() {
        return (
            <div className="content__body history">
                <div className="content__menu content__menu--filter content__menu--transfer-cost content__menu--sticky">
                    <HistoryTransfercostsFilter />
                </div>
                {this.state.detailsWillOpenInModal ? null : (
                    <div className="content__main content__main--stuffing">
                        <HistoryTransfercostsDetails />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        messages: state.messages,
        router: state.router,
        history: state.history,
        transfercosts: state.transfercosts,
    };
};

HistoryTransfercostsPage = connect(mapStateToProps)(HistoryTransfercostsPage);

export default HistoryTransfercostsPage;
