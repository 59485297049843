import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';

import './styles.scss';

import { modalTrigger, organisationUpdate, checkForNewVersion } from './actions';

// Components
import Navigation from './global/Navigation';
import Modal from './global/Modal';
import ProtectedRoute from './global/ProtectedRoute';
import Spinner from './global/Spinner';

// Pages
import LoginPage from './pages/login/LoginPage';
import MaintenancePage from './pages/maintenance/MaintenancePage';

// Routes
import { routes } from './RouteSettings';

// Modals
import ChooseCommitment from './global/modals/ChooseCommitment';
import PushNotification from './global/modals/PushNotification';

// UserManager
import { userManager, store } from './store';

const Error = (props) => {
    return (
        <div className="content__body">
            <div className="content__main content__main--404">
                <div className="content__wrapper content__wrapper--center">
                    <span className="title title--huge">
                        <div className="title__huge">404</div>
                    </span>
                    <h1 className="title title--h1">Oooopss..!</h1>
                    <p className="caption">Här var det alldeles tomt. Finns ingenting. Nada.</p>
                    <NavLink className="button button--function" to="/">
                        Ta mig någon annanstans
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

class App extends Component {
    constructor(props) {
        super(props);
        var query = this.props.location?.search ? queryString.parse(this.props.location.search, { arrayFormat: 'comma' }) : null;

        let isMaintenance = false;
        if (query?.maintenance) {
            isMaintenance = !(query.maintenance === 'devs');
            localStorage.setItem('maintenanceMode', 'off');
        } else {
            const maintenanceMode = localStorage.getItem('maintenanceMode');
            if (maintenanceMode && maintenanceMode === 'off') {
                isMaintenance = false;
            }
        }

        this.state = {
            isMaintenance: isMaintenance,
        };
        userManager.events.addSilentRenewError(function (error) {
            console.log(error);
        });
        if (window.location.pathname !== '/') {
            // Save current pathname and search just in case it's needed for deeplink
            localStorage.setItem('deepLink', window.location.pathname);
            localStorage.setItem('deepLinkSearch', window.location.search);
        }
        window.reduxActions = bindActionCreators({ checkForNewVersion }, store.dispatch);
    }
    componentDidUpdate(prevProps) {
        let { oidc, organisation, modal, socket, pushNotification } = this.props;
        if (oidc && oidc.user && !oidc.isLoadingUser && organisation && !organisation.organisationNumber) {
            let ssCommitment = localStorage.getItem('commitment');
            if (ssCommitment) {
                this.props.organisationUpdate(JSON.parse(ssCommitment));
                return; //Don't continue until we have all the info about organisation
            }
        }
        if (oidc && !oidc.isLoadingUser && oidc.user && !modal.isOpen && organisation && !organisation.organisationNumber && !organisation.isLoading) {
            this.props.modalTrigger(true, ChooseCommitment, true);
        }
        // If user is logged in and an commitment has been chosen
        if (oidc && oidc.user && organisation && organisation.organisationNumber) {
            if (pushNotification && pushNotification.showPush && !modal.isOpen && !pushNotification.declined) {
                this.props.modalTrigger(true, PushNotification, true);
            }
        }
    }

    render() {
        let { oidc, organisation } = this.props;
        if (oidc.isLoadingUser) {
            return <Spinner />;
        }
        let filteredRotues = [];
        if (this.props.organisation && this.props.organisation.productType) {
            filteredRotues = routes.filter((route) => {
                return route.availableForClientType.indexOf(this.props.organisation.productType) > -1;
            });
        }
        let allRoutes = filteredRotues
            .map(({ name, component, isExact, isProtected, childRoutes, redirectTo }, index) => {
                let elements = [];
                if (redirectTo) {
                    elements.push(
                        <Route
                            path={name}
                            exact={isExact}
                            key={index}
                            component={() => (
                                <Redirect
                                    to={{
                                        pathname: redirectTo,
                                        state: { from: this.props.location },
                                    }}
                                />
                            )}
                        />
                    );
                } else if (isProtected) {
                    elements.push(<ProtectedRoute exact={isExact} key={index} path={name} component={component} />);
                } else {
                    elements.push(<Route path={name} exact={isExact} key={index} component={component} />);
                }

                if (childRoutes && childRoutes.length > 0) {
                    childRoutes.forEach((childRoute, childIndex) => {
                        if (childRoute.isProtected) {
                            elements.push(
                                <ProtectedRoute
                                    exact={childRoute.isExact}
                                    key={`child-${childIndex}`}
                                    path={childRoute.name}
                                    component={childRoute.component}
                                />
                            );
                        } else {
                            elements.push(
                                <Route path={childRoute.name} exact={childRoute.isExact} key={`child-${childIndex}`} component={childRoute.component} />
                            );
                        }
                    });
                }
                return elements;
            })
            .flat();

        return (
            <React.Fragment>
                <Switch>
                    <Route path="/" exact component={this.state.isMaintenance ? MaintenancePage : LoginPage} />
                    {oidc && oidc.user ? (
                        <Route
                            render={() => (
                                <Modal>
                                    <Navigation {...this.props}>
                                        <div className="page">
                                            {!organisation.organisationNumber && oidc.user ? (
                                                <div className="content__spinner">
                                                    <Spinner />
                                                </div>
                                            ) : (
                                                <div className="content">
                                                    <Switch>
                                                        {allRoutes}
                                                        <Route component={Error} />
                                                    </Switch>
                                                </div>
                                            )}
                                        </div>
                                    </Navigation>
                                </Modal>
                            )}
                        />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: this.props.location, isMaintenance: this.state.isMaintenance },
                            }}
                        />
                    )}
                </Switch>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        messages: state.messages,
        router: state.router,
        oidc: state.oidc,
        organisation: state.organisation,
        modal: state.modal,
        pushNotification: state.pushNotification,
    };
};

App = withRouter(
    connect(mapStateToProps, {
        modalTrigger,
        organisationUpdate,
    })(App)
);

export default App;
