// This is a service used for getting, setting. and removing a cookie
export const CookieService = {
    getCookie: (cookieName) => {
        let nameEQ = cookieName + '=';
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    setCookie: (cookieName, value, days) => {
        let expires = '';
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = cookieName + '=' + (value || '') + expires + '; path=/';
    },
    eraseCookie: (cookieName) => {
        document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=None';
    },
};
