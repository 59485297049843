import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Motion, spring } from 'react-motion';
import { push } from 'redux-first-history';
import Spinner from '../../global/Spinner';
import { BankIdLogin } from '@factoringgruppen/fg.ui.components';
import { loginStatus, organisationUpdate, loginDemo, checkForNewVersion, loginSuccess } from '../../actions';
import { CookieService } from '../../global/CookieService';
import { isMobile, isAndroidWebView, isAndroid, isIOS, isIosWebView, isWebView, Constants } from '../../global/Helpers';

import SignInCallback from './SignInCallback';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ssn: '',
            demoActivated: false,
            showDebugInfo: false,
            noClicks: 0,
            demoUsername: '',
            demoPassword: '',
            isMobile: false,
            canceled: false,
            version: process.env.REACT_APP_VERSION,
            bankIdEndPoints: {
                initiateEndPoint: {
                    url: process.env.REACT_APP_BANKID_INIT_URL,
                    method: 'post',
                    body: JSON.stringify({}),
                },
                pollEndPoint: {
                    url: process.env.REACT_APP_BANKID_POLL_URL,
                    method: 'post',
                    body: JSON.stringify({}),
                },
                generateRollingQrCode: {
                    url: process.env.REACT_APP_BANKID_GENERATE_ROLLING_QR_CODE_URL,
                    method: 'post',
                    body: JSON.stringify({}),
                },
            },
            redirectUrl: isIosWebView() ? process.env.REACT_APP_BANKID_IOS_APP_REDIRECT_URL : process.env.REACT_APP_BANKID_REDIRECT_URL,
            url: '',
        };
        if (!props.oidc.isLoadingUser && !props.oidc.user && localStorage.getItem('commitment')) {
            // No user found, make sure that no user information is saved.
            localStorage.removeItem('commitment');
            this.props.organisationUpdate({
                name: '',
                organisationNumber: '',
                list: [],
                isLoading: false,
            });
        }
        // Initially, the login state is not loading. If user has been kicked this might be set to true, so make sure it's false.
        if (props.loginInformation.isLoading) {
            props.loginStatus({ isLoading: false });
        }

        if (!isWebView()) props.checkForNewVersion();
    }

    componentDidMount() {
        if (this.props.oidc.user) {
            if (this.props.location.state && this.props.location.state.from.pathname) {
                let prevRoute = this.props.location.state.from.pathname;
                this.props.push({ pathname: prevRoute });
            } else if (localStorage.getItem('deepLink')) {
                this.props.push({ pathname: localStorage.getItem('deepLink') });
                localStorage.removeItem('deepLink');
            } else {
                this.props.push({ pathname: 'hem' });
            }
        }
        this.setState((state) => {
            state.isMobile = isMobile(navigator, window);
            return state;
        });

        let that = this;
        let root = document.getElementById('root');
        const openBankByRedirect = (e) => {
            let url = e.detail;
            that.setState(
                (state) => {
                    state.url = url;
                    return state;
                },
                () => {
                    if (!isIosWebView()) {
                        window.location.href = url;
                    }
                }
            );
        };
        root.removeEventListener('fg-openbankid', openBankByRedirect);
        root.addEventListener('fg-openbankid', (e) => openBankByRedirect(e), false);
    }

    handleChange(event) {
        event.persist();
        this.setState((state) => {
            if (event.target) {
                state.ssn = event.target.value;
            }
            state.ssnError = false;
            state.ssnErrorMessage = '';

            return state;
        });
    }

    cancelLoginClick(event) {
        event.preventDefault();
        this.props.loginCancel(this.props.loginInformation.transactionId, this.props.loginInformation.transactionType);
        this.setState((state) => {
            if (event.target) {
                state.ssn = event.target.value;
            }
            state.ssnError = false;
            state.ssnErrorMessage = '';

            return state;
        });
    }

    handleLoginBankIdOnThisDevice(event) {
        this.handleLogin(event, true);
    }

    handleLoginOtherDevice(event) {
        this.handleLogin(event, false);
    }

    handleLogin(event, bankIdOnThisDevice) {
        event.preventDefault();
        let ssnRegExp = new RegExp(/^(19|20)(\d{6}([-+]|\s)\d{4}|(?!19|20)\d{10})$/);
        if (ssnRegExp.test(this.state.ssn)) {
            this.props.loginTrigger(this.state.ssn, bankIdOnThisDevice);
        } else {
            this.setState((state) => {
                state.ssnError = true;
                state.ssnErrorMessage = 'Ange ett giltigt personnummer';

                return state;
            });
        }
    }

    handleDemoChange(event) {
        event.persist();
        this.setState((state) => {
            if (event.target && event.target.name) {
                if (event.target.name === 'demoUsername') {
                    state.demoUsername = event.target.value;
                } else if (event.target.name === 'demoPassword') {
                    state.demoPassword = event.target.value;
                }
            }
            return state;
        });
    }

    handleDemoLogin(event) {
        event.preventDefault();
        this.props.loginDemo(this.state.demoUsername, this.state.demoPassword);
    }

    logoClicked() {
        this.setState((state) => {
            state.noClicks++;
            if (state.demoActivated && state.noClicks === 4) {
                state.showDebugInfo = true;
            }

            if (state.noClicks === 5) {
                state.noClicks = 0;
                state.showDebugInfo = false;
                state.demoActivated = !state.demoActivated;
                if (state.demoActivated) {
                    CookieService.setCookie('demo-user', 'always');
                } else {
                    CookieService.eraseCookie('demo-user');
                }
            }
            return state;
        });
    }

    getRegistrationLink() {
        if (isWebView()) {
            return process.env.REACT_APP_REGISTER_URL + Constants.webkitTargetNativeBrowserUrlHash();
        }
        return process.env.REACT_APP_REGISTER_URL;
    }

    render() {
        const { loginInformation, oidc } = this.props;
        let buttonClass = oidc.isLoadingUser ? 'button button--function button--full button--disabled' : 'button button--function button--full';

        return (
            <div className="login">
                <div className="login__form">
                    <img
                        className="login__logo"
                        src={require('../../img/logo.svg').default}
                        alt="Logotype för Factoringgruppen"
                        onClick={this.logoClicked.bind(this)}
                    />

                    <div className="form">
                        <h1 className="title title--h2">Logga in med BankID</h1>
                        {isIosWebView() && this.state.url.length > 0 && document.querySelector('.login-component-container button') == null ? (
                            <div>
                                <a id="bankidRedirectBtn" href={this.state.url} className="button button--approve button--full">
                                    Öppna bankid
                                </a>
                            </div>
                        ) : null}

                        <div className="login-component-container">
                            <BankIdLogin
                                handleCompleted={() => {}}
                                title={''}
                                description={''}
                                onBankIdComplete={(id) => this.props.loginSuccess(id)}
                                bankIdEndPoints={this.state.bankIdEndPoints}
                                redirectUrl={isAndroidWebView() || isAndroid ? null : this.state.redirectUrl}
                                orString={'eller om du vill öppna BankID på den här enheten'}
                                buttonStringSameDevice={isIosWebView() ? 'Logga in' : 'Öppna BankID på denna enheten'}
                                preventRedirect={isIosWebView()}
                            />
                        </div>

                        {loginInformation.status.message && !loginInformation.canceled ? (
                            <Motion defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }} style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}>
                                {(value) => (
                                    <div className="form__async-status" style={{ opacity: value.opacity, top: value.top, transform: `scale(${value.scale})` }}>
                                        {loginInformation.status.message}
                                    </div>
                                )}
                            </Motion>
                        ) : null}
                    </div>
                    <div className="form">
                        <h2 className="title title--h2 title--center">Har du inget konto?</h2>
                        <a href={this.getRegistrationLink()} className="button button--approve button--full">
                            Skapa konto här
                        </a>
                    </div>
                    <div className="versionInfo">Version {this.state.version}</div>
                    {this.state.showDebugInfo ? (
                        <div className="debug-info">
                            <label className="title--center">
                                <span>Debug info</span>
                            </label>
                            <label>
                                <span>userAgent: </span>
                                {navigator.userAgent}
                            </label>
                            <label>
                                <span>isAndroid: </span>
                                {isAndroid ? 'true' : 'false'}
                            </label>
                            <label>
                                <span>isIos: </span>
                                {isIOS ? 'true' : 'false'}
                            </label>
                            <label>
                                <span>isAndroidWebView: </span>
                                {isAndroidWebView() ? 'true' : 'false'}
                            </label>
                            <label>
                                <span>isIosWebView: </span>
                                {isIosWebView() ? 'true' : 'false'}
                            </label>
                            <label>
                                <span>isMobile: </span>
                                {isMobile(navigator, window) ? 'true' : 'false'}
                            </label>
                        </div>
                    ) : null}

                    {this.state.demoActivated ? (
                        <div className="form">
                            <h2 className="title title--h2 title--center">Logga in med demo konto</h2>
                            <React.Fragment>
                                <div className="input">
                                    <label className="input__label" htmlFor="demoUsername">
                                        Användarnamn
                                    </label>
                                    <input
                                        name="demoUsername"
                                        value={this.state.demoUsername}
                                        onChange={this.handleDemoChange.bind(this)}
                                        placeholder="Användarnamn"
                                        disabled={loginInformation.isDemoLoading ? 'disabled' : ''}
                                        id="demoUsername"
                                        type="text"
                                        className="input__field"
                                    />
                                    <label className="input__label input__label--top" htmlFor="demoPassword">
                                        Lösenord
                                    </label>
                                    <input
                                        name="demoPassword"
                                        value={this.state.demoPassword}
                                        onChange={this.handleDemoChange.bind(this)}
                                        placeholder="Lösenord"
                                        disabled={loginInformation.isDemoLoading ? 'disabled' : ''}
                                        id="demoPassword"
                                        type="password"
                                        className="input__field"
                                    />
                                </div>

                                {loginInformation.demoLoginError ? (
                                    <Motion defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }} style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}>
                                        {(value) => (
                                            <div
                                                className="form__async-status"
                                                style={{ opacity: value.opacity, top: value.top, transform: `scale(${value.scale})` }}
                                            >
                                                {loginInformation.demoLoginError}
                                            </div>
                                        )}
                                    </Motion>
                                ) : null}
                                {loginInformation.isDemoLoading ? <Spinner /> : null}
                                <button className={buttonClass} disabled={loginInformation.isLoading} onClick={this.handleDemoLogin.bind(this)}>
                                    Logga in med demo konto
                                </button>
                            </React.Fragment>
                        </div>
                    ) : null}
                    {this.props.router.location.hash ? <SignInCallback /> : null}
                </div>
                <div className="login__graphic"></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        oidc: state.oidc,
        router: state.router,
        loginInformation: state.loginInformation,
        organisation: state.organisation,
    };
};

LoginPage = connect(mapStateToProps, {
    loginStatus,
    organisationUpdate,
    push,
    loginDemo,
    checkForNewVersion,
    loginSuccess,
})(LoginPage);

export default LoginPage;
