import { SOCKET_INIT, SOCKET_SETUP_MULTIPLEX, SOCKET_UPDATE } from '../actions/index';

const initialState = {
    auth: '',
    socket: null,
    isLoading: false,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SOCKET_SETUP_MULTIPLEX:
            return {
                socket: action.payload.socket,
                auth: action.payload.auth,
                ...state,
            };
        case SOCKET_UPDATE:
            return { ...state, ...action.payload };
        case SOCKET_INIT:
        default:
            return { ...state };
    }
}
