import {
    INTEGRATION_UPDATE,
    INTEGRATION_COMPLETE,
    INTEGRATION_HAS_COMPLETED,
    INTEGRATION_INITIATE_ONBOARDING
} from '../actions/index';

const initialState = {
    resolved: false,
    loading: false,
    urlLoading: false,
    completed: false,
    connectionState: '',
    onboardingLink: ''
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case INTEGRATION_HAS_COMPLETED:
            return { ...state };
        case INTEGRATION_UPDATE:
            return { ...state, ...action.payload };
        case INTEGRATION_COMPLETE:
            return { ...state, ...action.payload };
        case INTEGRATION_INITIATE_ONBOARDING:
            return { ...state, ...action.payload };
        default:
            return { ...state };
    }
}
