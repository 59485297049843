import React, { Component } from 'react';

import FileUploaderPresentationalComponent from './FileUploaderPresentationalComponent';

// This was an initial attempt to code using presentational components.
// It did not result in me coding using this pattern since it felt redundant.
//
// This component is used for listening for when a user drops a file, and tells parent component to upload file(s).
class FileUploader extends Component {
    static counter = 0;
    fileUploaderInput = null;

    constructor(props) {
        super(props);
        this.state = { dragging: false, file: null };
    }

    dragEventCounter = 0;
    dragenterListener(event) {
        this.overrideEventDefaults(event);
        this.dragEventCounter++;
        if (event.dataTransfer.items && event.dataTransfer.items[0]) {
            this.setState({ dragging: true });
        } else if (event.dataTransfer.types && event.dataTransfer.types[0] === 'Files') {
            // This block handles support for IE - if you're not worried about
            // that, you can omit this
            this.setState({ dragging: true });
        }
    }

    dragleaveListener(event) {
        this.overrideEventDefaults(event);
        this.dragEventCounter--;

        if (this.dragEventCounter === 0) {
            this.setState({ dragging: false });
        }
    }

    dropListener(event) {
        this.overrideEventDefaults(event);
        this.dragEventCounter = 0;
        this.setState({ dragging: false });
        if (event.dataTransfer.files && event.dataTransfer.files.length > 10) {
            this.setState({ error: true, errorText: 'Max 10 filer i taget' });
            return;
        }
        if (event.dataTransfer.files && event.dataTransfer.files) {
            this.setState({ file: event.dataTransfer.files, error: false, errorText: '' }, this.newFile);
        }
    }

    overrideEventDefaults(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    onSelectFileClick() {
        this.fileUploaderInput && this.fileUploaderInput.click();
    }

    onFileChanged(event) {
        if (event.target.files && event.target.files.length > 10) {
            this.setState({ error: true, errorText: 'Max 10 filer i taget' });
            return;
        }
        if (event.target.files && event.target.files) {
            this.setState({ file: event.target.files, error: false, errorText: '' }, this.newFile);
        }
    }
    newFile() {
        if (!Array.from(this.state.file).every((file) => (file.type === 'application/pdf' ? true : false))) {
            this.setState({ file: this.state.file, error: true, errorText: 'Vänligen ladda endast upp fakturor i pdf format' });
        } else if (this.state.file) {
            this.props.fileListUpdate(this.state.file);
        }
    }

    componentDidMount() {
        window.addEventListener('dragover', (event) => {
            this.overrideEventDefaults(event);
        });
        window.addEventListener('drop', (event) => {
            this.overrideEventDefaults(event);
        });
    }

    componentWillUnmount() {
        window.removeEventListener('dragover', this.overrideEventDefaults);
        window.removeEventListener('drop', this.overrideEventDefaults);
    }

    render() {
        return (
            <FileUploaderPresentationalComponent
                disabled={this.props.disabled}
                dragging={this.state.dragging}
                error={this.state.error}
                errorText={this.state.errorText}
                file={this.state.file}
                onSelectFileClick={this.onSelectFileClick.bind(this)}
                onDrag={this.overrideEventDefaults.bind(this)}
                onDragStart={this.overrideEventDefaults.bind(this)}
                onDragEnd={this.overrideEventDefaults.bind(this)}
                onDragOver={this.overrideEventDefaults.bind(this)}
                onDragEnter={this.dragenterListener.bind(this)}
                onDragLeave={this.dragleaveListener.bind(this)}
                onDrop={this.dropListener.bind(this)}
            >
                <input
                    disabled={this.props.disabled}
                    type="file"
                    multiple
                    name="upload-file"
                    id="upload-file"
                    className="upload-file__input"
                    onChange={this.onFileChanged.bind(this)}
                    ref={(el) => (this.fileUploaderInput = el)}
                />
            </FileUploaderPresentationalComponent>
        );
    }
}

export default FileUploader;
