import { SETTINGS_UPDATE, REFRESH_NOTIFICATION_SETTING } from '../actions/index';
import { USER_EXPIRED, SILENT_RENEW_ERROR } from 'redux-oidc';

const initialState = {
    user: {},
    notification: {},
    integration: {
        emailIntegration: '',
        partnerType: '',
    },
};

const findIndex = (notificationSettings, notificationType) => {
    let index = notificationSettings.findIndex((setting) => {
        if (setting) {
            return setting.notificationType === notificationType;
        }
        return false;
    });

    return index;
};

export default function rootReducer(state = initialState, action) {
    let index;
    switch (action.type) {
        case SETTINGS_UPDATE:
            return { ...state, ...action.payload };
        case REFRESH_NOTIFICATION_SETTING:
            index = findIndex(state.notification.list, action.payload.setting.notificationType);

            if (index > -1) {
                state.notification.list[index] = action.payload.setting;
            }
            return { ...state };
        case USER_EXPIRED:
        case SILENT_RENEW_ERROR:
            return {
                ...initialState,
            };
        default:
            return { ...state };
    }
}
