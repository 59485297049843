import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Motion, spring } from 'react-motion';
import { fromEvent, merge } from 'rxjs';

import { modalTrigger } from '../actions';

import Icon from './Icon';

// This is a modal for showing images. The user can zoom and pan around the image.
class ImageModal extends Component {
    constructor(props) {
        super(props);
        this.imgEl = React.createRef();
        this.imgWrapper = React.createRef();
        this.state = {
            styles: {
                scale: 100,
                translateX: 0,
                translateY: 0,
                dirty: false,
            },
        };
    }
    findCoords(event) {
        let coords;
        if (event.touches && event.touches.length > 0) {
            coords = {
                x: event.touches[0].clientX,
                y: event.touches[0].clientY,
            };
        } else {
            coords = {
                x: event.clientX,
                y: event.clientY,
            };
        }

        return coords;
    }
    findDelta(prevCoords, event) {
        let newCoords = this.findCoords(event);
        let coords = {
            x: prevCoords.x - newCoords.x,
            y: prevCoords.y - newCoords.y,
        };
        return coords;
    }
    componentDidMount() {
        // setup event listeners using rxjs
        const mouseDown = fromEvent(this.imgWrapper.current, 'mousedown');
        const touchStart = fromEvent(this.imgWrapper.current, 'touchstart');
        // Merge to one observer
        this.touchStartSubscriber = merge(mouseDown, touchStart).subscribe((event) => {
            if (event.type !== 'touchstart') {
                event.preventDefault();
                event.stopPropagation();
            }
            let clientPrev = this.findCoords(event);

            // Create observers for dragging mouse. User is already clicking and holding.
            const mouseMove = fromEvent(this.imgWrapper.current, 'mousemove');
            const touchMove = fromEvent(this.imgWrapper.current, 'touchmove');
            const mouseDragObs = merge(mouseMove, touchMove).subscribe((event) => {
                // This happens when user is holding down mouse button on the imagewrapper AND moving around
                let clientDelta = this.findDelta(clientPrev, event);
                this.setState((state) => {
                    // Update state for where the image should be
                    state.styles.translateX += clientDelta.x;
                    state.styles.translateY += clientDelta.y;
                    state.styles.dirty = true;
                    return state;
                });
                // Update coords for next event
                clientPrev = this.findCoords(event);
                // If this is a touch event
                if (event.touches && event.touches.length > 0) {
                    let wrapperRect = this.imgWrapper.current.getBoundingClientRect();
                    let wrapperBounds = {
                        left: wrapperRect.left,
                        right: wrapperRect.left + wrapperRect.width,
                        top: wrapperRect.top,
                        bottom: wrapperRect.top + wrapperRect.height,
                    };
                    if (
                        event.touches[0].clientX < wrapperBounds.right &&
                        event.touches[0].clientX > wrapperBounds.left &&
                        event.touches[0].clientY > wrapperBounds.top &&
                        event.touches[0].clientY < wrapperBounds.bottom
                    ) {
                        // Touch drag is within borders, do not continue
                        return;
                    } else {
                        // Touch is out of bounds, cancel the scroller
                        let evt = new TouchEvent('touchend', {
                            bubbles: true,
                            cancelable: true,
                            view: window,
                        });
                        this.imgWrapper.current.dispatchEvent(evt);
                    }
                }
            });
            // Create observers for when user drags too far, or releases mouse button...
            const mouseUp = fromEvent(this.imgWrapper.current, 'mouseup');
            const touchEnd = fromEvent(this.imgWrapper.current, 'touchend');
            const dragLeave = fromEvent(this.imgWrapper.current, 'mouseout');
            // ... merge to one observer...
            const mouseUpObs = merge(mouseUp, touchEnd, dragLeave).subscribe((event) => {
                // ... Cleanup listeners and inactivate until mousebutton is hold again over imagewrapper.
                mouseDragObs.unsubscribe();
                mouseUpObs.unsubscribe();
            });
        });
    }
    componentWillUnmount() {
        if (this.touchStartSubscriber) {
            this.touchStartSubscriber.unsubscribe();
        }
    }
    closeModal(event) {
        event.preventDefault();
        event.persist();
        this.props.modalTrigger(false);
    }
    handleZoom(event) {
        let scaleValue = event.target.value;
        this.setState((state) => {
            state.styles.scale = scaleValue;
            state.styles.dirty = true;
            return state;
        });
    }
    resetScaleAndScroll(event) {
        event.preventDefault();
        this.setState((state) => {
            state.styles = {
                scale: 100,
                translateY: 0,
                translateX: 0,
                dirty: false,
            };

            return state;
        });
    }
    render() {
        let { styles } = this.state;
        return (
            <div className="lightbox">
                <div className="lightbox__img-wrapper" ref={this.imgWrapper}>
                    <Motion
                        defaultStyle={{ scale: 1, translateX: 0, translateY: 0 }}
                        style={{
                            scale: spring(styles.scale / 100, { stiffness: 200, damping: 20 }),
                            translateX: spring(styles.translateX, { stiffness: 200, damping: 20 }),
                            translateY: spring(styles.translateY, { stiffness: 200, damping: 20 }),
                        }}
                    >
                        {(interpolatingStyle) => {
                            // Negate values in order for translateX/translateY to work properly
                            let x = interpolatingStyle.translateX - interpolatingStyle.translateX * 2;
                            let y = interpolatingStyle.translateY - interpolatingStyle.translateY * 2;
                            return (
                                <img
                                    style={{ transform: `scale(${interpolatingStyle.scale}) translateX(${x}px) translateY(${y}px)` }}
                                    ref={this.imgEl}
                                    className="lightbox__img"
                                    src={this.props.img}
                                    alt="bild på den aktuella fakturan"
                                />
                            );
                        }}
                    </Motion>
                </div>
                <div className="lightbox__zoom">
                    <div className="lightbox__zoom-controls">
                        <Icon name="zoom-out" />
                        <input
                            onChange={this.handleZoom.bind(this)}
                            type="range"
                            className="input-range"
                            id="start"
                            name="zoom"
                            step="1"
                            value={styles.scale}
                            min="50"
                            max="200"
                        />
                        <Icon name="zoom-in" />
                    </div>
                    <div className="lightbox__zoom-percent">{Math.round(styles.scale)}%</div>
                    {styles.dirty ? (
                        <button className="button button--action button--chip" onClick={this.resetScaleAndScroll.bind(this)}>
                            Återställ
                        </button>
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
    };
};

ImageModal = connect(mapStateToProps, {
    modalTrigger,
})(ImageModal);

export default ImageModal;
