import { MATURITY_STRUCTURE_UPDATE } from '../actions/index';

import { USER_EXPIRED, SILENT_RENEW_ERROR } from 'redux-oidc';

const initialState = {
    groups: [],
    numberOfInvoices: 0,
    totalInvoiceCost: 0,
    loading: false,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case MATURITY_STRUCTURE_UPDATE:
            if (!action.payload) {
                return initialState;
            } else {
                return { ...state, ...action.payload };
            }
        case USER_EXPIRED:
        case SILENT_RENEW_ERROR:
            return {
                ...initialState,
            };
        default:
            return { ...state };
    }
}
