import React, { Component } from 'react';
import { connect } from 'react-redux';

import HistoryTransfercostsDetailsInner from './HistoryTransfercostsDetailsInner';

import { transfercostsUpdate } from '../../../actions';

class HistoryTransfercostsDetails extends Component {
    componentWillUnmount() {
        if (this.props.inModal) {
            let transfercosts = { ...this.props.transfercosts };
            transfercosts.chosenRecord = undefined;
            this.props.transfercostsUpdate({ ...transfercosts });
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.props.inModal ? (
                    <div className="content__modal">
                        <HistoryTransfercostsDetailsInner />
                    </div>
                ) : (
                    <HistoryTransfercostsDetailsInner />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        transfercosts: state.transfercosts,
    };
};

HistoryTransfercostsDetails = connect(mapStateToProps, {
    transfercostsUpdate,
})(HistoryTransfercostsDetails);

export default HistoryTransfercostsDetails;
