import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';

import Icon from '../../../global/Icon';
import Spinner from '../../../global/Spinner';

import { updateNotificationSetting } from '../../../actions';

export const notificationTypeMapper = {
    Unknown: {
        text: 'Okänd',
        description: 'Felaktig inställning...',
    },
    GeneralInformation: { text: 'Allmäna meddelanden', description: '' },
    InvoiceRecieved: { text: 'Mottagna fakturor', description: '' },
    InvoicePurchased: {
        text: 'Köp av fakturor',
        description: 'Notifiering av fakturor som är avräknade av Factoringgruppen. Detta innebär att fakturorna är behandlade och utskickade till din kund.',
    },
    InvoicePaid: { text: '', description: '' },
    InvoiceRepurchased: { text: 'Regressade fakturor', description: '' },
    RecipientApproval: { text: 'Leveransgodkända', description: '' },
    UnhandledInvoices: { text: 'Ohanterade fakturor', description: '' },
    InvoiceReminder: { text: 'Förfallna fakturor', description: '' },
    InvoiceInkasso: { text: 'Inkasso', description: '' },
    InvoiceKfm: { text: 'Kronofogden', description: '' },
    MessageFromOperations: {
        text: 'Meddelanden från FG',
        description: '',
    },
};

export const notificationTriggerMapper = {
    OnEvent: {
        text: 'Direkt vid händelsen',
    },
    Daily: {
        text: 'Dagligen',
    },
};

export class NotificationSettingsItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isSaving: false,
        };

        this.handleSettingClick = this.handleSettingClick.bind(this);
        this.handlePushChanged = this.handlePushChanged.bind(this);
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.getMappedText = this.getMappedText.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.state.isSaving &&
            prevProps.setting &&
            this.props.setting &&
            (this.props.setting.usePush !== prevProps.setting.usePush || this.props.setting.useEmail !== prevProps.setting.useEmail)
        ) {
            this.setState({
                isSaving: false,
            });
        }
    }

    handleSettingClick(event) {
        event.preventDefault();

        this.setState((state) => {
            state.isOpen = !state.isOpen;
            return state;
        });
    }

    stopPropagation(event) {
        event.stopPropagation();
    }

    handlePushChanged(checked, event) {
        this.stopPropagation(event);

        this.setState(
            {
                isSaving: true,
            },
            () => {
                this.props.updateNotificationSetting({
                    notificationType: this.props.setting.notificationType,
                    property: 'usePush',
                    value: !this.props.setting.usePush,
                });
            }
        );
    }

    handleEmailChanged(checked, event) {
        this.stopPropagation(event);

        this.setState(
            {
                isSaving: true,
            },
            () => {
                this.props.updateNotificationSetting({
                    notificationType: this.props.setting.notificationType,
                    property: 'useEmail',
                    value: !this.props.setting.useEmail,
                });
            }
        );
    }

    getMappedText() {
        const typeText = notificationTypeMapper[this.props.setting.notificationType] || {
            text: 'Okänd status',
            description: '',
        };
        const triggerText = notificationTriggerMapper[this.props.setting.notificationTrigger] || {
            text: 'Okänd status',
        };

        return {
            type: typeText,
            trigger: triggerText,
        };
    }

    render() {
        let classNames = this.state.isOpen ? 'settings__item settings__item--active' : 'settings__item';
        const setting = this.props.setting;
        const settingMapped = this.getMappedText();
        return (
            <li className={classNames} key={this.props.index}>
                <div onClick={this.handleSettingClick} className="settings__item-row">
                    {this.state.isSaving ? <Spinner /> : null}
                    <div className="settings__panel">
                        <p className="paragraph">
                            <small>Typ</small>
                        </p>
                        <p className="paragraph">
                            <strong>{settingMapped.type.text}</strong>
                        </p>
                    </div>
                    <div className="settings__panel">
                        <p className="paragraph">
                            <small>Tidpunkt</small>
                        </p>
                        <p className="paragraph">
                            <strong>{settingMapped.trigger.text}</strong>
                        </p>
                    </div>
                    <div className="settings__panel settings__panel--switch">
                        <p className="paragraph">
                            <small>Pushnotis</small>
                        </p>
                        <div className="paragraph settings__item-row--switch" onClick={this.stopPropagation}>
                            <Switch
                                onChange={this.handlePushChanged}
                                checked={setting.usePush}
                                disabled={this.state.isSaving}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={20}
                                height={12}
                                width={40}
                                onColor="#179e5a"
                            />
                        </div>
                    </div>
                    <div className="settings__panel settings__panel--switch">
                        <p className="paragraph">
                            <small>E-post</small>
                        </p>
                        <div className="paragraph settings__item-row--switch" onClick={this.stopPropagation}>
                            <Switch
                                onChange={this.handleEmailChanged}
                                checked={setting.useEmail}
                                disabled={this.state.isSaving}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={20}
                                height={12}
                                width={40}
                                onColor="#179e5a"
                            />
                        </div>
                        <div className="settings__item-arrow">
                            <div className="settings__arrow-img">
                                <Icon name="down" />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isOpen ? (
                    <div className="settings__content-wrapper">
                        <div className="settings__item-content">
                            <div className="settings__panel">
                                <div className="paragraph">
                                    <div className="paragraph">{settingMapped.type.description}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </li>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { updateNotificationSetting };

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsItem);
