import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Motion, spring } from 'react-motion';

import { organisationChooseCommitment, organisationAddContactDetails } from '../../actions';
import Spinner from '../Spinner';
import { Constants } from '../Helpers';

class AddContactInformation extends Component {
    constructor(props) {
        super(props);

        this.name = React.createRef();
        this.email = React.createRef();
        this.phone = React.createRef();

        this.state = {
            name: '',
            email: '',
            phone: '',
        };
    }
    handleChange(name, event) {
        let propName = name;
        let input = event.target;
        this.setState((state) => {
            state[propName] = input.value;

            return state;
        });
    }
    validateInput(event) {
        let inputValue = event.target.value;
        switch (event.target.id) {
            case 'name':
                this.setState((state) => {
                    state.nameIsValid = inputValue && inputValue.length > 0;
                    return state;
                });
                break;
            case 'email':
                let regex = Constants.emailRegex();
                this.setState((state) => {
                    state.emailIsValid = inputValue && regex.test(inputValue);
                    return state;
                });
                break;
            case 'phone':
                event.target.value = inputValue.replace(/\s/g, '');
                inputValue = event.target.value;
                this.setState((state) => {
                    state.phoneIsValid = inputValue && /^[0-9]*$/.test(inputValue);
                    return state;
                });
                break;
            default:
                break;
        }
    }
    inputsAreValid() {
        return this.state.nameIsValid && this.state.emailIsValid && this.state.phoneIsValid;
    }
    saveResult(event) {
        event.preventDefault();
        this.name.current.querySelector('input').dispatchEvent(new Event('blur'));
        this.email.current.querySelector('input').dispatchEvent(new Event('blur'));
        this.phone.current.querySelector('input').dispatchEvent(new Event('blur'));

        if (this.inputsAreValid()) {
            const contactName = this.name.current.querySelector('input').value;
            const contactEmailAddress = this.email.current.querySelector('input').value;
            const contactPhoneNumber = this.phone.current.querySelector('input').value;
            this.props.organisationAddContactDetails(contactName, contactEmailAddress, contactPhoneNumber);
        }
    }
    render() {
        let { organisation } = this.props;
        let inputClasses = (name) => {
            let className = 'input';
            if (typeof this.state[name] !== 'undefined' && !this.state[name]) {
                className += ' input--error';
            }
            return className;
        };
        return (
            <div className="contact-info">
                <form className="form form--dark">
                    <h2 className="title title--h2">Innan vi går vidare...</h2>
                    {organisation.addcontactDetailsLoading ? (
                        <div className="commitment-picker__inline-spinner">
                            <Spinner />
                        </div>
                    ) : null}
                    <p className="paragraph">
                        Det är sällan det behövs men det kan hända att vi behöver kontakta er. Det kan röra sig om att skicka en kopia på ert avtal, frågor
                        kring framtida fakturor, eller liknande ärenden.
                    </p>
                    <div className={inputClasses('nameIsValid')} ref={this.name}>
                        <label className="input__label" htmlFor="name">
                            Kontaktperson
                        </label>
                        <input
                            value={this.state.name}
                            onChange={this.handleChange.bind(this, 'name')}
                            onBlur={this.validateInput.bind(this)}
                            id="name"
                            type="text"
                            placeholder="För- och efternamn"
                            className="input__field"
                        />
                        {typeof this.state.nameIsValid !== 'undefined' && !this.state.nameIsValid ? (
                            <div className="input__required-information input__required-information--narrow">Fältet är obligatoriskt</div>
                        ) : null}
                    </div>
                    <div className={inputClasses('emailIsValid')} ref={this.email}>
                        <label className="input__label" htmlFor="name">
                            Email
                        </label>
                        <input
                            value={this.state.email}
                            onChange={this.handleChange.bind(this, 'email')}
                            onBlur={this.validateInput.bind(this)}
                            id="email"
                            type="email"
                            placeholder="mail@exempel.se"
                            className="input__field"
                        />
                        {typeof this.state.emailIsValid !== 'undefined' && !this.state.emailIsValid ? (
                            <div className="input__required-information input__required-information--narrow">Ange en giltig email-adress</div>
                        ) : null}
                    </div>
                    <div className={inputClasses('phoneIsValid')} ref={this.phone}>
                        <label className="input__label" htmlFor="name">
                            Telefonnummer inkl. riktnummer
                        </label>
                        <input
                            value={this.state.phone}
                            onChange={this.handleChange.bind(this, 'phone')}
                            onBlur={this.validateInput.bind(this)}
                            id="phone"
                            type="tel"
                            placeholder="Telefonnummer"
                            className="input__field"
                        />
                        {typeof this.state.phoneIsValid !== 'undefined' && !this.state.phoneIsValid ? (
                            <div className="input__required-information input__required-information--narrow">Ange enbart numeriska värden</div>
                        ) : null}
                    </div>
                    {organisation.error && organisation.error.type === 'ADDCONTACTERROR' ? (
                        <Motion defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }} style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}>
                            {(value) => (
                                <div
                                    className="form__async-status form__async-status--error"
                                    style={{ opacity: value.opacity, top: value.top, transform: `scale(${value.scale})` }}
                                >
                                    {organisation.error.message}
                                </div>
                            )}
                        </Motion>
                    ) : null}
                    <button className="button button--full button--action" onClick={this.saveResult.bind(this)}>
                        Skicka
                    </button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        organisation: state.organisation,
        oidc: state.oidc,
    };
};

AddContactInformation = connect(mapStateToProps, {
    organisationChooseCommitment,
    organisationAddContactDetails,
})(AddContactInformation);

export default AddContactInformation;
