import React, { Component } from 'react';
import { connect } from 'react-redux';

import { importInvoiceAddComment, importInvoiceUpdateEmail } from '../../../actions';
import { Constants } from '../../../global/Helpers';

class ImportInvoiceItemDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            emailIsValid: true,
            email: this.props.invoice && this.props.invoice.email ? this.props.invoice.email : '',
            comment: this.props.invoice && this.props.invoice.comment ? this.props.invoice.comment : '',
        };
    }

    componentWillUnmount(event) {
        if (this.state.emailIsValid) {
            this.props.importInvoiceAddComment(this.state.comment, this.props.invoice.invoiceNumber);
            this.props.importInvoiceUpdateEmail(this.state.email, this.props.invoice.invoiceNumber);
        } else {
            return false;
        }
    }

    handleChange(name, event) {
        let propName = name;
        let input = event.target;
        this.setState(
            (state) => {
                state[propName] = input.value;
                return state;
            },
            () => {
                if (propName === 'comment') {
                    this.props.importInvoiceAddComment(this.state.comment, this.props.invoice.invoiceNumber);
                }
            }
        );
    }

    validateInput(event) {
        let inputValue = event.target.value;
        switch (event.target.id) {
            case 'email':
                let regex = Constants.emailRegex();
                this.setState(
                    (state) => {
                        state.emailIsValid = !inputValue || inputValue === '' || (inputValue && regex.test(inputValue));
                        return state;
                    },
                    () => {
                        this.props.onValidationChanged(this.state.emailIsValid);
                        if (this.state.emailIsValid) {
                            this.props.importInvoiceUpdateEmail(inputValue, this.props.invoice.invoiceNumber);
                        }
                    }
                );
                break;
            default:
                break;
        }
    }

    inputsAreValid() {
        return this.state.emailIsValid;
    }

    render() {
        return (
            <div className="imports__content-wrapper">
                <div className="imports__item-content">
                    <div className="imports__panel">
                        <div className="paragraph">
                            <div className="paragraph">Vill du istället skicka denna faktura som e-post? Ange mailadressen nedan:</div>
                            <div className="paragraph">
                                <input
                                    value={this.state.email}
                                    onChange={this.handleChange.bind(this, 'email')}
                                    onBlur={this.validateInput.bind(this)}
                                    id="email"
                                    type="email"
                                    className="input__field"
                                    placeholder="mail@exempel.se"
                                />
                                {typeof this.state.emailIsValid !== 'undefined' && !this.state.emailIsValid ? (
                                    <div className="input__required-information input__required-information--narrow">Ange en giltig email-adress</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="imports__panel--divider" />
                <div className="imports__item-content">
                    <div className="imports__panel">
                        <div className="paragraph">Vill du lämna ett meddelande till Factoringgruppen som tillhör denna faktura kan du göra detta nedan:</div>
                        <div className="paragraph">
                            <textarea
                                value={this.state.comment}
                                onChange={this.handleChange.bind(this, 'comment')}
                                autoComplete="off"
                                className="input__text-area"
                                name="freeText"
                                placeholder="Fritext"
                                max="400"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        import: state.import,
    };
};

ImportInvoiceItemDetails = connect(mapStateToProps, {
    importInvoiceAddComment,
    importInvoiceUpdateEmail,
})(ImportInvoiceItemDetails);

export default ImportInvoiceItemDetails;
