import { of, concat } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import {
    GET_NOTIFICATION_SETTINGS,
    GET_USER_SETTINGS,
    UPDATE_USER_SETTING,
    UPDATE_NOTIFICATION_SETTING,
    GET_INTEGRATION_SETTINGS,
    SAVE_INTEGRATION_SETTINGS,
    settingsUpdate,
    refreshNotificationSetting,
} from '../actions';

let endpoint = `${window.location.origin}/`;
let companyEndpoint = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    endpoint = process.env.REACT_APP_API_URL;
    companyEndpoint = process.env.REACT_APP_COMPANY_API_URL;
}

export const getNotificationSettingsEpic = (action$, store) =>
    action$.ofType(GET_NOTIFICATION_SETTINGS).pipe(
        switchMap((action) => {
            let { oidc } = store.value;
            return concat(
                of(settingsUpdate({ notification: { isLoading: true } })),
                ajax
                    .post(
                        `${endpoint}api/notification/get`,
                        { socialSecurityNumber: oidc.user.profile.sub },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                        }
                    )
                    .pipe(
                        map((response) => {
                            return settingsUpdate({
                                notification: {
                                    isLoading: false,
                                    list: response.response.notificationSettingsList,
                                },
                            });
                        }),
                        catchError((error) => {
                            return of(
                                settingsUpdate({
                                    notification: {
                                        isLoading: false,
                                        error: { type: 'FETCHFAIL' },
                                    },
                                })
                            );
                        })
                    )
            );
        })
    );

export const getUserSettingsEpic = (action$, store) =>
    action$.ofType(GET_USER_SETTINGS).pipe(
        switchMap((action) => {
            let { oidc } = store.value;
            return concat(
                of(settingsUpdate({ user: { isLoading: true } })),
                ajax
                    .post(
                        `${endpoint}api/companyContactPerson/contactcompanyperson`,
                        { socialSecurityNumber: oidc.user.profile.sub },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                        }
                    )
                    .pipe(
                        map((response) => {
                            return settingsUpdate({
                                user: {
                                    isLoading: false,
                                    details:
                                        response.status === 204
                                            ? {
                                                  emailAddress: '',
                                                  phoneNumber: '',
                                              }
                                            : response.response,
                                },
                            });
                        }),
                        catchError((error) => {
                            return of(
                                settingsUpdate({
                                    user: {
                                        isLoading: false,
                                        error: { type: 'FETCHFAIL' },
                                    },
                                })
                            );
                        })
                    )
            );
        })
    );

export const updateUserSettingEpic = (action$, store) =>
    action$.ofType(UPDATE_USER_SETTING).pipe(
        switchMap((action) => {
            const userSetting = action.payload;
            let { oidc } = store.value;

            const request = {
                socialSecurityNumber: oidc.user.profile.sub,
                name: oidc.user.profile.name,
                phoneNumber: userSetting.phoneNumber,
                emailAddress: userSetting.email,
            };
            return concat(
                of(settingsUpdate({ user: { isLoading: true } })),
                ajax
                    .post(`${endpoint}api/companyContactPerson/save`, request, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        map((response) => {
                            return settingsUpdate({
                                user: {
                                    isLoading: false,
                                    details: {
                                        emailAddress: request.emailAddress,
                                        phoneNumber: request.phoneNumber,
                                    },
                                },
                            });
                        }),
                        catchError((error) => {
                            return of(
                                settingsUpdate({
                                    user: {
                                        isLoading: false,
                                        error: { type: 'FETCHFAIL' },
                                    },
                                })
                            );
                        })
                    )
            );
        })
    );

export const updateNotificationSettingEpic = (action$, store) =>
    action$.ofType(UPDATE_NOTIFICATION_SETTING).pipe(
        switchMap((action) => {
            const updateSetting = action.payload;
            let { oidc, settings } = store.value;

            const setting = getSetting(settings.notification.list, updateSetting.notificationType);

            const request = {
                socialSecurityNumber: oidc.user.profile.sub,
                notificationSettingsItemModel: {
                    notificationType: setting.notificationType,
                    notificationTrigger: setting.notificationTrigger,
                    useEmail: updateSetting.property === 'useEmail' ? updateSetting.value : setting.useEmail,
                    usePush: updateSetting.property === 'usePush' ? updateSetting.value : setting.usePush,
                },
            };

            return concat(
                ajax
                    .post(`${endpoint}api/notification/save`, request, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        switchMap((response) => {
                            return of(
                                refreshNotificationSetting({
                                    setting: request.notificationSettingsItemModel,
                                })
                            );
                        }),
                        catchError((error) => {
                            return of(
                                refreshNotificationSetting({
                                    setting: setting,
                                })
                            );
                        })
                    )
            );
        })
    );

export const getIntegrationSettingsEpic = (action$, store) =>
    action$.ofType(GET_INTEGRATION_SETTINGS).pipe(
        switchMap((action) => {
            return concat(
                of(settingsUpdate({ integration: { isLoading: true } })),
                ajax
                    .post(
                        `${companyEndpoint}api/company-service/getcompanyintegrationsetting`,
                        {
                            organizationNumber: store.value.organisation.organisationNumber,
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        }
                    )
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        map((data) => {
                            return settingsUpdate({
                                integration: {
                                    isLoading: false,
                                    activeIntegration: data.integrationType,
                                    chosenAction: data.action,
                                    partnerType: data.partnerType,
                                },
                            });
                        }),
                        catchError((e) => {
                            console.log('e', e);
                            return of(settingsUpdate({ integration: { isLoading: false, error: 'FETCH_FAILED' } }));
                        })
                    )
            );
        })
    );

export const saveIntegrationSettingsEpic = (action$, store) =>
    action$.ofType(SAVE_INTEGRATION_SETTINGS).pipe(
        switchMap((action) => {
            return concat(
                of(
                    settingsUpdate({
                        integration: {
                            isSaving: true,
                            activeIntegration: action.payload.chosenIntegrationType,
                            chosenAction: action.payload.chosenAction,
                            partnerType: store.value.settings.integration.partnerType,
                        },
                    })
                ),
                ajax
                    .post(
                        `${companyEndpoint}api/company-service/savecompanyintegrationsetting`,
                        {
                            organizationNumber: store.value.organisation.organisationNumber,
                            integrationType: action.payload.chosenIntegrationType,
                            action: action.payload.chosenAction,
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        }
                    )
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        map((data) => {
                            return settingsUpdate({
                                integration: {
                                    isSaving: false,
                                    activeIntegration: action.payload.chosenIntegrationType,
                                    chosenAction: action.payload.chosenAction,
                                    partnerType: store.value.settings.integration.partnerType,
                                },
                            });
                        }),
                        catchError(() => {
                            return of(settingsUpdate({ integration: { isSaving: false, error: 'FETCH_FAILED' } }));
                        })
                    )
            );
        })
    );

const getSetting = (notificationSettings, notificationType) => {
    let index = notificationSettings.findIndex((setting) => {
        if (setting) {
            return setting.notificationType === notificationType;
        }
        return false;
    });
    if (index > -1) {
        return notificationSettings[index];
    }

    return undefined;
};
