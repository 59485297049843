import React from 'react';
import '../img/svg/sprite.svg';
// This is a basic component for using the SVG icon sprite that "gulp sprite" generates.
// More information can be found in the gulp file.
const Icon = (props) => (
    <svg className={props.extraClasses ? `icon icon--${props.name} ${props.extraClasses}` : `icon icon--${props.name}`}>
        <use xlinkHref={`#${props.name}`} />
    </svg>
);

export default Icon;
