import { of, concat, empty } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { isAndroid, isIOS, isWebView } from '../global/Helpers';
import ChooseCommitment from '../global/modals/ChooseCommitment';

import { CookieService } from '../global/CookieService';

import {
    ORGANISATION_GET_COMMITMENTS,
    ORGANISATION_UPDATE,
    ORGANISATION_CHOOSE_COMMITMENTS,
    ORGANISATION_GET_TASKS,
    ORGANISATION_ADD_CONTACT_DETAILS,
    ORGANISATION_GET_SETTINGS,
    organisationUpdate,
    organisationGetTasks,
    organisationGetSettings,
    pushNotificationGetSetting,
    uploadFindExistingUploads,
    modalTrigger,
    transfercostsUpdate,
    maturityStructureFetch,
    importGetNonImported,
} from '../actions';

let endpoint = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    endpoint = process.env.REACT_APP_API_URL;
}

export const organisationGetCommitmentsEpic = (action$, store) =>
    action$.ofType(ORGANISATION_GET_COMMITMENTS).pipe(
        switchMap((action) => {
            let { oidc } = store.value;
            // Get available commitments and update loading state accordingly
            return concat(
                of(organisationUpdate({ isLoading: true })),
                ajax.getJSON(`${endpoint}api/company/getAvailableCommitments`, { Authorization: `Bearer ${oidc.user.access_token}` }).pipe(
                    map((response) => {
                        return organisationUpdate({ isLoading: false, list: response });
                    }),
                    catchError((error) => {
                        return of(organisationUpdate({ isLoading: false, error: { type: 'FETCHFAIL' }, list: [] }));
                    })
                )
            );
        })
    );

export const organisationUpdateEpic = (action$, store) =>
    action$.ofType(ORGANISATION_UPDATE).pipe(
        switchMap((action) => {
            if (action.payload.name && action.payload.organisationNumber) {
                // Set cookie so we know what org has been chosen so socket connection can use it
                CookieService.setCookie('organisation-number', action.payload.organisationNumber);
                return concat(
                    of(organisationGetSettings()),
                    of(uploadFindExistingUploads()),
                    of(importGetNonImported()),
                    of(transfercostsUpdate(null)),
                    of(maturityStructureFetch()),
                    of(pushNotificationGetSetting())
                );
            }

            return empty();
        })
    );

export const organisationChooseCommitmentEpic = (action$, store) =>
    action$.ofType(ORGANISATION_CHOOSE_COMMITMENTS).pipe(
        switchMap((action) => {
            let commitment = action.payload;
            if (commitment.status === 'NotYetCustomer') {
                // TODO: Use this as a way of creating a new onboarding and link to onboarding page
                return empty();
            }
            // Set some information about what organisation was chosen in session storage for when/if user refreshes page
            localStorage.setItem(
                'commitment',
                JSON.stringify({
                    name: commitment.name,
                    organisationNumber: commitment.organisationNumber,
                    productType: commitment.productType,
                })
            );

            const userAgent = navigator.userAgent.toLowerCase();

            return concat(
                of(
                    organisationUpdate({
                        chooseCommitmentIsLoading: true,
                        isLoading: false,
                        name: commitment.name,
                        organisationNumber: commitment.organisationNumber,
                        productType: commitment.productType,
                    })
                ),
                of(organisationGetTasks()),
                ajax
                    .post(
                        `${endpoint}api/company/choseCommitment`,
                        {
                            userAgent: userAgent,
                            isWebView: isWebView(),
                            isAndroid: isAndroid,
                            isIOS: isIOS,
                            personName: store.value.oidc.user.profile.name,
                            companyName: commitment.name,
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': commitment.organisationNumber,
                        }
                    )
                    .pipe(
                        switchMap((response) => {
                            return of(organisationUpdate({ commitment: undefined, showContactInfoModal: undefined, chooseCommitmentIsLoading: false }));
                        }),
                        catchError((error) => {
                            return of(organisationUpdate({ commitment: undefined, showContactInfoModal: undefined, chooseCommitmentIsLoading: false }));
                        })
                    )
            );
        })
    );

export const organisationGetSettingsEpic = (action$, store) =>
    action$.ofType(ORGANISATION_GET_SETTINGS).pipe(
        switchMap((action) => {
            let { oidc } = store.value;
            // Get available commitments and update loading state accordingly
            return concat(
                of(organisationUpdate({ isLoading: true })),
                ajax
                    .getJSON(`${endpoint}api/company/settings`, {
                        Authorization: `Bearer ${oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        switchMap((response) => {
                            return of(organisationUpdate({ isLoading: false, settings: response }));
                        }),
                        catchError((error) => {
                            return of(organisationUpdate({ isLoading: false, error: { type: 'FETCHFAIL' }, settings: {} }));
                        })
                    )
            );
        })
    );

export const organisationGetTasksEpic = (action$, store) =>
    action$.ofType(ORGANISATION_GET_TASKS).pipe(
        switchMap((action) => {
            // This is currently not used, but it will be. At some point KYC and other stuff requires to be answered once a year.
            // The tasks should be able to tell the app if some actions need to be taken by the user, but we are not there yet.
            return concat(
                of(organisationUpdate({ isLoading: true })),
                ajax
                    .post(
                        `${endpoint}api/company/companyTasks`,
                        {
                            type: 'InvoicePurchase',
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                        }
                    )
                    .pipe(
                        mergeMap((result) => {
                            let agreementBundleId;
                            if (result.status === 204) {
                                // TODO: Fix when there is no tasks
                                return of(modalTrigger(false));
                            }
                            result.response.tasks.map((task) => {
                                if (task.type === 'AgreementSigning' && !agreementBundleId) {
                                    agreementBundleId = task.dataId;
                                }
                                return task;
                            });
                            if (result.response) {
                                let commitment = {
                                    name: store.value.organisation.name,
                                    organisationNumber: store.value.organisation.organisationNumber,
                                    productType: store.value.organisation.productType,
                                };
                                localStorage.setItem('commitment', JSON.stringify(commitment));
                                let modalShouldBeActive = [false];
                                let organisationData = {
                                    isLoading: false,
                                };
                                if (!result.response.contactDetails) {
                                    if (!commitment.contactInfo) {
                                        console.log('no contact info found');
                                        modalShouldBeActive = [true, ChooseCommitment, true];
                                        organisationData = {
                                            isLoading: false,
                                            showContactInfoModal: true,
                                            commitment: commitment,
                                        };
                                    }
                                }
                                let observablesToReturn = [of(organisationUpdate(organisationData))];
                                observablesToReturn.push(of(modalTrigger(...modalShouldBeActive)));

                                return concat(...observablesToReturn);
                            } else {
                                // TODO: Handle error
                                return of(
                                    organisationUpdate({
                                        isLoading: false,
                                    })
                                );
                            }
                        }),
                        catchError((error) => {
                            console.log(error);
                            // TODO: Handle error
                            return of(
                                organisationUpdate({
                                    isLoading: false,
                                })
                            );
                        })
                    )
            );
        })
    );

export const organisationAddContactDetailsEpic = (action$, store) =>
    action$.ofType(ORGANISATION_ADD_CONTACT_DETAILS).pipe(
        switchMap((action) => {
            const contactDetails = {
                name: action.payload.contactName,
                emailAddress: action.payload.contactEmailAddress,
                phoneNumber: action.payload.contactPhoneNumber,
            };
            return concat(
                of(organisationUpdate({ addcontactDetailsLoading: true })),
                ajax
                    .post(`${endpoint}api/onboarding/addcontactdetails`, contactDetails, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        switchMap((response) => {
                            return concat(of(modalTrigger(false)));
                        })
                    ),
                of(organisationUpdate({ showContactInfoModal: undefined, addcontactDetailsLoading: undefined }))
            );
        }),
        catchError((error) => {
            return of(
                organisationUpdate({
                    addcontactDetailsLoading: false,
                    error: {
                        message:
                            'Något gick fel när era kontaktuppgifter skulle sparas. Detta kan bero på nätverksfel eller att något gick fel i våra system. Vänligen försök igen.',
                        type: 'ADDCONTACTERROR',
                    },
                })
            );
        })
    );
