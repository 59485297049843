import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Custom quickly created protected route wrapper of an original Route from react-router-dom.
let ProtectedRoute = ({ oidc, tasks, organisation, component: Component, router, loading, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (loading.isLoading || oidc.isLoadingUser || router.location.pathname === '/') {
                // Stuff is loading, wait for it to finish an return nothing in the meantime.
                return null;
            }
            // If we are logged in, give access to the component. If not, redirect to top path which is the login page.
            return oidc.user && organisation.organisationNumber ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location },
                    }}
                />
            );
        }}
    />
);

const mapStateToProps = (state) => {
    return {
        oidc: state.oidc,
        loading: state.loading,
        router: state.router,
        organisation: state.organisation,
    };
};

ProtectedRoute = connect(mapStateToProps)(ProtectedRoute);

export default ProtectedRoute;
