export const PUSH_NOTIFICATION_GET_SETTINGS = 'PUSH_NOTIFICATION_GET_SETTINGS';
export const PUSH_NOTIFICATION_UPDATE_SETTINGS = 'PUSH_NOTIFICATION_UPDATE_SETTINGS';
export const PUSH_NOTIFICATION_DECLINE = 'PUSH_NOTIFICATION_DECLINE';
export const PUSH_NOTIFICATION_SAVE_TOKEN = 'PUSH_NOTIFICATION_SAVE_TOKEN';
export const PUSH_NOTIFICATION_RESET_ANSWER = 'PUSH_NOTIFICATION_RESET_ANSWER';

export const pushNotificationGetSetting = () => ({
    type: PUSH_NOTIFICATION_GET_SETTINGS,
});

export const pushNotificationUpdateSetting = (setting) => ({
    type: PUSH_NOTIFICATION_UPDATE_SETTINGS,
    payload: setting,
});

export const pushNotificationDecline = () => ({
    type: PUSH_NOTIFICATION_DECLINE,
});

export const pushNotificationSaveToken = (token) => ({
    type: PUSH_NOTIFICATION_SAVE_TOKEN,
    payload: { userToken: token },
});

export const pushNotificationResetAnswer = () => ({
    type: PUSH_NOTIFICATION_RESET_ANSWER,
});

export const pushNotificationSaveTokenExternal = (payload) => ({
    type: PUSH_NOTIFICATION_SAVE_TOKEN,
    payload: { userToken: payload.token, error: payload.error },
});
