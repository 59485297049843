import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../global/Spinner';
import { integrationHasCompleted, integrationSetComplete } from '../../actions';

export class StrawbayIntegrationCallbackPage extends Component {
    constructor(props) {
        super(props);
        
        this.props.integration.loading = true;
        this.props.integrationHasCompleted();

        
    }
    componentDidUpdate() {
        if(!this.props.integration.loading && !this.props.integration.completed)
        {
            const queryParameters = new URLSearchParams(window.location.search)
            const onboardingId = queryParameters.get("state");

            if(onboardingId)
            {
                this.props.integrationSetComplete( { onboardingId: onboardingId } );
            }
        }
    }
    render() {
        return (
            <div className="content__body integration">
                <div className="content__main">
                    <div className="content__wrapper content__wrapper--stuffing">
                        {this.props.integration.loading ? (
                            <div className="content__spinner">
                                <Spinner />
                            </div>
                        ) : this.props.integration.completed && (
                            <div>
                                <h1 className="title title--h1">Färdig!</h1>
                                <h3>Anslutning mellan Factoringgruppen och {this.props.integration.partner} är öppen!</h3>
                                {this.props.integration.autoPilot ? (
                                    <p className="paragraph">
                                        Nu kommer fakturor, per det datum ni valt, som bokförs men ej är skickade till kund löpande att hämtas från ert affärssystem och behandlas av
                                        Factoringgruppen per automatik eftersom ni valt "Autopilot". 
                                    </p>
                                ) : (
                                    <>
                                        <p className="paragraph">
                                            Fakturorna kommer löpande att hämtas från ert affärssystem men ni behöver logga in i mina sidor och göra era val
                                            innan Factoringgruppen börjar hanteringen av dessa.
                                        </p>
                                        <p className="paragraph">
                                            Görs inget val inom 5 dagar kommer fakturorna att rensas från Mina sidor och ni får skicka dessa själva till er
                                            fakturamottagare. Vi kommer inte att rensa fakturorna i ert affärssystem.
                                        </p>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        integration: state.integration,
        organisation: state.organisation,
    };
};

StrawbayIntegrationCallbackPage = connect(mapStateToProps, {
    integrationSetComplete,
    integrationHasCompleted
})(StrawbayIntegrationCallbackPage);

export default StrawbayIntegrationCallbackPage;