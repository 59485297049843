export const GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS';
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const SETTINGS_UPDATE = 'SETTINGS_UPDATE';
export const UPDATE_USER_SETTING = 'UPDATE_USER_SETTING';
export const UPDATE_NOTIFICATION_SETTING = 'UPDATE_NOTIFICATION_SETTING';
export const REFRESH_NOTIFICATION_SETTING = 'REFRESH_NOTIFICATION_SETTING';
export const GET_INTEGRATION_SETTINGS = 'GET_INTEGRATION_SETTINGS';
export const SAVE_INTEGRATION_SETTINGS = 'SAVE_INTEGRATION_SETTINGS';

export const getNotificationSettings = () => ({
    type: GET_NOTIFICATION_SETTINGS,
});

export const getUserSettings = () => ({
    type: GET_USER_SETTINGS,
});

export const settingsUpdate = (settings) => ({
    type: SETTINGS_UPDATE,
    payload: settings,
});

export const updateUserSetting = (userSetting) => ({
    type: UPDATE_USER_SETTING,
    payload: userSetting,
});

export const updateNotificationSetting = (updateSetting) => ({
    type: UPDATE_NOTIFICATION_SETTING,
    payload: updateSetting,
});

export const refreshNotificationSetting = (settingUpdate) => ({
    type: REFRESH_NOTIFICATION_SETTING,
    payload: settingUpdate,
});

export const getIntegrationSettings = () => ({
    type: GET_INTEGRATION_SETTINGS,
});

export const saveIntegrationSettings = (setting) => ({
    type: SAVE_INTEGRATION_SETTINGS,
    payload: setting,
});
