export const SOCKET_INIT = 'SOCKET_INIT';
export const SOCKET_SETUP_MULTIPLEX = 'SOCKET_SETUP_MULTIPLEX';
export const SOCKET_UPDATE = 'SOCKET_UPDATE';
export const SOCKET_CLOSE = 'SOCKET_CLOSE';

export const socketInit = () => ({
    type: SOCKET_INIT,
});

export const socketSetupMultiplex = (socket, auth) => ({
    type: SOCKET_SETUP_MULTIPLEX,
    payload: {
        socket: socket,
        auth: auth,
    },
});

export const socketUpdate = (state) => ({
    type: SOCKET_UPDATE,
    payload: state,
});

export const socketClose = (state) => ({
    type: SOCKET_CLOSE,
});
