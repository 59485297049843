import { Observable, of, empty, concat, timer, merge as mergeObs } from 'rxjs';
import { map, switchMap, mergeMap, catchError, takeUntil } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import { Constants } from '../global/Helpers';

import {
    // Upload
    UPLOAD_FETCH_INTERPRETER_DATA,
    UPLOAD_VALIDATE_INVOICE,
    UPLOAD_FINALIZE_SELL,
    UPLOAD_FINALIZE_SELL_TRY_AGAIN,
    UPLOAD_INIT_DETERMINATION,
    UPLOAD_GET_DETERMINATION,
    UPLOAD_GET_CONFIRMATION,
    UPLOAD_CONTACT_SUPPORT,
    uploadUpdate,
    uploadUploadSuccess,
    uploadUpdatedFileList,
    uploadValidateFail,
    uploadValidateSuccess,
    uploadUploadFailure,
    // Modal
    modalTrigger,
} from '../actions';

// Private
let url = `${window.location.origin}/`;
if(process.env.NODE_ENV === 'development') {
    url = process.env.REACT_APP_INTERPRETER_API_URL;
}

// This file is basically a graveyard of code i was too sad to let go of.
// Once upon a time, this was used to upload, interpret, and finish a sale but not any more.
// Feel free to delete, but it could serve some purpose for you if you wanna read some code and learn how i did stuff.

const findIndex = (files, interpretationId) => {
    let index = files.findIndex((fileObj) => {
        if (fileObj.invoice && fileObj.invoice.interpretationId) {
            return fileObj.invoice.interpretationId === interpretationId;
        }
        if (fileObj.invoice && fileObj.invoice.id) {
            return fileObj.invoice.id === interpretationId;
        }
        if (fileObj.interpretationId) {
            return fileObj.interpretationId === interpretationId;
        }
        if (fileObj.id) {
            return fileObj.id === interpretationId;
        }
        return false;
    });

    return index;
};

const findDoneCountAndValidFiles = (store) => {
    let doneCount = 0;
    let validFiles = store.value.upload.files.filter((fileObj) => {
        return (
            !fileObj.hasEmptyField &&
            fileObj.recipientIsKnown &&
            (fileObj.decision.decisionStatus === 'Buy' || fileObj.decision.decisionStatus === 'BuyWithRecipientApproval')
        );
    });
    validFiles.forEach((fileObj) => {
        if (fileObj.historyModel) {
            doneCount++;
        }
    });

    return {
        doneCount: doneCount,
        validFiles: validFiles,
    };
};

const handleFinalizeSellResponse = (invoices, store) => {
    let returnStatement = [empty()];
    invoices.map((confirmResponse) => {
        if (confirmResponse.confirmationStatus === 'Sent') {
            return confirmResponse;
        }
        let index = findIndex(store.value.upload.files, confirmResponse.interpretationId);
        if (confirmResponse.confirmationStatus === 'InvoiceNotStampedCorrectly') {
            store.value.upload.files[index] = {
                ...store.value.upload.files[index],
                historyModel: {
                    confirmationStatus: confirmResponse.confirmationStatus,
                },
            };
        } else {
            store.value.upload.files[index] = {
                ...store.value.upload.files[index],
                historyModel: {
                    error: true,
                    errorType: confirmResponse.confirmationStatus,
                },
            };
        }

        let { doneCount, validFiles } = findDoneCountAndValidFiles(store);

        returnStatement.push(
            of(
                uploadUpdate({
                    ...store.value.upload,
                    isFinalizing: !(doneCount === validFiles.length),
                })
            )
        );

        return confirmResponse;
    });
    return concat(...returnStatement);
};

export const uploadFetchInterpreterDataEpic = (action$, store) =>
    action$.ofType(UPLOAD_FETCH_INTERPRETER_DATA).pipe(
        map(({ payload }) => payload),
        switchMap((payload) => {
            return concat(
                ajax
                    .get(`${url}api/interpreter-service/upload/matches/${payload}`, {
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        map((response) => response.response),
                        map((response) => {
                            let matches = [];
                            let index = findIndex(store.value.upload.files, payload);
                            for (const match of response.otherMatches) {
                                let localMatches = match.matches.map((d) => {
                                    d.potentialMatch = [...match.fields];
                                    d.value = d.formattedValue;
                                    return d;
                                });
                                matches = [...matches, ...localMatches];
                            }
                            store.value.upload.files[index].cacheKeys = [...response.cacheKeys];
                            return uploadUploadSuccess({
                                ...store.value.upload.files[index],
                                otherMatches: matches,
                                success: true,
                            });
                        })
                    )
            );
        })
    );

export const uploadValidateInvoiceEpic = (action$, store) =>
    action$.ofType(UPLOAD_VALIDATE_INVOICE).pipe(
        map(({ payload }) => {
            return payload;
        }),
        mergeMap((payload) => {
            Object.keys(payload.invoice.invoice).forEach((prop) => {
                let obj = payload.invoice.invoice[prop];
                if (obj && obj.value) {
                    obj.rawValue = obj.value;
                }
            });
            let index = findIndex(store.value.upload.files, payload.interpretationId || payload.invoice.id);
            // Do a local validation before sending it to backend
            let hasEmptyField =
                !payload.invoice.invoice.street ||
                !payload.invoice.invoice.zipCode ||
                !payload.invoice.invoice.invoiceCreated ||
                !payload.invoice.invoice.invoiceDueDate ||
                !payload.invoice.invoice.invoiceNumber ||
                !payload.invoice.invoice.invoiceAmount;
            let recipientIsKnown = false;
            if (payload.invoice.invoice.recipientOrganizationNumber && payload.invoice.invoice.recipientOrganizationNumber.formattedValue) {
                recipientIsKnown =
                    store.value.recipients.recipients.findIndex(
                        (recipient) => recipient.organisationNumber === payload.invoice.invoice.recipientOrganizationNumber.formattedValue
                    ) > -1;
            }
            if (hasEmptyField || !recipientIsKnown) {
                return concat(
                    of(uploadValidateFail(payload.invoice.invoice, null)),
                    of(
                        uploadUploadSuccess({
                            ...store.value.upload.files[index],
                            hasEmptyField: hasEmptyField,
                            isLoading: false,
                            recipientIsKnown: recipientIsKnown,
                        })
                    )
                );
            }
            // The invoice haven't changed so there is no need to save it
            if (JSON.stringify(store.value.upload.files[index].invoice) === JSON.stringify(payload.invoice)) {
                return of(
                    uploadUploadSuccess({
                        ...store.value.upload.files[index],
                        hasEmptyField: hasEmptyField,
                        isLoading: false,
                        recipientIsKnown: recipientIsKnown,
                    })
                );
            }
            // Validation passed, send to backend for real validation
            let retunStatement = [
                of(
                    uploadUploadSuccess({
                        ...store.value.upload.files[index],
                        isLoading: true,
                        loadingText: 'Sparar faktura',
                    })
                ),
                ajax
                    .post(`${url}api/interpreter-service/upload/save`, payload.invoice, {
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                        'Content-Type': 'application/json',
                    })
                    .pipe(
                        map((response) => response.response),
                        switchMap((response) => {
                            index = findIndex(store.value.upload.files, payload.interpretationId || payload.invoice.id);
                            hasEmptyField =
                                !response.invoice.invoice.street ||
                                !response.invoice.invoice.zipCode ||
                                !response.invoice.invoice.invoiceCreated ||
                                !response.invoice.invoice.invoiceDueDate ||
                                !response.invoice.invoice.invoiceNumber ||
                                !response.invoice.invoice.invoiceAmount;

                            recipientIsKnown = false;
                            if (response.invoice.invoice.recipientOrganizationNumber && response.invoice.invoice.recipientOrganizationNumber.formattedValue) {
                                recipientIsKnown =
                                    store.value.recipients.recipients.findIndex(
                                        (recipient) => recipient.organisationNumber === response.invoice.invoice.recipientOrganizationNumber.formattedValue
                                    ) > -1;
                            }
                            // Something went really bad down there in the server
                            if (response.saveError) {
                                return of(
                                    uploadUploadFailure({
                                        ...store.value.upload.files[index],
                                        isLoading: false,
                                        success: false,
                                        error: true,
                                        errorText: 'Kunde inte spara fakturan.',
                                        loadingText: undefined,
                                    })
                                );
                            }
                            // If valid...
                            if (!hasEmptyField && recipientIsKnown) {
                                // Tell everyone that all is good
                                return concat(
                                    of(
                                        uploadUploadSuccess({
                                            ...store.value.upload.files[index],
                                            isLoading: false,
                                            hasEmptyField: hasEmptyField,
                                            recipientIsKnown: recipientIsKnown,
                                        })
                                    ),
                                    of(uploadValidateSuccess(response.invoice))
                                );
                            }

                            // No correct validation had been found, we can safely assume that something's wrong.
                            console.log('Validation error');
                            return concat(
                                of(uploadValidateFail(response.invoice, response.fields)),
                                of(
                                    uploadUploadSuccess({
                                        ...store.value.upload.files[index],
                                        isLoading: false,
                                        hasEmptyField: hasEmptyField,
                                        recipientIsKnown: recipientIsKnown,
                                    })
                                )
                            );
                        })
                    ),
            ];
            if (store.value.modal.isOpen) {
                retunStatement.push(of(modalTrigger(false)));
            }
            return concat(...retunStatement);
        })
    );

export const uploadInitDeterminationEpic = (action$, store) =>
    action$.ofType(UPLOAD_INIT_DETERMINATION).pipe(
        mergeMap((action) => {
            action.payload.forEach((interpretationId) => {
                const index = findIndex(store.value.upload.files, interpretationId);
                if (index > -1) {
                    delete store.value.upload.files[index].decision;
                }
            });
            // Tell redux store that we have updated the data
            return concat(
                of(uploadUpdate(store.value.upload)),
                ajax
                    .post(
                        `${url}api/invoice/sell`,
                        {
                            invoiceInterpretationIds: action.payload,
                        },
                        {
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                            'Content-Type': 'application/json',
                        }
                    )
                    .pipe(
                        map((response) => response.response),
                        mergeMap((sellStates) => {
                            /*
                             * What happens in this flow is the following:
                             *   1. This initialization finishes and backend knows that determination has been initialized
                             *   2. Backend creates a determination based on a number of factors
                             *   3. The socket server receives determination and sends to appropriate authenticated client listener
                             *   4. SocketEpic multiplex receives determination and tells store to update fileObj
                             */
                            sellStates.map((state) => {
                                const index = findIndex(store.value.upload.files, state.interpretationId);
                                store.value.upload.files[index] = {
                                    ...store.value.upload.files[index],
                                    decision: {
                                        status: state.status,
                                    },
                                };
                                return state;
                            });

                            // Create timers for each interpretations id thats has been initialized to be sold
                            let timers = [];
                            action.payload.forEach((interpretationId) => {
                                // Create observable that stops timer if a backend has told us to fetch a determination
                                let stateIndex = sellStates.findIndex((state) => state.interpretationId === interpretationId);
                                if (stateIndex > -1 && sellStates[stateIndex].status === 'Sent') {
                                    let stopTimer = Observable.create((observer) => {
                                        const subscriber = action$.ofType(UPLOAD_GET_DETERMINATION).subscribe((determinationAction) => {
                                            let interpretationIdToStopTimer = determinationAction.payload;
                                            if (interpretationId === interpretationIdToStopTimer) {
                                                observer.next();
                                                subscriber.unsubscribe();
                                            }
                                        });
                                    });
                                    // Create a new timer that if it finishes, force in a retry decision in order for components to react accordingly
                                    let timerForId = timer(30000).pipe(
                                        takeUntil(stopTimer),
                                        switchMap((d) => {
                                            let index = findIndex(store.value.upload.files, interpretationId);
                                            return of(
                                                uploadUploadSuccess({
                                                    ...store.value.upload.files[index],
                                                    decision: {
                                                        decisionStatus: 'Retry',
                                                    },
                                                })
                                            );
                                        })
                                    );
                                    // Add timer to array of timers
                                    timers.push(timerForId);
                                } else if (stateIndex > -1) {
                                    let index = findIndex(store.value.upload.files, interpretationId);
                                    store.value.upload.files[index].decision = {
                                        decisionStatus: sellStates[stateIndex].status,
                                    };
                                }
                            });
                            return concat(
                                of(uploadUpdatedFileList(store.value.upload.files)),
                                mergeObs(
                                    ...timers // Also use all the timers we created
                                )
                            );
                        })
                    )
            );
        })
    );

export const uploadGetDeterminationEpic = (action$, store) =>
    action$.ofType(UPLOAD_GET_DETERMINATION).pipe(
        map(({ payload }) => payload),
        mergeMap((payload) => {
            let interpretationId = payload;
            return ajax
                .get(`${url}api/invoice/sell/result/${interpretationId}`, {
                    Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                    'organisation-number': store.value.organisation.organisationNumber,
                    'Content-Type': 'application/json',
                })
                .pipe(
                    map((response) => response.response),
                    switchMap((response) => {
                        let index = findIndex(store.value.upload.files, interpretationId);
                        let decision = {
                            decisionStatus: response.decision,
                        };
                        // TODO: Remove this when administer only is in scope again 
                        if (response.decision === 'AdministerOnly') {
                            decision = {
                                decisionStatus: 'Reject',
                            };
                        }

                        if (response.debitInformation) {
                            decision = {
                                paidImmediately: response.debitInformation.paidImmediately.replace(',', '.'),
                                financedAmount: response.debitInformation.financedAmount.replace(',', '.'),
                                depositionUsage: response.debitInformation.depositionUsage.replace(',', '.'),
                                commissionFee: response.debitInformation.commissionFee.replace(',', '.'),
                                administrativeFees: response.debitInformation.administrativeFees.replace(',', '.'),
                                commissionRate: response.debitInformation.commissionRate.replace(',', '.'),
                                numberOfInvoiceDays: response.debitInformation.numberOfInvoiceDays,
                                ...decision,
                            };
                        }
                        return concat(
                            of(
                                uploadUploadSuccess({
                                    ...store.value.upload.files[index],
                                    decision: {
                                        ...decision,
                                    },
                                })
                            )
                        );
                    }),
                    catchError((error) => {
                        // TODO: Handle error!
                        return empty();
                    })
                );
        })
    );
//

export const uploadFinalizeSellEpic = (action$, store) =>
    action$.ofType(UPLOAD_FINALIZE_SELL).pipe(
        mergeMap((action) => {
            let interpretationIdsForSell = [];
            let interpretationIdsForAdministration = [];
            let returnStatement = [
                of(
                    uploadUpdate({
                        ...store.value.upload,
                        isFinalizing: true,
                    })
                ),
            ];
            store.value.upload.files.forEach((inputObj) => {
                switch (inputObj.decision.decisionStatus) {
                    case 'Buy':
                    case 'BuyWithRecipientApproval':
                        interpretationIdsForSell.push(inputObj.interpretationId);
                        break;
                    case 'AdministerOnly':
                        interpretationIdsForAdministration.push(inputObj.interpretationId);
                        break;
                    case 'NoDecision':
                    case 'Retry':
                    case 'Reject':
                    default:
                        // Do nothing
                        break;
                }
            });
            if (interpretationIdsForSell.length > 0) {
                let postForSell = ajax
                    .post(
                        `${url}api/invoice/confirm`,
                        {
                            InvoiceInterpretationIds: [...interpretationIdsForSell],
                        },
                        {
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                            'Content-Type': 'application/json',
                        }
                    )
                    .pipe(
                        map((response) => response.response),
                        mergeMap(({ invoices }) => {
                            return handleFinalizeSellResponse(invoices, store);
                        }),
                        catchError((error) => {
                            console.log("Couldn't post data for confirm sell", error);
                            // TODO: Handle error
                            return of(
                                uploadUpdate({
                                    ...store.value.upload,
                                    isFinalizing: false,
                                })
                            );
                        })
                    );
                returnStatement.push(postForSell);
            }
            return concat(...returnStatement);
        })
    );

export const uploadFinalizeSellTryAgainEpic = (action$, store) =>
    action$.ofType(UPLOAD_FINALIZE_SELL_TRY_AGAIN).pipe(
        mergeMap((action) => {
            let interpretationIdsForSellTryAgain = [];
            let interpretationIdsForAdministrationTryAgain = [];
            let returnStatement = [
                of(
                    uploadUpdate({
                        ...store.value.upload,
                        isFinalizing: true,
                    })
                ),
            ];
            store.value.upload.files.forEach((inputObj) => {
                if (
                    (inputObj.historyModel && inputObj.historyModel.error) ||
                    (inputObj.historyModel && inputObj.historyModel.invoiceAddedStatus === 'UnsuccessfullyAdded')
                ) {
                    switch (inputObj.decision.decisionStatus) {
                        case 'Buy':
                        case 'BuyWithRecipientApproval':
                            interpretationIdsForSellTryAgain.push(inputObj.interpretationId);
                            break;
                        case 'AdministerOnly':
                            interpretationIdsForAdministrationTryAgain.push(inputObj.interpretationId);
                            break;
                        case 'NoDecision':
                        case 'Retry':
                        case 'Reject':
                        default:
                            // Do nothing
                            break;
                    }
                }
            });
            if (interpretationIdsForSellTryAgain.length > 0) {
                let postForSell = ajax
                    .post(
                        `${url}api/invoice/confirm`,
                        {
                            InvoiceInterpretationIds: [...interpretationIdsForSellTryAgain],
                        },
                        {
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                            'Content-Type': 'application/json',
                        }
                    )
                    .pipe(
                        map((response) => response.response),
                        mergeMap(({ invoices }) => {
                            return handleFinalizeSellResponse(invoices, store);
                        }),
                        catchError((error) => {
                            console.log("Couldn't post data for retrying confirm sell", error);
                            // TODO: Handle error
                            return of(
                                uploadUpdate({
                                    ...store.value.upload,
                                    isFinalizing: false,
                                })
                            );
                        })
                    );
                returnStatement.push(postForSell);
            }
            return concat(...returnStatement);
        })
    );

export const uploadGetConfirmation = (action$, store) =>
    action$.ofType(UPLOAD_GET_CONFIRMATION).pipe(
        map(({ payload }) => payload),
        mergeMap((interpretationId) => {
            return ajax
                .get(`${url}api/invoice/confirm/result/${interpretationId}`, {
                    Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                    'organisation-number': store.value.organisation.organisationNumber,
                    'Content-Type': 'application/json',
                })
                .pipe(
                    map((response) => response.response),
                    mergeMap((response) => {
                        let index = findIndex(store.value.upload.files, interpretationId);
                        let fileObj = store.value.upload.files[index];
                        if (index > -1) {
                            store.value.upload.files[index] = {
                                ...fileObj,
                                historyModel: {
                                    ...response,
                                },
                            };
                        }

                        let { doneCount, validFiles } = findDoneCountAndValidFiles(store);
                        return of(
                            uploadUpdate({
                                ...store.value.upload,
                                isFinalizing: !(doneCount === validFiles.length),
                            })
                        );
                    }),
                    catchError((error) => {
                        console.log("Couldn't get confirmation", error);
                        let index = findIndex(store.value.upload.files, interpretationId);
                        let fileObj = store.value.upload.files[index];
                        if (index > -1) {
                            store.value.upload.files[index] = {
                                ...fileObj,
                                historyModel: {
                                    error: true,
                                },
                            };
                        }
                        // TODO: Handle error
                        let { doneCount, validFiles } = findDoneCountAndValidFiles(store);
                        return of(
                            uploadUpdate({
                                ...store.value.upload,
                                isFinalizing: !(doneCount === validFiles.length),
                                finalizeError: true,
                            })
                        );
                    })
                );
        })
    );

export const uploadContactSupportEpic = (action$, store) =>
    action$.ofType(UPLOAD_CONTACT_SUPPORT).pipe(
        map(({ payload }) => payload),
        mergeMap((payload) => {
            // regex for validating email
            const regex = Constants.emailRegex();
            let validEmail = payload.email && regex.test(payload.email);
            let hasEmptyField = !payload.question || !validEmail;

            if (!hasEmptyField) {
                return ajax
                    .post(
                        `${url}api/interpreter-service/upload/interpretationhelp`,
                        {
                            interpretationId: payload.fileObj.interpretationId,
                            description: payload.question,
                            emailAddress: payload.email,
                        },
                        {
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                            'Content-Type': 'application/json',
                        }
                    )
                    .pipe(
                        map((response) => response.response),
                        mergeMap((response) => {
                            store.value.upload = {
                                ...store.value.upload,
                                questionAskedSuccess: true,
                                supportHasEmptyField: false,
                            };
                            return of(
                                uploadUpdate({
                                    ...store.value.upload,
                                })
                            );
                        }),
                        catchError((error) => {
                            console.log("Couldn't post message for support", error);
                            // TODO: Handle error
                            return empty();
                        })
                    );
            } else {
                return of(
                    uploadUpdate({
                        ...store.value.upload,
                        supportHasEmptyField: true,
                    })
                );
            }
        })
    );
