import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'redux-first-history';
import { userManager } from '../../store';

class SignInCallback extends React.Component {
    render() {
        let url = localStorage.getItem('deepLink') || 'hem';
        let search = localStorage.getItem('deepLinkSearch') || '';
        localStorage.removeItem('deepLink');
        localStorage.removeItem('deepLinkSearch');
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={() => this.props.push({ pathname: url, search: search })}
                errorCallback={(error) => {
                    this.props.push({ pathname: '/' });
                    console.error(error);
                }}
            >
                <React.Fragment></React.Fragment>
            </CallbackComponent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        router: state.router,
    };
};
SignInCallback = connect(mapStateToProps, {
    push,
})(SignInCallback);

export default SignInCallback;
