export const TRANSFERCOSTS_FETCH_LIST = 'TRANSFERCOSTS_FETCH_LIST';
export const TRANSFERCOSTS_UPDATE = 'TRANSFERCOSTS_UPDATE';
export const TRANSFERCOSTS_RECORD_DETAILS_FETCH = 'TRANSFERCOSTS_RECORD_DETAILS_FETCH';
export const TRANSFERCOSTS_RECORD_DETAILS_DOWLOAD_DOC = 'TRANSFERCOSTS_RECORD_DETAILS_DOWLOAD_DOC';
export const TRANSFER_RECORD_DETAILS_DOWNLOAD_SIE_FILE = 'TRANSFER_RECORD_DETAILS_DOWNLOAD_SIE_FILE';

export const transfercostsFetchList = (dates) => ({
    type: TRANSFERCOSTS_FETCH_LIST,
    payload: dates,
});

export const transfercostsUpdate = (state) => ({
    type: TRANSFERCOSTS_UPDATE,
    payload: state,
});

export const transfercostsRecordDetailsFetch = (id) => ({
    type: TRANSFERCOSTS_RECORD_DETAILS_FETCH,
    payload: id,
});

export const transfercostsRecordDetailsDownloadDoc = (id) => ({
    type: TRANSFERCOSTS_RECORD_DETAILS_DOWLOAD_DOC,
    payload: id,
});

export const transfercostsRecordDetailsDownloadSieFile = (id) => ({
    type: TRANSFER_RECORD_DETAILS_DOWNLOAD_SIE_FILE,
    payload: id,
});
