export const MATURITY_STRUCTURE_FETCH = 'MATURITY_STRUCTURE_FETCH';
export const MATURITY_STRUCTURE_UPDATE = 'MATURITY_STRUCTURE_UPDATE';

export const maturityStructureFetch = (filter = {}) => ({
    type: MATURITY_STRUCTURE_FETCH,
    payload: filter,
});

export const maturityStructureUpdate = (state) => ({
    type: MATURITY_STRUCTURE_UPDATE,
    payload: state,
});
