// Components
import SellInvoicePage from './pages/sell-invoice/SellInvoicePage';
import ImportInvoicePage from './pages/sell-invoice/ImportInvoicePage';
import HistoryInvoicesPage from './pages/history/HistoryInvoicesPage';
import HistoryTransfercostsPage from './pages/history/HistoryTransfercostsPage';
import InvoiceSpecificationPage from './pages/invoice-specification/InvoiceSpecificationPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import RecipientsPage from './pages/recipients/RecipientsPage';
import UserSettingsPage from './pages/setting/UserSettingsPage';
import NotificationRouter from './pages/notification/NotificationRouter';
import IntegrationPage from './pages/setting/IntegrationPage';
import StrawbayIntegrationCallbackPage from './pages/setting/StrawbayIntegrationCallbackPage';

export const routes = [
    {
        name: '/hem',
        includeInNav: true,
        linkText: 'Startsida',
        isProtected: false,
        isHidden: false,
        isExact: true,
        icon: {
            name: 'dashboard',
            activeName: 'dashboard-active',
        },
        availableForClientType: ['Factoringgruppen', 'AtivoCore'],
        component: DashboardPage,
    },
    {
        name: '/sälj',
        includeInNav: true,
        linkText: 'Sälj faktura',
        isProtected: true,
        isHidden: false,
        isExact: true,
        feature: { name: 'import', toggle: true },
        icon: {
            name: 'invoice',
            activeName: 'invoice-active',
        },
        availableForClientType: ['Factoringgruppen'],
        redirectTo: '/sälj/fakturor',
        notificationCounter: (props) => {
            return props.upload.files.length + props.import.invoices.length;
        },
        childRoutes: [
            {
                name: '/sälj/fakturor',
                includeInNav: true,
                linkText: 'Ladda upp',
                isProtected: true,
                isHidden: false,
                isExact: false,
                availableForClientType: ['Factoringgruppen'],
                notificationCounter: (props) => {
                    return props.upload.files.length;
                },
                component: SellInvoicePage,
            },
            {
                name: '/sälj/importera',
                includeInNav: true,
                linkText: 'Importerade',
                isProtected: true,
                isHidden: false,
                isExact: false,
                availableForClientType: ['Factoringgruppen'],
                notificationCounter: (props) => {
                    return props.import.invoices.length;
                },
                component: ImportInvoicePage,
            },
        ],
    },
    {
        name: '/historik',
        includeInNav: true,
        linkText: 'Historik',
        isProtected: true,
        isHidden: false,
        isExact: true,
        icon: {
            name: 'clock',
            activeName: 'clock-active',
        },
        redirectTo: '/historik/fakturor',
        availableForClientType: ['Factoringgruppen', 'AtivoCore'],
        childRoutes: [
            {
                name: '/historik/fakturor',
                includeInNav: true,
                linkText: 'Fakturor',
                isProtected: true,
                isHidden: false,
                isExact: true,
                availableForClientType: ['Factoringgruppen', 'AtivoCore'],
                component: HistoryInvoicesPage,
            },
            {
                name: '/historik/fakturor/:id',
                linkText: 'Fakturaspecification',
                includeInNav: false,
                isProtected: true,
                isHidden: false,
                isExact: true,
                availableForClientType: ['Factoringgruppen', 'AtivoCore'],
                component: InvoiceSpecificationPage,
            },
            {
                name: '/historik/avräkningar',
                includeInNav: true,
                linkText: 'Avräkningar',
                isProtected: true,
                isHidden: false,
                isExact: true,
                availableForClientType: ['Factoringgruppen', 'AtivoCore'],
                component: HistoryTransfercostsPage,
            },
        ],
    },
    {
        name: '/mottagare',
        includeInNav: true,
        linkText: 'Mottagare',
        isProtected: false,
        isHidden: true,
        isExact: true,
        icon: {
            name: 'recipient',
            activeName: 'recipient-active',
        },
        availableForClientType: ['Factoringgruppen', 'AtivoCore'],
        component: RecipientsPage,
    },
    {
        name: '/inställningar',
        includeInNav: true,
        linkText: 'Inställningar',
        isProtected: true,
        isHidden: false,
        isExact: true,
        icon: {
            name: 'settings',
            activeName: 'settings-active',
        },
        availableForClientType: ['Factoringgruppen', 'AtivoCore'],
        redirectTo: '/inställningar/mina-inställningar',
        childRoutes: [
            {
                name: '/inställningar/mina-inställningar',
                includeInNav: true,
                linkText: 'Mina inställningar',
                isProtected: true,
                isHidden: false,
                isExact: false,
                availableForClientType: ['Factoringgruppen', 'AtivoCore'],
                component: UserSettingsPage,
            },
            {
                name: '/inställningar/integrationer',
                includeInNav: true,
                linkText: 'Integrationer',
                isProtected: true,
                isHidden: false,
                isExact: true,
                availableForClientType: ['Factoringgruppen'],
                component: IntegrationPage,
            },
            {
                name: '/inställningar/integrationer/cb/strawbay',
                includeInNav: false,
                isProtected: true,
                isHidden: true,
                isExact: false,
                availableForClientType: ['Factoringgruppen'],
                component: StrawbayIntegrationCallbackPage,
            }
        ],
    },
    {
        name: '/notifiering/:id',
        includeInNav: false,
        isProtected: true,
        isHidden: true,
        isExact: true,
        availableForClientType: ['Factoringgruppen', 'AtivoCore'],
        component: NotificationRouter,
    },
];
