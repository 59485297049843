import { concat, merge, empty, of, timer, fromEvent } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { mergeMap, switchMap, catchError, retryWhen, takeUntil, tap, delay } from 'rxjs/operators';

import {
    SOCKET_INIT,
    SOCKET_SETUP_MULTIPLEX,
    SOCKET_CLOSE,
    socketSetupMultiplex,
    socketUpdate,
    // Upload
    uploadGetResult,
} from '../actions';

let socketEndpoint = `wss://${window.location.host}/socket`;
if (process.env.NODE_ENV === 'development') {
    socketEndpoint = process.env.REACT_APP_SOCKET_URL;
}

// Create a socket using rxjs webSocket. This needs to be global in order to be able to reconnect after a sudden disconnect
let socket;

export const socketInitEpic = (action$, store) =>
    action$.ofType(SOCKET_INIT).pipe(
        mergeMap((action) => {
            if (socket) {
                // Close current socket if there is one
                socket.complete();
            }
            // Always create new socket if init has been called.
            socket = webSocket({
                url: `${socketEndpoint}`,
            });
            socket.pipe(
                retryWhen((err) => {
                    //console.log('Socket reporting error: ', err);
                    // Check for online status. If online, wait another second and check if connection to the internet exists
                    if (window.navigator.onLine) {
                        return timer(1000);
                    } else {
                        // If browser is offline, trigger a new connection when we're back online
                        return fromEvent(window, 'online');
                    }
                }),
                catchError((error) => {
                    //console.log(error);
                    return empty();
                })
            );
            return concat(of(socketUpdate({ socket: socket })), of(socketSetupMultiplex(socket)));
        }),
        catchError((error) => {
            //console.log(error);
            return empty();
        })
    );
let multiplexes = [];
export const socketSetupMuliplexEpic = (action$, store) =>
    action$.ofType(SOCKET_SETUP_MULTIPLEX).pipe(
        mergeMap((action) => {
            let broadcastObservable = socket
                .multiplex(
                    () => ({ subscribe: 'broadcast' }),
                    () => ({ unsubscribe: 'broadcast' }),
                    ({ Subject }) => {
                        return Subject === 'broadcast';
                    }
                )
                .pipe(
                    takeUntil(action$.ofType(SOCKET_CLOSE)), // If socket is closed, immediately stop receiving data
                    retryWhen((errors) => {
                        // errors are observable. When it triggers, console log the error once (tap) and delay a second
                        return errors.pipe(
                            tap((err) => {
                                //console.log('Broadcast multiplex reporting error: ', err);
                            }),
                            delay(1000)
                        );
                    }),
                    switchMap((d) => {
                        // This shouldn't do anything. This multiplex is merely here to make sure that messages are received and for keep-alive purposes.
                        return empty();
                    })
                );

            let invoiceInterpretationUploadDoneObservable = socket
                .multiplex(
                    () => ({ subscribe: 'InvoiceInterpretationUploadDone, InvoiceInterpretationDone' }),
                    () => ({ unsubscribe: 'InvoiceInterpretationUploadDone, InvoiceInterpretationDone' }),
                    ({ Subject }) => {
                        return Subject === 'InvoiceInterpretationUploadDone' || Subject === 'InvoiceInterpretationDone';
                    }
                )
                .pipe(
                    takeUntil(action$.ofType(SOCKET_CLOSE)), // If socket is closed, immediately stop receiving data
                    retryWhen((errors) => {
                        // errors are observable. When it triggers, console log the error once (tap) and delay a second
                        return errors.pipe(
                            tap((err) => {
                                //console.log('InvoiceInterpretationUploadDone/InvoiceInterpretationDone multiplex reporting error: ', err);
                            }),
                            delay(1000)
                        );
                    }),
                    mergeMap((message) => {
                        let invoiceData = JSON.parse(message.Data);
                        // Tell uploadEpic to get result of interpretation
                        return of(uploadGetResult(invoiceData.InvoiceInterpretationId)).pipe(delay(1000));
                    })
                );

            // Create array of multiplexes just for readability. Join them in an array and then spread them in a merged observable, again, just for readability purposes.
            multiplexes = [...multiplexes, broadcastObservable, invoiceInterpretationUploadDoneObservable];
            return merge(...multiplexes);
        }),
        catchError((error) => {
            //console.log(error);
            return empty();
        })
    );

export const socketCloseEpic = (action$, store) =>
    action$.ofType(SOCKET_CLOSE).pipe(
        mergeMap((action) => {
            // Close socket and return nothing. This is end of life for socket.
            if (socket) {
                socket.complete();
            }
            socket = undefined;
            return empty();
        })
    );
