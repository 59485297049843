import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Subject, fromEvent, forkJoin } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import * as moment from 'moment';

import {
    uploadRemoveFromList,
    modalTrigger,
    uploadValidateInvoice,
    uploadGetResult,
    uploadUploadSuccess,
    uploadFileUpdateEmail,
    uploadFileAddComment,
    uploadUploadAttachment,
} from '../../../actions';

import ImageSlider from '../../../global/ImageSlider';
import Spinner from '../../../global/Spinner';
import { Constants } from '../../../global/Helpers';
import AttachmentUploader from './AttachmentUploader';

// this is the detailed view of an uploaded file
class FileItemDetails extends Component {
    constructor(props) {
        super(props);
        const fileObj = this.props.upload.files[this.props.index];
        this.state = {
            confirmDelete: false,
            imgUrls: [],
            imageLoading: false,
            emailIsValid: true,
            email: fileObj && fileObj.email ? fileObj.email : '',
            comment: fileObj && fileObj.comment ? fileObj.comment : '',
        };
        this.changeSubject = new Subject();
        this.subscriber = [];
        this.subscriber.push(this.changeSubject.subscribe((changeEvent) => this.handleValueUpdate(changeEvent)));
    }
    componentWillUnmount() {
        this.subscriber.forEach((sub) => {
            sub.unsubscribe();
        });
        if (this.state.emailIsValid) {
            this.props.uploadFileAddComment(this.state.comment, this.props.index);
            this.props.uploadFileUpdateEmail(this.state.email, this.props.index);
        } else {
            return false;
        }
    }
    componentDidMount() {
        let fileObj = this.props.upload.files[this.props.index];
        this.findImages(fileObj);
    }
    componentDidUpdate(prevProps, prevState) {
        let fileObj = this.props.upload.files[this.props.index];
        if (this.state.imageLoading && JSON.stringify(prevProps) === JSON.stringify(this.props)) {
            return;
        }
        if (this.state.imgUrls.length === 0 || JSON.stringify(prevState.imgUrls) !== JSON.stringify(this.state.imgUrls)) {
            this.findImages(fileObj);
        }
    }
    findImages(fileObj) {
        let cacheKeys = fileObj.cacheKeys;
        if (cacheKeys && cacheKeys.length > 0) {
            let imageFetchers = [];
            // Create observables for fetching images
            cacheKeys.forEach((cacheKey, index) => {
                let baseUrl = `${window.location.origin}/`;
                if (process.env.NODE_ENV === 'development') {
                    baseUrl = process.env.REACT_APP_INTERPRETER_API_URL;
                }
                let endpoint = `${baseUrl}api/interpreter-service/upload/getdocument?i=${cacheKey}`;
                const getFileRequest = ajax({
                    url: endpoint,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${this.props.oidc.user.access_token}`,
                        'organisation-number': this.props.organisation.organisationNumber,
                    },
                    responseType: 'arraybuffer',
                });

                imageFetchers.push(getFileRequest);
            });
            this.setState(
                (state) => {
                    state.imageLoading = true;
                    return state;
                },
                () => {
                    // Go fetch all images at the same time using rxjs forkJoin
                    let subscriber = forkJoin(...imageFetchers).subscribe((responses) => {
                        let imgUrls = [];
                        // Create objects for each image
                        responses.forEach((response, index) => {
                            const buffer = response.response;
                            const type = response.xhr.getResponseHeader('content-type');

                            const blob = new Blob([buffer], { type: type });
                            const url = window.URL.createObjectURL(blob);
                            imgUrls.push({
                                src: url,
                                alt: `Faktura sida nr ${index + 1}`,
                            });
                        });
                        // Tell react stuff has happened
                        this.setState((state) => {
                            state.imgUrls = [...imgUrls];
                            state.imageLoading = false;
                            return state;
                        });
                        // Make sure to clean up subscription after we are done
                        subscriber.unsubscribe();
                    });
                    this.subscriber.push(subscriber);
                }
            );
        }
    }
    findIndex() {
        return this.props.upload.files.findIndex((file) => {
            if (file.invoice && file.invoice.interpretationId) {
                return file.invoice.interpretationId === this.props.interpretationId;
            }

            if (file.interpretationId) {
                return file.interpretationId === this.props.interpretationId;
            }

            return false;
        });
    }
    removeItem(event) {
        event.preventDefault();

        if (!this.state.emailIsValid) {
            this.props.onValidationChanged(true);
        }

        let fileObj = this.props.upload.files[this.props.index];
        this.props.uploadRemoveFromList(fileObj, this.props.index);
    }
    confirmDelete(event) {
        event.preventDefault();
        if (this.clickSub) {
            this.clickSub.unsubscribe();
        }
        this.setState(
            (state) => {
                if (state.confirmDelete) {
                    state.confirmDelete = false;
                } else {
                    state.confirmDelete = true;
                }
                return state;
            },
            () => {
                this.clickObs = fromEvent(document, 'click');
                this.clickSub = this.clickObs.subscribe((e) => {
                    let el = document.querySelector('.confirm-action');
                    if (el && !el.contains(e.target) && e.target.className.indexOf('button button--chip button--warning') === -1) {
                        this.clickSub.unsubscribe();
                        this.setState((state) => {
                            state.confirmDelete = false;
                            return state;
                        });
                    }
                });
                this.subscriber.push(this.clickSub);
            }
        );
    }
    handleValueUpdate(changeEvent) {
        let fileObj = this.props.upload.files[this.props.index];
        if (changeEvent.value && changeEvent.value.organisationNumber) {
            fileObj.invoice.invoice.recipientOrganizationNumber = {
                ...fileObj.invoice.invoice.recipientOrganizationNumber,
                rawValue: changeEvent.value.organisationNumber,
                formattedValue: changeEvent.value.organisationNumber,
            };
        } else {
            fileObj.invoice.invoice.recipientOrganizationNumber = {
                ...fileObj.invoice.invoice.recipientOrganizationNumber,
                rawValue: null,
                formattedValue: null,
            };
        }
        this.props.uploadValidateInvoice(fileObj.invoice, fileObj.interpretationId);
    }
    tryAgain(fileObj, event) {
        event.preventDefault();
        let id = fileObj.interpretationId || fileObj.invoice.id;
        this.props.uploadUploadSuccess({
            ...fileObj,
            isLoading: true,
            loadingText: 'Försöker hämta information igen',
            error: undefined,
            errorText: undefined,
            uploadDone: false,
            success: true,
        });
        this.props.uploadGetResult(id);
    }
    findDateString(date) {
        let isToday = moment(date).isSame(moment(), 'day');
        if (isToday) {
            return moment(date).fromNow();
        }
        return moment(date).format('LLL');
    }
    handleChange(name, event) {
        let propName = name;
        let input = event.target;
        this.setState(
            (state) => {
                state[propName] = input.value;
                return state;
            },
            () => {
                if (propName === 'comment') {
                    this.props.uploadFileAddComment(this.state.comment, this.props.index);
                }
            }
        );
    }
    validateInput(event) {
        let inputValue = event.target.value;
        switch (event.target.id) {
            case 'email':
                let regex = Constants.emailRegex();
                this.setState(
                    (state) => {
                        state.emailIsValid = !inputValue || inputValue === '' || (inputValue && regex.test(inputValue));
                        return state;
                    },
                    () => {
                        this.props.onValidationChanged(this.state.emailIsValid);
                        if (this.state.emailIsValid) {
                            this.props.uploadFileUpdateEmail(this.state.email, this.props.index);
                        }
                    }
                );
                break;
            default:
                break;
        }
    }
    fileListUpdate(files) {
        let index = this.props.index;
        let fileObj = this.props.upload.files[index];
        Array.from(files).forEach((file) => {
            if (this.attachmentAlreadyExists(file)) {
                return;
            }

            this.props.uploadUploadAttachment({ file: file, id: fileObj.interpretationId, index: index });
            this.setState((state) => {
                state.fileUploaderExpanded = false;
                return state;
            });
        });
    }
    attachmentAlreadyExists(file) {
        let fileExist = false;
        const fileObj = this.props.upload.files[this.props.index];
        if (fileObj.attachments && fileObj.attachments.includes(file)) {
            fileExist = true;
        }
        return fileExist;
    }
    render() {
        const fileObj = this.props.upload.files[this.props.index];

        return (
            <div className="files__content-wrapper">
                {!fileObj.success && fileObj.error ? (
                    <React.Fragment>
                        <div className="files__item-content">
                            <div className="files__panel files__panel--full">
                                <h3 className="title title--h3">Något gick fel!</h3>
                                <p className="paragraph">
                                    {fileObj.errorText
                                        ? fileObj.errorText
                                        : 'Något gick fel när filen skulle skickas. Det kan bero på att anslutningen till internet plötsligt gick ner, eller att filen i sig är på något sätt felaktig och inte kan mottas av servern. Du kan försöka igen genom att ta bort filen från listan och ladda upp samma fil igen.'}
                                </p>
                                <div className="files__panel files__panel--full">
                                    <button className="button button--chip button--function" onClick={this.tryAgain.bind(this, fileObj)}>
                                        Försök igen
                                    </button>
                                    {this.state.confirmDelete ? (
                                        <div className="confirm-action">
                                            <p className="paragraph">Är du säker på att du vill ta bort fakturan?</p>
                                            <button className="close close--alt" onClick={this.confirmDelete.bind(this)}>
                                                <span className="close__icon"></span>
                                            </button>
                                            <button className="button button--warning button--chip" onClick={this.removeItem.bind(this)}>
                                                Ta bort
                                            </button>
                                        </div>
                                    ) : null}
                                    <button className="button attachment-button button--chip button--warning" onClick={this.confirmDelete.bind(this)}>
                                        Ta bort fil
                                    </button>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="files__item-content">
                            {!fileObj.uploadDone ? (
                                <div className="files__panel files__panel--full">
                                    <h3 className="title title--h3">Fakturan laddas upp</h3>
                                </div>
                            ) : null}
                            <div className="files__panel">
                                <div className="paragraph">
                                    {fileObj.cacheKeys && this.state.imageLoading ? (
                                        <React.Fragment>
                                            <div className="invoice-image">
                                                <div className="invoice-image__invoice">
                                                    {fileObj.cacheKeys.map((key, i) => (
                                                        <div className="invoice-image__item invoice-image__item--loading" key={i}>
                                                            <Spinner />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <ImageSlider photos={this.state.imgUrls} />
                                    )}
                                </div>
                                <div className="paragraph">
                                    <div className="caption">
                                        Laddades upp av <strong>{fileObj.createdBy || 'okänd'}</strong> {this.findDateString(fileObj.timestamp)}
                                    </div>
                                </div>
                                {this.state.confirmDelete ? (
                                    <div className="confirm-action">
                                        <p className="paragraph">Är du säker på att du vill ta bort fakturan?</p>
                                        <button className="close close--alt" onClick={this.confirmDelete.bind(this)}>
                                            <span className="close__icon"></span>
                                        </button>
                                        <button className="button button--warning button--chip button--right" onClick={this.removeItem.bind(this)}>
                                            Ta bort
                                        </button>
                                    </div>
                                ) : null}
                                <div className="paragraph">
                                    <AttachmentUploader index={this.props.index} fileListUpdate={this.fileListUpdate.bind(this)}></AttachmentUploader>
                                </div>
                                <div className="paragraph">
                                    <button className="button button--chip button--warning" onClick={this.confirmDelete.bind(this)}>
                                        Ta bort fil
                                    </button>
                                </div>
                            </div>
                        </div>
                        {!fileObj.uploadDone ? null : <div className="files__panel--divider" />}
                        <div className="files__item-content">
                            {!fileObj.uploadDone ? null : (
                                <React.Fragment>
                                    <div>
                                        <div className="files__item-content">
                                            <div className="files__panel">
                                                <div className="paragraph">
                                                    <div className="paragraph">Vill du istället skicka denna faktura som e-post? Ange mailadressen nedan:</div>
                                                    <div className="paragraph">
                                                        <input
                                                            value={this.state.email}
                                                            onChange={this.handleChange.bind(this, 'email')}
                                                            onBlur={this.validateInput.bind(this)}
                                                            id="email"
                                                            type="email"
                                                            className="input__field"
                                                            placeholder="mail@exempel.se"
                                                        />
                                                        {typeof this.state.emailIsValid !== 'undefined' && !this.state.emailIsValid ? (
                                                            <div className="input__required-information input__required-information--narrow">
                                                                Ange en giltig email-adress
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="files__panel--divider-horizontal" />
                                        <div className="files__item-content">
                                            <div className="files__panel">
                                                <div className="paragraph">
                                                    Vill du lämna ett meddelande till Factoringgruppen som tillhör denna faktura kan du göra detta nedan:
                                                </div>
                                                <div className="paragraph">
                                                    <textarea
                                                        value={this.state.comment}
                                                        onChange={this.handleChange.bind(this, 'comment')}
                                                        autoComplete="off"
                                                        className="input__text-area"
                                                        name="freeText"
                                                        placeholder="Fritext"
                                                        max="400"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        upload: state.upload,
        oidc: state.oidc,
        organisation: state.organisation,
    };
};

FileItemDetails = connect(mapStateToProps, {
    uploadRemoveFromList,
    modalTrigger,
    uploadValidateInvoice,
    uploadGetResult,
    uploadUploadSuccess,
    uploadFileUpdateEmail,
    uploadFileAddComment,
    uploadUploadAttachment,
})(FileItemDetails);

export default FileItemDetails;
