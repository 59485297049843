// Function for pairing Invoice recipients with known recipients
export const InvoiceRecipientPairing = (store) => {
    if (store.value.upload.files.length > 0 && store.value.recipients.recipients.length > 0) {
        // Go through all existing files
        store.value.upload.files.map((fileObj) => {
            // If all information has been loaded
            if (fileObj.invoice && fileObj.invoice.invoice && fileObj.invoice.invoice.recipientOrganizationNumber) {
                // Go through all recipients
                store.value.recipients.recipients.forEach((recipient) => {
                    // Check if system found any orgnumber
                    if (recipient.organisationNumber === fileObj.invoice.invoice.recipientOrganizationNumber.formattedValue) {
                        // Don't do anything, orgNo was found
                        return fileObj;
                    }
                    // If the name exactly matches any of the existing known recipients
                    if (recipient.name === fileObj.invoice.invoice.recipientName.formattedValue) {
                        // We can safely assume that the orgNo can be used
                        fileObj.invoice.invoice.recipientName = {
                            ...fileObj.invoice.invoice.recipientOrganizationNumber,
                            rawValue: recipient.organisationNumber,
                            formattedValue: recipient.organisationNumber,
                        };
                    }
                });
            }

            return fileObj;
        });
    }
    return store;
};
