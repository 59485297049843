import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Icon from '../../global/Icon';
import Spinner from '../../global/Spinner';
import State from '../../global/State';

import { maturityStructureFetch } from '../../actions';

class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.props.maturityStructureFetch();
    }

    render() {
        // A mapper for classes and strings
        const findClassAndGroupName = (group) => {
            switch (group) {
                case 'expired_46+':
                    return {
                        class: 'bar--error',
                        groupName: '46+ dagar',
                        groupNameLong: '46+ dagar efter förfallodatum',
                    };
                case 'expired_31-46':
                    return {
                        class: 'bar--danger',
                        groupName: '31-46 dagar',
                        groupNameLong: '31-46 dagar efter förfallodatum',
                    };
                case 'expired_16-31':
                    return {
                        class: 'bar--warning',
                        groupName: '16-30 dagar',
                        groupNameLong: '16-30 dagar efter förfallodatum',
                    };
                case 'expired_1-15':
                    return {
                        class: 'bar--concern',
                        groupName: '1-15 dagar',
                        groupNameLong: '1-15 dagar efter förfallodatum',
                    };
                case 'inkasso':
                    return {
                        class: 'bar--error',
                        groupName: 'Inkassoärenden',
                        groupNameLong: 'Inkassoärenden',
                    };
                case 'notExpired':
                    return {
                        class: 'bar--normal',
                        groupName: 'Ej förfallet',
                        groupNameLong: 'Ej förfallet',
                    };
                default:
                    return {
                        class: 'bar--error',
                        groupName: '',
                    };
            }
        };

        return (
            <div className="content__body dashboard">
                <div className="content__main content__main--flex">
                    <div className="content__flyer">
                        <div className="filter filter--full-height">
                            <div className="filter__content">
                                <div className="filter__greeting">
                                    <h3 className="title title--h3">
                                        Förfallostruktur
                                        {this.props.maturityStructure.loading ? <Spinner /> : null}
                                    </h3>
                                </div>
                                <div className="filter__section">
                                    <div className="data-list">
                                        {this.props.maturityStructure.groups.map((groupObj, index) => {
                                            if (groupObj.group === 'inkasso' || groupObj.group === 'notExpired') {
                                                return null;
                                            }
                                            let classAndGroup = findClassAndGroupName(groupObj.group);
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="data-list__item">
                                                        <div className="data-list__label">{classAndGroup.groupName}</div>
                                                        <div className="data-list__data">
                                                            {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                                parseFloat(groupObj.amount)
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="data-list__item">
                                                        <div className={`bar bar--has-info ${classAndGroup.class}`}>
                                                            <div className="bar__info">{groupObj.count} st</div>
                                                            <div
                                                                className="bar__progress"
                                                                style={{
                                                                    width: ((groupObj.count / this.props.maturityStructure.numberOfInvoices) * 100 || 0) + '%',
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="filter__footer">
                                {this.props.maturityStructure.groups.length > 0 ? (
                                    <div className="data-list data-list--small">
                                        {this.props.maturityStructure.groups.map((groupObj, index) => {
                                            if (groupObj.group === 'inkasso' || groupObj.group === 'notExpired') {
                                                let classAndGroup = findClassAndGroupName(groupObj.group);
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="data-list__item">
                                                            <div className="data-list__label">{classAndGroup.groupName}</div>
                                                            <div className="data-list__data">
                                                                {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                                    parseFloat(groupObj.amount)
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="data-list__item">
                                                            <div className={`bar bar--has-info ${classAndGroup.class}`}>
                                                                <div className="bar__info">{groupObj.count} st</div>
                                                                <div
                                                                    className="bar__progress"
                                                                    style={{
                                                                        width:
                                                                            ((groupObj.count / this.props.maturityStructure.numberOfInvoices) * 100 || 0) + '%',
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                ) : null}
                                <div className="filter__footer-summary">
                                    <div className="summary-box">
                                        <div className="summary-box__content">
                                            <div className="data-list">
                                                <div className="data-list__item">
                                                    <div className="data-list__label">Summa</div>
                                                    <div className="data-list__data">
                                                        {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                            parseFloat(this.props.maturityStructure.totalInvoiceCost)
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            this.props.maturityStructure.loading
                                ? 'content__wrapper content__wrapper--loading content__wrapper--stuffing'
                                : 'content__wrapper content__wrapper--stuffing'
                        }
                    >
                        <h1 className="title title--h1">Öppna fakturor</h1>
                        {this.props.maturityStructure.groups.map((groupObj, index) => {
                            if (groupObj.invoiceList.length === 0) {
                                return null;
                            }
                            return (
                                <React.Fragment key={index}>
                                    <h2 className="title title--h3">{findClassAndGroupName(groupObj.group).groupNameLong}</h2>
                                    <div className="invoice-list">
                                        <ul className="invoice-list__list">
                                            {groupObj.invoiceList.map((invoice, invoiceIndex) => {
                                                return (
                                                    <li className="invoice-list__item" key={invoiceIndex}>
                                                        <NavLink to={'/historik/fakturor/' + invoice.id} className="invoice-list__link">
                                                            <div className="invoice-list__item-content">
                                                                <div className="invoice-list__panel">
                                                                    <React.Fragment>
                                                                        <p className="paragraph">
                                                                            <strong
                                                                                dangerouslySetInnerHTML={(() => {
                                                                                    let dangerousHtml = {
                                                                                        __html: invoice.recipient,
                                                                                    };
                                                                                    return dangerousHtml;
                                                                                })()}
                                                                            ></strong>
                                                                        </p>
                                                                        <p className="caption">
                                                                            <small>{Intl.DateTimeFormat('se-sv').format(new Date(invoice.created))}</small>
                                                                        </p>
                                                                    </React.Fragment>
                                                                </div>
                                                                <div className="invoice-list__panel invoice-list__panel--state">
                                                                    <State state={invoice.latestState} />
                                                                </div>
                                                                <div className="invoice-list__panel">
                                                                    <p className="paragraph">
                                                                        <small>Fakturabelopp</small>
                                                                    </p>
                                                                    <p className="paragraph">
                                                                        <strong>
                                                                            {Intl.NumberFormat('sv-se', { style: 'currency', currency: 'SEK' }).format(
                                                                                invoice.invoiceAmount
                                                                            )}
                                                                        </strong>
                                                                    </p>
                                                                </div>
                                                                <div className="invoice-list__panel">
                                                                    <p className="paragraph">
                                                                        <small>Återstående fakturabelopp</small>
                                                                    </p>
                                                                    <p className="paragraph">
                                                                        <strong>
                                                                            {Intl.NumberFormat('sv-se', { style: 'currency', currency: 'SEK' }).format(
                                                                                invoice.remainingAmount
                                                                            )}
                                                                        </strong>
                                                                    </p>
                                                                </div>
                                                                <div className="invoice-list__panel">
                                                                    <p className="paragraph">
                                                                        <small>Fakturanummer</small>
                                                                    </p>
                                                                    <p className="paragraph">
                                                                        <strong>{invoice.invoiceNumber}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="invoice-list__panel invoice-list__panel--icon">
                                                                <Icon name="right" />
                                                            </div>
                                                        </NavLink>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </React.Fragment>
                            );
                        })}

                        {!this.props.maturityStructure.loading && this.props.maturityStructure.numberOfInvoices === 0 ? (
                            <ul className="invoice-list__list">
                                <li className="invoice-list__item invoice-list__item--loader">
                                    <p className="caption">Inga öppna fakturor hittades</p>
                                </li>
                            </ul>
                        ) : null}
                        {this.props.maturityStructure.loading ? <Spinner /> : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        maturityStructure: state.maturityStructure,
    };
};

DashboardPage = connect(mapStateToProps, {
    maturityStructureFetch,
})(DashboardPage);

export default DashboardPage;
