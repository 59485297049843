import { ORGANISATION_UPDATE, ORGANISATION_GET_COMMITMENTS } from '../actions/index';
import { USER_EXPIRED, SILENT_RENEW_ERROR } from 'redux-oidc';
const initialState = {
    name: '',
    organisationNumber: '',
    list: [],
    settings: {},
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case ORGANISATION_UPDATE:
        case ORGANISATION_GET_COMMITMENTS:
            return { ...state, ...action.payload };
        case USER_EXPIRED:
        case SILENT_RENEW_ERROR:
            return {
                ...initialState,
            };
        default:
            return { ...state };
    }
}
