/*
 * This is a service to help out with getting correct classes and icons based on decision from backend
 * This was mainly used for when selling and invoice, but can still be used to display state of and invoice as well.
 * The currently available states are as follows:
 * NoDecision = 0,
 * Buy = 100,
 * BuyWithDeposition = 101,
 * BuyWithRepurchaseLimit = 102,
 * BuyWithDepositionAndRepurchaseLimit = 103,
 * BuyWithRecipientApproval = 150,
 * BuyWithDepositionAndRecipientApproval = 151,
 * BuyWithRepurchaseLimitAndRecipientApproval = 152,
 * BuyWithDepositionAndRepurchaseLimitAndRecipientApproval = 153,
 * Pause = 200,
 * Retry = 201,
 * Reject = 300,
 */

export const DecisionService = {
    getDataClassByDecision: (decision) => {
        switch (decision) {
            case 'Buy':
            case 100:
            case 'BuyWithDeposition':
            case 101:
            case 'BuyWithRepurchaseLimit':
            case 102:
            case 'BuyWithDepositionAndRepurchaseLimit':
            case 103:
                return 'data data--sell';
            case 'NoDecision':
            case 0:
            case 'BuyWithRecipientApproval':
            case 150:
            case 'BuyWithDepositionAndRecipientApproval':
            case 151:
            case 'BuyWithRepurchaseLimitAndRecipientApproval':
            case 152:
            case 'BuyWithDepositionAndRepurchaseLimitAndRecipientApproval':
            case 'Pause':
            case 200:
            case 153:
            case 'Retry':
            case 201:
                return 'data data--wait';
            case 'Reject':
            case 300:
                return 'data data--warning';
            default:
                return 'data';
        }
    },
    getDataClassBySellState: (decision) => {
        switch (decision) {
            case 'NotFound':
            case 'AlreadyExists':
                return 'data data--warning';
            case 'Unknown':
            case 'RequiresRecipientApproval':
            case 'NeedsRetry':
            case 'Busy':
                return 'data data--wait';
            case 'Sent':
                return 'data data--sell';
            default:
                return 'data';
        }
    },
    getIconClassBySellState: (decision) => {
        switch (decision) {
            case 'NotFound':
            case 'AlreadyExists':
                return 'speech-2-error';
            case 'Unknown':
            case 'RequiresRecipientApproval':
            case 'NeedsRetry':
            case 'Busy':
                return 'speech-2';
            case 'Sent':
            default:
                return 'check-small';
        }
    },
    getIconClassByDecision: (decision) => {
        switch (decision) {
            case 'Buy':
            case 100:
            case 'BuyWithDeposition':
            case 101:
            case 'BuyWithRepurchaseLimit':
            case 102:
            case 'BuyWithDepositionAndRepurchaseLimit':
            case 103:
                return 'invoice-small';
            case 'BuyWithRecipientApproval':
            case 150:
            case 'BuyWithDepositionAndRecipientApproval':
            case 151:
            case 'BuyWithRepurchaseLimitAndRecipientApproval':
            case 152:
            case 'BuyWithDepositionAndRepurchaseLimitAndRecipientApproval':
            case 153:
            case 'Pause':
            case 200:
                return 'wait';
            case 'NoDecision':
            case 0:
            case 'Retry':
            case 201:
                return 'speech-2';
            case 'Reject':
            case 300:
                return 'speech-2-error';
            default:
                return 'invoice-small';
        }
    },
};
