import { of, empty, concat } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { USER_EXPIRED, SILENT_RENEW_ERROR, USER_FOUND } from 'redux-oidc';
import { modalTrigger, socketClose } from '../actions';

import { CookieService } from '../global/CookieService';

export const oidcTimeOutEpic = (action$, store) =>
    action$.ofType(USER_EXPIRED, SILENT_RENEW_ERROR).pipe(
        switchMap((action) => {
            // User has been logged out, cleanup cookies and close sockets.
            sessionStorage.clear();
            localStorage.removeItem('commitment');
            CookieService.eraseCookie('Authorization');
            CookieService.eraseCookie('organisation-number');
            CookieService.eraseCookie('demo-user');
            return concat(of(modalTrigger(false)), of(socketClose()));
        })
    );

export const oidcUserFoundEpic = (action$, store) =>
    action$.ofType(USER_FOUND).pipe(
        switchMap((action) => {
            // User logged in, create cookie to that socket can be opened
            CookieService.setCookie('Authorization', action.payload.access_token);
            if (store.value && store.value.oidc && store.value.oidc.user && store.value.oidc.user.profile && store.value.oidc.user.profile.sub === 'demo') {
                CookieService.setCookie('demo-user', 'always');
            }

            console.dir(store);
            return empty();
        })
    );
