import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Motion, spring } from 'react-motion';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import Spinner from '../../global/Spinner';

import { recipientsInit, recipientsFilter, recipientsPaginate, recipientsUpdate, recipientsClear } from '../../actions';

class RecipientsPage extends Component {
    constructor(props) {
        super(props);
        this.searchInput = React.createRef();

        if (props.recipients.recipients.length === 0 || typeof props.recipients.scrollPosition !== 'number') {
            props.recipientsInit();
        }

        // Create observers for scroll and resize events
        const scrollObs = merge(fromEvent(window, 'wheel', { passive: true }), fromEvent(window, 'scrollEnd', { passive: true }));
        const resizeObs = fromEvent(window, 'resize', { passive: true });
        const scroll = fromEvent(window, 'touchmove');
        const mergedObs = merge(resizeObs, scrollObs, scroll).pipe(
            debounceTime(25),
            map((d) => {
                return document.body.scrollTop;
            }),
            distinctUntilChanged((prev, curr) => {
                return prev === curr;
            })
        );
        // Triggered when scroll or window size change
        this.scrollSub = mergedObs.subscribe((event) => {
            // If we reach correct scroll distance (400 pixels from bottom) then trigger pagination automatically
            const startFetchOffset = 400;
            const reachedTriggerHeight = document.body.scrollTop + document.body.clientHeight + startFetchOffset >= document.body.scrollHeight;
            const allIsLoaded = this.props.recipients.recipients.length === this.props.recipients.totalHits;
            if (!allIsLoaded && !this.props.recipients.loading && reachedTriggerHeight) {
                this.props.recipientsPaginate();
            }
        });
    }

    componentDidMount() {
        // If there is a search query, update input field to show it
        if (this.props.recipients.query) {
            this.searchInput.current.value = this.props.recipients.query;
        }
        // Setup listeners for when user searches with query
        this.searchTermSub = fromEvent(this.searchInput.current, 'input')
            .pipe(debounceTime(150))
            .subscribe((event) => {
                const query = this.searchInput.current.value;
                if (this.props.recipients.query !== query) {
                    this.triggerFilter();
                }
            });
        // If an old scroll position has been saved set scroll position to that.
        if (this.props.recipients.scrollPosition > 0) {
            document.body.scrollTop = this.props.recipients.scrollPosition;
        }
    }

    componentWillUnmount() {
        if (this.scrollSub) {
            this.scrollSub.unsubscribe();
        }
        const nextRoutePath = decodeURI(window.location.pathname);
        const currentRoutePathname = this.props.location.pathname;
        // If user has navigated somewhere that isn't this route or a child route
        if (nextRoutePath.indexOf(currentRoutePathname) === -1) {
            document.body.scrollTop = 0; // reset scroll to avoid animation glitches
            this.props.recipientsClear();
        } else {
            this.props.recipientsUpdate({ scrollPosition: document.body.scrollTop });
        }
    }
    triggerFilter() {
        const query = this.searchInput.current.value;
        this.props.recipientsFilter(query);
    }

    render() {
        const { recipients } = this.props;
        return (
            <div className="content__body recipient">
                <div className="content__main content__main--stuffing">
                    <div className="content__wrapper">
                        <h1 className="title title--h1">Mottagare</h1>
                        <div className="recipient__search">
                            <div className="input">
                                <input
                                    aria-label="Sökterm"
                                    name="search"
                                    ref={this.searchInput}
                                    type="search"
                                    placeholder="Sök efter namn eller organsiationsnummer/personnummer"
                                    className="input__field"
                                />
                            </div>
                            <button className="button button--function">Sök</button>
                        </div>
                        <h3 className="title title--h3">Alla mottagare</h3>
                        <Motion
                            defaultStyle={{ opacity: 0, translateY: 25 }}
                            style={{ opacity: spring(1), translateY: spring(0, { stiffness: 250, damping: 20 }) }}
                        >
                            {(interpolatingStyle) => (
                                <div className="recipient">
                                    <ul className="recipient__list">
                                        {recipients.recipients.map((recipient, index) => (
                                            <li className="recipient__item" key={index}>
                                                <div className="recipient__link">
                                                    {/*<NavLink to={"/mottagare/detalj/" + recipient.keerosId} className="recipient__link">*/}
                                                    <div className="recipient__item-content">
                                                        <div className="recipient__panel">
                                                            <React.Fragment>
                                                                <p className="paragraph">
                                                                    <strong
                                                                        dangerouslySetInnerHTML={(() => {
                                                                            let dangerousHtml = {
                                                                                __html: recipient.name,
                                                                            };
                                                                            return dangerousHtml;
                                                                        })()}
                                                                    ></strong>
                                                                </p>
                                                                <p className="caption">
                                                                    <small>{recipient.externalCustomerNumber}</small>
                                                                </p>
                                                            </React.Fragment>
                                                        </div>
                                                        <div className="recipient__panel">
                                                            <p className="paragraph">
                                                                <small>Senast fakturerad</small>
                                                            </p>
                                                            <p className="paragraph">
                                                                <strong>
                                                                    {recipient.lastInvoiceDate
                                                                        ? Intl.DateTimeFormat('se-sv').format(new Date(recipient.lastInvoiceDate))
                                                                        : null}
                                                                </strong>
                                                            </p>
                                                        </div>
                                                        <div className="recipient__panel">
                                                            <p className="paragraph">
                                                                <small>Antal fakturor</small>
                                                            </p>
                                                            <p className="paragraph">
                                                                <strong>{Intl.NumberFormat('sv-se').format(recipient.numberOfInvoices)}</strong>
                                                            </p>
                                                        </div>
                                                        <div className="recipient__panel">
                                                            <p className="paragraph">
                                                                <small>Total försäljning</small>
                                                            </p>
                                                            <p className="paragraph">
                                                                <strong>
                                                                    {Intl.NumberFormat('sv-se', { style: 'currency', currency: 'SEK' }).format(
                                                                        recipient.totalValueOfInvoices
                                                                    )}
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/*<div className="recipient__panel recipient__panel--icon">
                                                        <Icon name="right" />
                                                    </div>*/}
                                                </div>
                                                {/*</NavLink>*/}
                                            </li>
                                        ))}
                                        {recipients.loading || recipients.recipients.length !== recipients.totalHits ? (
                                            <li className="recipient__item recipient__item--loader">
                                                <Spinner />
                                            </li>
                                        ) : null}
                                        {!recipients.loading && recipients.recipients.length === 0 ? (
                                            <li className="recipient__item recipient__item--loader">
                                                <p className="caption">Hittade inga mottagare</p>
                                            </li>
                                        ) : null}
                                    </ul>
                                    {recipients && recipients.recipients.length > 0 ? (
                                        <div className="recipient__total-count">
                                            Visar {recipients.recipients.length} av {recipients.totalHits}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </Motion>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        recipients: state.recipients,
        organisation: state.organisation,
    };
};

RecipientsPage = connect(mapStateToProps, {
    recipientsInit,
    recipientsFilter,
    recipientsPaginate,
    recipientsUpdate,
    recipientsClear,
})(RecipientsPage);

export default RecipientsPage;
