import { concat, of } from 'rxjs';
import { map, switchMap, catchError, debounceTime } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import { RECIPIENTS_INIT, RECIPIENTS_FILTER, RECIPIENTS_PAGINATE, recipientsUpdate } from '../actions';

let endpoint = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    endpoint = process.env.REACT_APP_COMPANY_API_URL;
}

// This is something that used to exist but doesn't anymore. This is a list of a clients' known recipients.

export const recipientsInitEpic = (action$, store) =>
    action$.ofType(RECIPIENTS_INIT).pipe(
        switchMap((action) => {
            return concat(
                of(recipientsUpdate({ loading: true })),
                ajax
                    .post(
                        `${endpoint}api/company-service/getrecipients`,
                        {
                            OrganizationNumber: store.value.organisation.organisationNumber,
                        },
                        {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        }
                    )
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        map((data) => {
                            return recipientsUpdate({
                                recipients: [...data.hits],
                                totalHits: data.totalHits,
                                size: data.size,
                                page: data.page,
                                loading: false,
                            });
                        }),
                        catchError((error) => {
                            console.log("Couldn't fetch recipients", error);
                            return of(recipientsUpdate({ error: true, loading: false }));
                        })
                    )
            );
        })
    );

export const recipientsFilterEpic = (action$, store) =>
    action$.ofType(RECIPIENTS_FILTER).pipe(
        switchMap((action) => {
            let filter = {
                organizationNumber: store.value.organisation.organisationNumber,
                query: action.payload.query,
                size: store.value.recipients.size,
                page: 1,
            };
            return concat(
                of(recipientsUpdate({ loading: true })),
                ajax
                    .post(`${endpoint}api/company-service/getrecipients`, filter, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        map((data) => {
                            return recipientsUpdate({ recipients: [...data.hits], totalHits: data.totalHits, ...filter, loading: false });
                        }),
                        catchError((error) => {
                            console.log("Couldn't fetch recipients", error);
                            return of(recipientsUpdate({ error: true, loading: false }));
                        })
                    )
            );
        })
    );

export const recipientsPaginateEpic = (action$, store) =>
    action$.ofType(RECIPIENTS_PAGINATE).pipe(
        debounceTime(10),
        switchMap((action) => {
            // Create filter object based on store filter data, and increase the page size by 1
            let filter = {
                organizationNumber: store.value.organisation.organisationNumber,
                size: store.value.recipients.size,
                page: store.value.recipients.page + 1,
                query: store.value.recipients.query,
            };

            // Fetch new content using the filter.
            return concat(
                of(recipientsUpdate({ loading: true })),
                ajax
                    .post(`${endpoint}api/company-service/getrecipients`, filter, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        map((data) => {
                            let recipients = [...store.value.recipients.recipients, ...data.hits];
                            return recipientsUpdate({ recipients: recipients, totalHits: data.totalHits, ...filter, loading: false });
                        }),
                        catchError((error) => {
                            // HTTP error happened
                            console.log("Couldn't fetch recipients");
                            return of(recipientsUpdate({ error: true, loading: false }));
                        })
                    )
            );
        })
    );
