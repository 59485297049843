import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StaggeredMotion, spring } from 'react-motion';
import { ajax } from 'rxjs/ajax';
import { empty } from 'rxjs';
import { catchError } from 'rxjs/operators';

import Icon from '../../global/Icon';
import Spinner from '../../global/Spinner';
import State from '../../global/State';
import Messages from '../../global/Messages';
import { DecisionService } from '../../global/DecisionService';
import { downloadPdfForApp } from '../../global/Helpers';
import { historyGetDetails, historyUpdate } from '../../actions';

class InvoiceSpecificationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingIndexes: [],
            errorIndexes: [],
        };
        this.props.historyGetDetails(props.match.params.id);
    }
    componentWillUnmount() {
        this.props.historyUpdate({ details: null });
    }
    goBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }
    downloadResponse(url, fileName, index, event) {
        event.preventDefault();
        if (downloadPdfForApp(url, fileName, this.props.oidc.user.access_token, this.props.organisation.organisationNumber)) {
            return;
        }
        this.setState((state) => {
            state.loadingIndexes = [...state.loadingIndexes, index];
            return state;
        });

        // Create request for downloading file
        const getFileRequest = ajax({
            url: url,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.props.oidc.user.access_token}`,
                'organisation-number': this.props.organisation.organisationNumber,
            },
            responseType: 'arraybuffer',
        }).pipe(
            catchError((error) => {
                this.setState((state) => {
                    state.errorIndexes = [...state.errorIndexes, index];
                    state.loadingIndexes.splice(
                        state.loadingIndexes.findIndex((num) => num === index),
                        1
                    );

                    return state;
                });

                return empty();
            })
        );
        // Trigger request and listen for completion
        let subscriber = getFileRequest.subscribe((response) => {
            const buffer = response.response;
            const type = response.xhr.getResponseHeader('content-type');
            if (!buffer) {
                return;
            }
            // create file and means to reference it
            const blob = new Blob([buffer], { type: type });
            const url = window.URL.createObjectURL(blob);
            // create link to trigger save of file
            var linkElement = document.createElement('a');
            linkElement.style = 'display: none';
            linkElement.href = url;
            linkElement.download = fileName;
            // trigger save and cleanup
            document.body.appendChild(linkElement);
            linkElement.click();
            window.URL.revokeObjectURL(url);
            subscriber.unsubscribe();

            this.setState((state) => {
                state.loadingIndexes.splice(
                    state.loadingIndexes.findIndex((num) => num === index),
                    1
                );
                state.errorIndexes.splice(
                    state.errorIndexes.findIndex((num) => num === index),
                    1
                );
                return state;
            });
        });
    }
    isFileLoading(index) {
        return this.state.loadingIndexes.length > 0 && this.state.loadingIndexes.findIndex((num) => num === index) > -1;
    }
    fileHasError(index) {
        return this.state.errorIndexes.length > 0 && this.state.errorIndexes.findIndex((num) => num === index) > -1;
    }
    render() {
        let { invoiceHistory } = this.props;
        let { details } = this.props.invoiceHistory;
        let endpoint = `${window.location.origin}/`;
        if (process.env.NODE_ENV === 'development') {
            endpoint = process.env.REACT_APP_API_URL;
        }

        return (
            <div className="content__body invoice-specification">
                <div className="content__main">
                    <div className="invoice-specification__content">
                        <div className="data-list">
                            <div className="data-list__item data-list__item--full">
                                <button onClick={this.goBack.bind(this)} className="button button--function button--small invoice-specification__back-button">
                                    <Icon name="left" /> Tillbaka
                                </button>
                                {details && details.states ? (
                                    <React.Fragment>
                                        {details.states.length > 0 ? (
                                            <State state={details.states[details.states.length - 1].state} />
                                        ) : (
                                            <State state={'NotSet'} />
                                        )}
                                    </React.Fragment>
                                ) : null}
                                <h1 className="title title--h1">Faktura&shy;information</h1>
                            </div>
                            {invoiceHistory.loading || !details ? (
                                <React.Fragment>
                                    {invoiceHistory.loading ? (
                                        <div className="data-list__loader">
                                            <Spinner />
                                        </div>
                                    ) : null}
                                    {!invoiceHistory.loading && !details ? (
                                        <div className="data-list__item data-list__item--error">
                                            <p className="caption">Kunde inte hämta information</p>
                                        </div>
                                    ) : null}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="data-list__item">
                                        <div className="data-list__label">Mottagare</div>
                                        <div className="data-list__data">
                                            <div>{details.recipient.name}</div>
                                            <div>{details.recipient.address}</div>
                                            <div>{details.recipient.address2}</div>
                                            <div>
                                                {details.recipient.zipcode} {details.recipient.city + ', '} {details.recipient.country}
                                            </div>
                                        </div>
                                    </div>
                                    {new Date(details.invoiceDate).getFullYear() > 2010 ? (
                                        <div className="data-list__item">
                                            <div className="data-list__label">Fakturadatum</div>
                                            <div className="data-list__data">{new Intl.DateTimeFormat('sv').format(new Date(details.invoiceDate))}</div>
                                        </div>
                                    ) : null}
                                    {new Date(details.expirationDate).getFullYear() > 2010 ? (
                                        <div className="data-list__item">
                                            <div className="data-list__label">Förfallodag</div>
                                            <div className="data-list__data">{new Intl.DateTimeFormat('sv').format(new Date(details.expirationDate))}</div>
                                        </div>
                                    ) : null}
                                    <div className="data-list__item">
                                        <div className="data-list__label">Fakturanummer</div>
                                        <div className="data-list__data">
                                            <span>{details.invoiceNumber}</span>
                                        </div>
                                    </div>
                                    <div className="data-list__item">
                                        <div className="data-list__label">Belopp</div>
                                        <div className="data-list__data">
                                            <span>{Intl.NumberFormat('sv-se', { style: 'currency', currency: 'SEK' }).format(details.invoiceAmount)}</span>
                                        </div>
                                    </div>
                                    <div className="data-list__item">
                                        <div className="data-list__label">Återstående fakturabelopp</div>
                                        <div className="data-list__data">
                                            <span>{Intl.NumberFormat('sv-se', { style: 'currency', currency: 'SEK' }).format(details.remainingCapital)}</span>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                            {details && details.debitInformation ? (
                                <React.Fragment>
                                    <div className="data-list__divider"></div>
                                    {parseInt(details.debitInformation.commissionFee) > 0 ? (
                                        <div className="data-list__item">
                                            <div className="data-list__label">Kostnader inkl. moms</div>
                                            <div className="data-list__data">
                                                <span className="data">
                                                    {new Intl.NumberFormat('sv-SE', {
                                                        style: 'currency',
                                                        currency: 'SEK',
                                                    }).format(parseFloat(details.debitInformation.commissionFee))}
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}
                                    {parseInt(details.debitInformation.administrativeFees) > 0 ? (
                                        <div className="data-list__item">
                                            <div className="data-list__label">Avgifter</div>
                                            <div className="data-list__data">
                                                <span className="data">
                                                    {new Intl.NumberFormat('sv-SE', {
                                                        style: 'currency',
                                                        currency: 'SEK',
                                                    }).format(parseFloat(details.debitInformation.administrativeFees))}
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}
                                    {parseInt(details.debitInformation.depositionUsage) > 0 ? (
                                        <div className="data-list__item">
                                            <div className="data-list__label">Deposition</div>
                                            <div className="data-list__data">
                                                <span className="data">
                                                    {new Intl.NumberFormat('sv-SE', {
                                                        style: 'currency',
                                                        currency: 'SEK',
                                                    }).format(parseFloat(details.debitInformation.depositionUsage))}
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}
                                    {details.debitInformation.depositionUsage &&
                                    details.debitInformation.administrativeFees &&
                                    details.debitInformation.commissionFee ? (
                                        <div className="data-list__item">
                                            <div className="data-list__label">Finansierat belopp</div>
                                            <div className="data-list__data">
                                                <span className={DecisionService.getDataClassByDecision(details.decision)}>
                                                    {new Intl.NumberFormat('sv-SE', {
                                                        style: 'currency',
                                                        currency: 'SEK',
                                                    }).format(
                                                        parseFloat(details.invoiceAmount) -
                                                            parseFloat(details.debitInformation.depositionUsage) -
                                                            parseFloat(details.debitInformation.administrativeFees) -
                                                            parseFloat(details.debitInformation.commissionFee)
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}
                                </React.Fragment>
                            ) : null}
                        </div>
                        <h2 className="title title--h2">Bifogade filer</h2>
                        {invoiceHistory.loading ? (
                            <div className="invoice-specification__loader">
                                <Spinner />
                            </div>
                        ) : null}
                        {!invoiceHistory.loading && !details ? <p className="caption">Kunde inte hämta information</p> : null}
                        {details && details.documentLinks ? (
                            <StaggeredMotion
                                defaultStyles={details.documentLinks.map((obj, index) => {
                                    return { opacity: 0, translateY: 10 };
                                })}
                                styles={(prevStyles) => {
                                    return details.documentLinks.map((obj, index) => {
                                        if (index === 0) {
                                            return {
                                                opacity: spring(1, { stiffness: 220, damping: 20 }),
                                                translateY: spring(0, { stiffness: 250, damping: 25 }),
                                            };
                                        }
                                        return {
                                            opacity: spring(prevStyles[index - 1].opacity, { stiffness: 220, damping: 20 }),
                                            translateY: spring(prevStyles[index - 1].translateY, { stiffness: 250, damping: 25 }),
                                        };
                                    });
                                }}
                            >
                                {(styles) => (
                                    <ul className="files">
                                        {details.documentLinks.map((file, index) => {
                                            return (
                                                <li
                                                    style={{ opacity: styles[index].opacity, transform: `translateY(${styles[index].translateY}px)` }}
                                                    className="files__item"
                                                    key={index}
                                                >
                                                    <div className="files__item-row files__item-row--button">
                                                        <div className="files__cloud-img">
                                                            {this.fileHasError(index) ? <Icon name="speech-2-error" /> : <Icon name="attachment" />}
                                                        </div>
                                                        <div className="files__item-name">
                                                            <div className="files__recipient">
                                                                <React.Fragment>
                                                                    <span className="data">{file.fileName}</span>
                                                                </React.Fragment>
                                                            </div>
                                                        </div>
                                                        <div className="files__item-button">
                                                            {this.fileHasError(index) ? (
                                                                <p className="caption">Fil kunde inte laddas ner</p>
                                                            ) : (
                                                                <button
                                                                    disabled={this.isFileLoading(index) || this.fileHasError(index)}
                                                                    onClick={this.downloadResponse.bind(
                                                                        this,
                                                                        `${endpoint}api/document/getdocument?i=${file.key}`,
                                                                        `${file.fileName}-${details.invoiceNumber}.pdf`,
                                                                        index
                                                                    )}
                                                                    className={
                                                                        this.isFileLoading(index)
                                                                            ? 'button button--function button--small button--disabled button--loading'
                                                                            : 'button button--function button--small'
                                                                    }
                                                                >
                                                                    Ladda ner {this.isFileLoading(index) ? <Spinner /> : <Icon name="download" />}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </StaggeredMotion>
                        ) : null}
                    </div>
                    <div className="invoice-specification__info">
                        <Messages />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        messages: state.messages,
        invoiceHistory: state.history,
        router: state.router,
        oidc: state.oidc,
        organisation: state.organisation,
    };
};

InvoiceSpecificationPage = connect(mapStateToProps, {
    historyGetDetails,
    historyUpdate,
})(InvoiceSpecificationPage);

export default InvoiceSpecificationPage;
