import React, { Component } from 'react';

import { default as ExtDayPickerInput } from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import 'moment/locale/sv';

// This is a wrapper of an external DayPickerInput.
// We have some additional classes end some events that we needed to i made a wrapper. Nothing fancy.
class DayPickerInput extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        if (props.defaultDate) {
            this.state.selectedDay = props.defaultDate;
        }
        if (this.props.resetSubject) {
            this.subscriber = this.props.resetSubject.subscribe(() => {
                this.setState((state) => {
                    return {
                        ...state,
                        selectedDay: this.props.defaultDate,
                    };
                });
            });
        }
    }
    componentWillUnmount() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

    handleDayChange(selectedDay, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        this.setState(
            {
                selectedDay,
                isEmpty: !input.value.trim(),
                isValidDay: typeof selectedDay !== 'undefined',
                isDisabled: modifiers.disabled === true,
            },
            () => {
                if (this.props.callBack) {
                    this.props.callBack(this.state);
                }
            }
        );
    }

    render() {
        let { isValid } = this.props;
        let inputClasses = () => {
            let className = 'input input--small input--full';
            // console.log(this.props);
            if (typeof isValid !== 'undefined' && !isValid) {
                className += ' input--error';
            }
            return className;
        };
        return (
            <div className={inputClasses()}>
                {this.props.label && <span className="input__label">{this.props.label}</span>}
                <ExtDayPickerInput
                    formatDate={formatDate}
                    placeholder={`${formatDate(this.state.selectedDay || new Date(), 'L', 'sv')}`}
                    value={this.state.selectedDay ? `${formatDate(this.state.selectedDay, 'L', 'sv')}` : null}
                    parseDate={parseDate}
                    onDayChange={this.handleDayChange.bind(this)}
                    selectedDay={this.state.selectedDay}
                    format="L"
                    clickUnselectsDay={true}
                    dayPickerProps={{
                        showOutsideDays: true,
                        firstDayOfWeek: 1,
                        locale: 'sv',
                        localeUtils: MomentLocaleUtils,
                        enableOutsideDaysClick: false,
                        toMonth: this.props.disabledAfter ? this.props.disabledAfter : null,
                        fromMonth: this.props.disabledBefore ? this.props.disabledBefore : null,
                        disabledDays: [
                            {
                                after: this.props.disabledAfter,
                                before: this.props.disabledBefore,
                            },
                        ],
                    }}
                ></ExtDayPickerInput>
            </div>
        );
    }
}

export default DayPickerInput;
