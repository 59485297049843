import { of, concat } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';

import { userManager } from '../store';

import {
    LOGIN_SUCCESS,
    loginStatus,
    // Demo
    LOGIN_DEMO,
} from '../actions';

// en is not currently used, but i felt need to include it for the future when/if a language picker is included.
const BANK_ID_STRINGS = {
    sv: {
        OUTSTANDING_TRANSACTION: 'Starta BankID-appen',
        USER_SIGN: 'Skriv in din säkerhetskod i BankIDappen och välj Legitimera eller Skriv under',
        NO_CLIENT: 'Starta BankID-appen',
        STARTED: 'Söker efter BankID, det kan ta en liten stund…',
        COMPLETE: 'Loggar in...',
        USER_CANCEL: 'Åtgärden avbruten',
    },
    en: {
        OUTSTANDING_TRANSACTION: 'Start your BankID app',
        USER_SIGN: 'Enter your security code in the BankID app and select Identify or Sign.',
        NO_CLIENT: 'Start your BankID app.',
        STARTED: 'Searching for BankID:s, it may take a little while…',
        COMPLETE: 'Logging in...',
        USER_CANCEL: 'Action cancelled',
    },
};

export const loginSuccessEpic = (action$) =>
    action$.ofType(LOGIN_SUCCESS).pipe(
        switchMap((d) => {
            userManager.signinRedirect({
                extraQueryParams: {
                    tid: d.payload,
                },
            });
            return of(
                loginStatus({
                    status: {
                        message: BANK_ID_STRINGS.sv['COMPLETE'],
                    },
                    isLoading: true,
                })
            );
        })
    );

export const loginDemoEpic = (action$) =>
    action$.ofType(LOGIN_DEMO).pipe(
        mergeMap((action) => {
            // Tells user that connection is in progress and displays message if something went wrong
            return concat(
                of(
                    loginStatus({
                        isLoggedIn: false,
                        isLoggedInDemo: false,
                        isLoading: true,
                        isDemoLoading: true,
                        demoLoginError: '',
                        user: null,
                        error: null,
                    })
                ),
                of(
                    userManager.signinRedirect({
                        extraQueryParams: {
                            usr: action.payload.username,
                            pwd: action.payload.password,
                        },
                    })
                ),
                of(
                    loginStatus({
                        isLoggedIn: true,
                        isLoggedInDemo: true,
                        isLoading: false,
                        isDemoLoading: false,
                        user: {},
                        error: null,
                    })
                )
            );
        })
    );
