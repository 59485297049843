import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';

import Icon from '../../../global/Icon';
import Spinner from '../../../global/Spinner';
import FileItemDetails from './FileItemDetails';

import { uploadFileInvoiceSetService, uploadTransferSetChange } from '../../../actions';

// This component show uploaded files in the sell invoice page. It also displays progress while uploading and error,
// and handles opening/closing of item for detailed view.
class FileItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };

        this.handleServiceChange = this.handleServiceChange.bind(this);
        this.onDetailsValidationChanged = this.onDetailsValidationChanged.bind(this);
        this.handleTransferClicked = this.handleTransferClicked.bind(this);
    }

    handleFileClick(file, event) {
        event.preventDefault();

        if (this.state.isOpen) {
            if (this.state.detailsValid === false) {
                return;
            }
        }

        this.setState((state) => {
            state.isOpen = !state.isOpen;
            return state;
        });
    }

    handleServiceChange(checked, event) {
        this.stopPropagation(event);
        this.props.uploadFileInvoiceSetService(this.props.fileObj.interpretationId);
    }

    stopPropagation(event) {
        event.stopPropagation();
    }

    onDetailsValidationChanged(isDetailsValid) {
        this.setState((state) => {
            state.detailsValid = isDetailsValid;
            return state;
        });

        this.props.onValidationChanged(this.props.index, isDetailsValid);
    }

    handleTransferClicked(event) {
        this.stopPropagation(event);
        this.props.uploadTransferSetChange(this.props.index);
    }

    getAttachmentCount(fileObj) {
        if (fileObj) {
            if (fileObj.attachments) {
                return fileObj.attachments.length;
            }
        }
        return '0';
    }

    render() {
        let classNames = this.state.isOpen ? 'files__item files__item--active' : 'files__item';
        const fileObj = this.props.upload.files[this.props.index];

        return (
            <li className={classNames}>
                <div
                    onClick={this.handleFileClick.bind(this, this.props.fileObj)}
                    className={fileObj.uploadDone ? 'files__item-row' : 'files__item-row files__item-row--uploading'}
                >
                    {fileObj.uploadDone && fileObj.success ? (
                        <React.Fragment>
                            <div className="files__cloud-img">{fileObj.isLoading ? <Spinner /> : <Icon name="invoice-small" />}</div>
                            <div className="files__panel--small">
                                <p className="paragraph">
                                    <small>Markera</small>
                                </p>
                                <div className="paragraph filter__states">
                                    <button
                                        className={
                                            fileObj.transfer
                                                ? 'button button--chip button--approve button--icon-only'
                                                : 'button button--chip button--approve button--icon-only button--disabled'
                                        }
                                        onClick={this.handleTransferClicked}
                                    >
                                        <Icon name="check" />
                                    </button>
                                </div>
                            </div>
                            <div className="files__panel">
                                <p className="paragraph">
                                    <small>Fil</small>
                                </p>
                                <p className="paragraph">
                                    <strong>{fileObj.file.name}</strong>
                                </p>
                            </div>
                            <div className="files__panel">
                                <p className="paragraph">
                                    <small>Bilagor</small>
                                </p>
                                <p className="paragraph">
                                    <strong>{this.getAttachmentCount(fileObj)} st</strong>
                                </p>
                            </div>
                            <div className="files__panel files__panel--reverse">
                                <p className="paragraph">
                                    <small>{fileObj.sell ? 'Finansieras' : 'Administreras'}</small>
                                </p>
                                <div className="paragraph files__item-row--switch" onClick={this.stopPropagation}>
                                    <Switch
                                        onChange={this.handleServiceChange}
                                        checked={fileObj.sell}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={20}
                                        height={12}
                                        width={40}
                                        onColor="#179e5a"
                                        offColor="#3268ba"
                                    />
                                </div>
                            </div>
                            <div className="files__item-arrow">
                                <div className="files__arrow-img">
                                    <Icon name="down" />
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="files__cloud-img">{!fileObj.error ? <Spinner /> : <Icon name="speech-2-error" />}</div>
                            <div className="files__item-name">
                                <div className="files__recipient">{fileObj.file ? <span className="data">{fileObj.file.name}</span> : null}</div>
                            </div>
                            {fileObj.uploadDone && !fileObj.success ? (
                                <div className="files__item-progress">
                                    <div className="files__status-text">Kunde inte ladda upp fil</div>
                                    <div className="bar bar--error">
                                        <div className="bar__progress" style={{ width: (fileObj.percentage || 100) + '%' }}></div>
                                    </div>
                                </div>
                            ) : null}
                            {!fileObj.uploadDone ? (
                                <div className="files__item-progress">
                                    {fileObj.percentage !== 100 ? (
                                        <React.Fragment>
                                            <div className="files__status-text">
                                                Laddar upp...
                                                <span className="files__percentage">{fileObj.percentage || 0}%</span>
                                            </div>
                                            <div className="bar">
                                                <div className="bar__progress" style={{ width: (fileObj.percentage || 0) + '%' }}></div>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="files__status-text">
                                                <div className="files__status-text">{fileObj.loadingText || 'Uppladdning klar! Bearbetar fil...'}</div>
                                                <div className="bar bar--processing">
                                                    <div className="bar__progress" style={{ width: fileObj.percentage + '%' }}></div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            ) : null}
                            <div className="files__item-arrow">
                                <div className="files__arrow-img">
                                    <Icon name="down" />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                {this.state.isOpen ? <FileItemDetails index={this.props.index} onValidationChanged={this.onDetailsValidationChanged} /> : null}
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        upload: state.upload,
    };
};

FileItem = connect(mapStateToProps, {
    uploadFileInvoiceSetService,
    uploadTransferSetChange,
})(FileItem);

export default FileItem;
