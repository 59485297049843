import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../global/Spinner';
import { notificationRouteFetch } from '../../actions';
import { push } from 'redux-first-history';
import { organisationChooseCommitment } from '../../actions';

export class NotifcationRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            redirected: false,
        };
    }
    componentDidUpdate() {
        if (this.props.organisation.list.length > 0 && !this.state.fetched && this.props.match.params.id) {
            this.setState((state) => {
                state.fetched = true;
                return state;
            });
            this.props.notificationRouteFetch(this.props.match.params.id);
        }
        if (this.state.fetched && this.props.notificationRoute.resolved) {
            if (!this.state.redirected) {
                this.redirected = true;
                if (!this.props.notificationRoute.switchToCommiment) {
                    localStorage.removeItem('deepLink');
                    this.props.push({ pathname: this.props.notificationRoute.routeUrl, search: this.props.notificationRoute.routeParams });
                }
            }
        }
    }
    switchCompany() {
        this.props.organisationChooseCommitment(this.props.notificationRoute.switchToCommiment);
        localStorage.removeItem('deepLink');
        this.props.push({ pathname: this.props.notificationRoute.routeUrl, search: this.props.notificationRoute.routeParams });
    }
    render() {
        return (
            <div className="content__body">
                <div className="content__main">
                    <div className="content__spinner">
                        {(!this.state.fetched || this.props.notificationRoute.loading) && !this.props.notificationRoute.switchToCompany ? <Spinner /> : null}
                    </div>

                    {this.props.notificationRoute.switchToCommiment ? (
                        <div className="content__wrapper content__wrapper--stuffing">
                            <h1>Notifiering</h1>
                            <p>Den här notifiering är för ditt bolag {this.props.notificationRoute.switchToCommiment.name}</p>
                            <p className="caption">För att se innehållet för notifieringen behöver du byta bolag:</p>
                            <button className="button button button--function" onClick={this.switchCompany.bind(this)}>
                                Gå till {this.props.notificationRoute.switchToCommiment.name}
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notificationRoute: state.notificationRoute,
        organisation: state.organisation,
    };
};

NotifcationRouter = connect(mapStateToProps, {
    notificationRouteFetch,
    push,
    organisationChooseCommitment,
})(NotifcationRouter);

export default NotifcationRouter;
