import { concat, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, switchMap, catchError } from 'rxjs/operators';

import {
    TRANSFERCOSTS_FETCH_LIST,
    TRANSFERCOSTS_RECORD_DETAILS_FETCH,
    TRANSFERCOSTS_RECORD_DETAILS_DOWLOAD_DOC,
    TRANSFER_RECORD_DETAILS_DOWNLOAD_SIE_FILE,
    transfercostsUpdate,
} from '../actions';
import { downloadPdfForApp, downloadSieForApp } from '../global/Helpers';

let endpoint = `${window.location.origin}/`;
let keerosEndpoint = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    endpoint = process.env.REACT_APP_API_URL;
    keerosEndpoint = process.env.REACT_APP_KEEROS_API_URL;
}

export const transfercostsFetchListEpic = (action$, store) =>
    action$.ofType(TRANSFERCOSTS_FETCH_LIST).pipe(
        map((action) => action.payload),
        switchMap((payload) => {
            // This epic triggers fetch from third party system that can be slow sometimes.
            // It will start a load of stuff in the backend that will ping back through socket when done.
            return concat(
                of(transfercostsUpdate({ loading: true, queryId: '', filter: { date: payload } })),
                ajax
                    .post(`${endpoint}api/transfercost/list`, payload, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        switchMap((data) => {
                            if (data) {
                                data = data.map((data) => {
                                    data.createdDate = new Date(data.createdDate);
                                    return data;
                                });
                            } else {
                                // Data is null or undefined, force it to become an array to avoid issued further down the line
                                data = [];
                            }
                            return of(transfercostsUpdate({ records: data, error: false, loading: false }));
                        }),
                        catchError((error) => {
                            console.log("Couldn't get query ID for transfer costs", error);
                            return of(transfercostsUpdate({ error: true, loading: false }));
                        })
                    )
            );
        })
    );

export const transfercostsRecordDetailsFetchEpic = (action$, store) =>
    action$.ofType(TRANSFERCOSTS_RECORD_DETAILS_FETCH).pipe(
        map((action) => action.payload),
        switchMap((payload) => {
            // Socket has told us to proceed, to go fetch data and store it.
            return concat(
                of(transfercostsUpdate({ detailsLoading: true, chosenRecord: { id: payload } })),
                ajax
                    .get(`${endpoint}api/transfercost/record/${payload}`, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        map((ajaxResponse) => ajaxResponse.response),
                        switchMap((data) => {
                            return of(transfercostsUpdate({ detailsLoading: false, fileError: false, chosenRecord: { id: payload, ...data } }));
                        }),
                        catchError((error) => {
                            console.log("Couldn't get query ID for transfer costs", error);
                            return of(transfercostsUpdate({ error: true, loading: false }));
                        })
                    )
            );
        })
    );

export const transfercostsRecordDetailsDowloadDocEpic = (action$, store) =>
    action$.ofType(TRANSFERCOSTS_RECORD_DETAILS_DOWLOAD_DOC).pipe(
        map((action) => action.payload),
        switchMap((payload) => {
            // Socket has told us file is available for download. Start downloading it
            const path = `${endpoint}api/transfercost/document/${payload}`;
            const fileName = `Avräkning-${payload}.pdf`;
            const accessToken = store.value.oidc.user.access_token;
            const organisationNumber = store.value.organisation.organisationNumber;
            if (downloadPdfForApp(path, fileName, accessToken, store.value.organisation.organisationNumber)) {
                return of(transfercostsUpdate({ fileLoading: false, fileError: false }));
            }
            return ajax({
                url: path,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'organisation-number': organisationNumber,
                },
                responseType: 'arraybuffer',
            }).pipe(
                switchMap((response) => {
                    // Some magic to create a file and a URL for that file that we just downloaded
                    if (response.status === 200) {
                        const buffer = response.response;
                        const type = response.xhr.getResponseHeader('content-type');
                        const blob = new Blob([buffer], { type: type });
                        const url = window.URL.createObjectURL(blob);
                        // Create a link element and give it attributes in order to being able to save of downloaded file
                        var linkElement = document.createElement('a');
                        linkElement.style = 'display: none';
                        linkElement.href = url;
                        linkElement.download = fileName;
                        // Append link to body, click it to trigger save
                        document.body.appendChild(linkElement);
                        linkElement.click();
                        window.URL.revokeObjectURL(url); // Cleanup url. This shouldn't be an issue but i think it's best practice to clean up stuff when i remember to.

                        return of(transfercostsUpdate({ fileLoading: false, fileError: false }));
                    } else {
                        return of(transfercostsUpdate({ fileError: true, loading: false }));
                    }
                }),
                catchError((error) => {
                    console.log("Couldn't get query ID for transfer costs", error);
                    return of(transfercostsUpdate({ error: true, loading: false }));
                })
            );
        })
    );

export const transfercostRecordDetailsDownloadSieEpic = (action$, store) =>
    action$.ofType(TRANSFER_RECORD_DETAILS_DOWNLOAD_SIE_FILE).pipe(
        map((action) => action.payload),
        switchMap((payload) => {
            const path = `${keerosEndpoint}api/keeros-service/transfercostsiefile`;
            const accessToken = store.value.oidc.user.access_token;
            const fileName = `Sie Rapport-${payload}.si`;
            const organizationNumber = store.value.organisation.organisationNumber;
            if (downloadSieForApp(path, fileName, accessToken, organizationNumber, payload)) {
                return of(transfercostsUpdate({ fileLoading: false, fileError: false }));
            }
            return ajax({
                url: path,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'content-type': 'application/json',
                },
                body: {
                    transferCostId: payload,
                    OrganizationNumber: organizationNumber,
                },
                responseType: 'arraybuffer',
            }).pipe(
                switchMap((response) => {
                    if (response.status === 200) {
                        const utf8json = JSON.parse(new TextDecoder().decode(response.response));
                        const text = window.atob(utf8json.data);
                        const blob = new Blob([text], { type: utf8json.mime });
                        const url = window.URL.createObjectURL(blob);

                        var linkElement = document.createElement('a');
                        linkElement.style = 'display: none';
                        linkElement.href = url;
                        linkElement.download = fileName;
                        document.body.appendChild(linkElement);
                        linkElement.click();
                        window.URL.revokeObjectURL(url);

                        return of(transfercostsUpdate({ fileLoading: false, fileError: false }));
                    } else {
                        return of(transfercostsUpdate({ fileError: true, loading: false }));
                    }
                }),
                catchError((error) => {
                    console.log('Could not complete SIE file request', error);
                    return of(transfercostsUpdate({ error: true, loading: false }));
                })
            );
        })
    );
