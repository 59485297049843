import React, { Component } from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { connect } from 'react-redux';

import ImageModal from './ImageModal';

import { modalTrigger } from '../actions';

class ImageSlider extends Component {
    constructor(props) {
        super(props);
        this.imgContainer = React.createRef();
        this.state = {
            photos: [],
            configs: [],
        };

        const resizeObs = fromEvent(window, 'resize').pipe(debounceTime(10));
        this.subscriber = resizeObs.subscribe((event) => {
            this.handleSliderUpdate();
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.state.photos) === JSON.stringify(this.props.photos)) {
            return;
        }
        if (this.props.photos) {
            this.setState(
                (state) => {
                    state.photos = this.props.photos;
                    return state;
                },
                () => this.handleSliderUpdate()
            );
        }
    }
    componentWillUnmount() {
        this.subscriber.unsubscribe();
    }

    openModal(src, event) {
        this.props.modalTrigger(true, () => <ImageModal img={src} />);
    }

    handleChange({ target: { value } }) {
        this.setState({ currPhoto: value });
    }

    handleSliderUpdate() {
        let configs = [];
        const { photos, currPhoto } = this.state;
        const leftStartCoords = photos.slice(0, currPhoto).reduce((sum, width) => sum - this.imgContainer.current.offsetWidth, 0);

        photos.reduce((prevLeft, photo, i) => {
            if (window.matchMedia('(max-width: 1080px)').matches) {
                configs.push({
                    src: photo.src,
                });
            } else {
                if (currPhoto === i) {
                    configs.push({
                        src: photo.src,
                    });
                } else {
                    configs.push({
                        src: photo.src,
                    });
                }
            }
            return prevLeft + this.imgContainer.current.offsetWidth;
        }, leftStartCoords);

        this.setState({ configs: configs });
    }

    componentDidMount() {
        this.handleSliderUpdate();
    }

    render() {
        return (
            <div className="invoice-image">
                <div className="invoice-image__invoice" ref={this.imgContainer}>
                    {this.state.configs.map(({ src, alt }, i) => (
                        <div className="invoice-image__item" key={i}>
                            <img onClick={this.openModal.bind(this, src)} className="invoice-image__img" src={src} alt={alt} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
    };
};

ImageSlider = connect(mapStateToProps, {
    modalTrigger,
})(ImageSlider);

export default ImageSlider;
