import React, { Component } from 'react';
import { connect } from 'react-redux';
import { organisationGetCommitments, organisationChooseCommitment } from '../../actions';
import { userManager } from '../../store';
import { isWebView, Constants } from '../Helpers';

import Icon from '../Icon';
import Spinner from '../Spinner';

class UserSettings extends Component {
    constructor(props) {
        super(props);
        this.commitmentWrapper = React.createRef();
    }
    toggleCommitmentPicker(event) {
        event.preventDefault();
        this.commitmentWrapper.current.classList.toggle('navigation__commitment--active');

        // If we opened the commitment picker, start listen to clicks outside in order to close it properly
        if (this.commitmentWrapper.current.classList.contains('navigation__commitment--active')) {
            if (this.props.oidc.user && this.props.organisation.list.length === 0) {
                this.props.organisationGetCommitments();
            }
            const element = this.commitmentWrapper.current;
            const isVisible = (elem) => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length); // source (2018-03-11): https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js
            const outsideClickListener = (event) => {
                if (!element.contains(event.target) && isVisible(element)) {
                    // or use: event.target.closest(selector) === null
                    this.commitmentWrapper.current.classList.remove('navigation__commitment--active');
                    removeClickListener();
                }
            };
            const removeClickListener = () => {
                document.removeEventListener('click', outsideClickListener);
            };
            document.addEventListener('click', outsideClickListener);
        }
    }
    chooseCommitment(commitment, event) {
        this.commitmentWrapper.current.classList.toggle('navigation__commitment--active');
        this.props.toggleMenu();

        if (commitment.status !== 'CurrentlyOnboarding') {
            event.preventDefault();
            this.props.organisationChooseCommitment(commitment);
        }
    }
    getOnBoardingUrl(commitment) {
        if (commitment.status !== 'CurrentlyOnboarding') {
            return null;
        }

        if(isWebView())
        {
            return  process.env.REACT_APP_REGISTER_URL + Constants.webkitTargetNativeBrowserUrlHash();
        }

        return process.env.REACT_APP_REGISTER_URL;
    }

    logout(event) {
        // this.props.loginStatus({isLoading: true});
        event.preventDefault();
        userManager.signoutRedirect();
    }
    render() {
        let { organisation, oidc } = this.props;
        return (
            <React.Fragment>
                <div className="navigation__user">
                    {oidc && oidc.user ? <p className="paragraph">{oidc.user.profile.name}</p> : null}
                    {/*<Icon name="settings" />*/}
                </div>
                <div className="navigation__commitment" ref={this.commitmentWrapper}>
                    <div className="navigation__current-commitment" onClick={this.toggleCommitmentPicker.bind(this)}>
                        <div>
                            <p className="paragraph">{organisation.name}</p>
                            <p className="caption">{organisation.organisationNumber}</p>
                        </div>
                        <Icon name="right" />
                    </div>
                    <div className="commitment-picker commitment-picker--small">
                        <h3 className="title title--h3">
                            Dina företag
                            {organisation.isLoading ? (
                                <React.Fragment>
                                    <Spinner />
                                </React.Fragment>
                            ) : null}
                        </h3>
                        <button aria-label="Stäng engagemangsväljare" className="close" onClick={this.toggleCommitmentPicker.bind(this)}>
                            <Icon name="close" />
                        </button>

                        {organisation.list.length > 0 ? (
                            <React.Fragment>
                                <div className="commitment-picker__list">
                                    {organisation.list.map((commitment, index) => {
                                        const findClasses = () => {
                                            let classNames = 'commitment-picker__item';
                                            if (
                                                commitment.status === 'NonActive' ||
                                                commitment.status === 'Active' ||
                                                commitment.status === 'CurrentlyOnboarding'
                                            ) {
                                                classNames += '';
                                            } else {
                                                classNames += ' commitment-picker__item--disabled';
                                            }
                                            if (
                                                classNames.indexOf('disabled') === -1 &&
                                                (organisation.chooseCommitmentIsLoading || organisation.organisationNumber === commitment.organisationNumber)
                                            ) {
                                                classNames += ' commitment-picker__item--disabled';
                                            }

                                            return classNames;
                                        };
                                        return (
                                            <a href={this.getOnBoardingUrl(commitment)} target="_blank" rel="noopener noreferrer">
                                                <button
                                                    disabled={
                                                        organisation.chooseCommitmentIsLoading || organisation.organisationNumber === commitment.organisationNumber
                                                    }
                                                    className={findClasses()}
                                                    key={index}
                                                    onClick={this.chooseCommitment.bind(this, commitment)}
                                                >
                                                    <div className="commitment-picker__panel">
                                                        <p className="paragraph">
                                                            <strong>{commitment.name}</strong>
                                                        </p>
                                                        <p className="caption">{commitment.organisationNumber}</p>
                                                    </div>
                                                    <div className="commitment-picker__panel">
                                                        {(commitment.status === 'NonActive' || commitment.status === 'Active') &&
                                                        !(organisation.organisationNumber === commitment.organisationNumber) ? (
                                                            <Icon name="right" />
                                                        ) : null}
                                                        {commitment.status === 'CurrentlyOnboarding' ? <Icon name="external" /> : null}
                                                    </div>
                                                </button>
                                            </a>
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
                <div className="navigation__logout">
                    <button onClick={this.logout.bind(this)} className="button button--function button--full" type="button">
                        Logga ut <Icon name="logout" />
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        organisation: state.organisation,
        oidc: state.oidc,
    };
};

UserSettings = connect(mapStateToProps, {
    organisationGetCommitments,
    organisationChooseCommitment,
})(UserSettings);

export default UserSettings;
