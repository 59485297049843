import React, { Component } from 'react';
import { connect } from 'react-redux';

export class MaintenancePage extends Component {
    render() {
        return (
            <div className="content__body">
                <div className="content__main content__main--404">
                    <div className="maintenance">
                        <span className="title title--huge">
                            <div className="title__huge">Underhåll</div>
                        </span>
                        <h1 className="title title--h1">Just nu har vi ett planerat underhåll</h1>
                        <p className="caption">Vi är strax tillbaka!</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePage);
