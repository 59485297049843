import React, { Component } from 'react';
import { connect } from 'react-redux';

import { modalTrigger } from '../actions';

// This component is responsible for modals. It is a wrapper for the whole page in order to create a blur effect while modal is open.
class Modal extends Component {
    componentWillUnmount() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }
    closeModal(event) {
        event.preventDefault();
        event.persist();
        this.props.modalTrigger(false);
    }
    render() {
        let props = this.props;
        let ModalContent = props.modal.component;
        return (
            <div className={props.modal.isOpen ? 'modal modal--open' : 'modal'}>
                {props.modal.isOpen ? (
                    <React.Fragment>
                        <button aria-label="Stäng ruta" className="close close--alt" onClick={this.closeModal.bind(this)} type="button">
                            <span className="close__icon"></span>
                        </button>
                        <div className="modal__box">
                            <div className="modal__wrapper">
                                <ModalContent {...props.modal.passedProps} />
                            </div>
                        </div>
                        <div className="modal__close-handler" onClick={this.closeModal.bind(this)} />
                    </React.Fragment>
                ) : null}
                <div className="modal__content">
                    {/* The children is basically everything on the page since this component is a wrapper for just that. */}
                    {props.children}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
    };
};

Modal = connect(mapStateToProps, {
    modalTrigger,
})(Modal);

export default Modal;
