import React, { Component } from 'react';
import { Motion, spring } from 'react-motion';
import { connect } from 'react-redux';

import FileUploader from './components/FileUploader';
import FileItem from './components/FileItem';
import Icon from '../../global/Icon';
import Spinner from '../../global/Spinner';
import InformationModal from '../../global/modals/InformationModal';

import {
    modalTrigger,
    uploadUploadFile,
    recipientsInit,
    uploadFinalizeSell,
    uploadInvoicesTransfer,
    uploadFindExistingUploads,
    socketInit,
    socketClose,
} from '../../actions';

class SellInvoicePage extends Component {
    constructor(props) {
        super(props);
        this.sideMenu = React.createRef();
        this.transferInvoices = React.createRef();
        this.subscriber = [];
        this.validationList = [];
        this.state = {
            fileUploaderExpanded: true,
            showCreditRiskView: false,
            allFilesValid: true,
        };
    }
    componentDidMount() {
        this.props.uploadFindExistingUploads();
    }
    componentWillUnmount() {
        this.subscriber.forEach((sub) => {
            sub.unsubscribe();
        });
    }
    componentDidUpdate(prevProps) {
        // open info dialog if we successfully transfered invoices
        if (this.props.upload && this.props.upload.transfer && this.props.upload.transfer.success) {
            if (prevProps.upload && prevProps.upload.transfer) {
                if (this.props.upload.transfer.success !== prevProps.upload.transfer.success) {
                    this.openInvoicesTransferredInfomration();
                }
            } else {
                this.openInvoicesTransferredInfomration();
            }
        }
    }
    fileAlreadyExists(file) {
        let fileExist = false;
        this.props.upload.files.map((f) => {
            if (file.name === f.file.name && file.size === f.file.size) {
                fileExist = true;
            }
            return f;
        });

        return fileExist;
    }
    fileUploadDone() {
        var done = this.props.upload.files.every(function (file) {
            return file.uploadDone;
        });

        return done;
    }
    fileListUpdate(files) {
        Array.from(files).forEach((file) => {
            if (this.fileAlreadyExists(file)) {
                return;
            }
            if (!this.props.socket.isLoading && !this.props.socket.socket) {
                this.props.socketInit();
            }
            this.props.uploadUploadFile({ file: file });
            this.setState((state) => {
                state.fileUploaderExpanded = false;
                return state;
            });
        });
    }
    handleUploadToggler(event) {
        event.preventDefault();
        document.body.scrollTo({ top: 0, behavior: 'smooth' });
        this.setState((state) => {
            state.fileUploaderExpanded = !state.fileUploaderExpanded;

            return state;
        });
    }
    showCreditRisk(event) {
        if (event) {
            event.preventDefault();
        }
        this.setState(
            (state) => {
                state.showCreditRiskView = !state.showCreditRiskView;
                return state;
            },
            () => {
                if (this.state.showCreditRiskView) {
                    this.sideMenu.current.classList.add('content__menu--inactive');
                } else {
                    this.sideMenu.current.classList.remove('content__menu--inactive');
                }
            }
        );
    }
    handleTransferInvoices(event) {
        event.preventDefault();

        this.transferInvoices.current.dispatchEvent(new Event('blur'));

        const selectedFiles = this.props.upload.files.filter((file) => {
            return file.transfer === true;
        });
        if (selectedFiles.length > 0) {
            this.props.uploadInvoicesTransfer(selectedFiles);
        }
        // todo else notify ui... same in import flow
    }
    onInvoiceValidationChanged(index, isValid) {
        this.validationList[index] = isValid;

        this.setState((state) => {
            state.allFilesValid = this.validationList.every((v) => v !== false);
            return state;
        });
    }
    openInvoicesTransferredInfomration() {
        this.props.modalTrigger(true, () => (
            <InformationModal
                header="Dina fakturor har skickats till Factoringgruppen"
                body="En handläggare kommer börja arbeta med dessa så fort som möjligt. En avräkning skickas per e-post så fort de är färdigbehandlade. Vid frågor ring 031-719 99 20 eller maila kund@fg.se"
            />
        ));
    }
    render() {
        return (
            <div className="content__body sell-invoice">
                <div className="content__menu content__menu--sticky" ref={this.sideMenu}>
                    <Motion
                        defaultStyle={{
                            opacity: 0,
                            scale: 0.95,
                        }}
                        style={{
                            opacity: spring(1),
                            scale: spring(1, { stiffness: 300, damping: 18 }),
                        }}
                    >
                        {(interpolatingStyle) => (
                            <React.Fragment>
                                <div
                                    style={
                                        interpolatingStyle.scale !== 1
                                            ? {
                                                  opacity: interpolatingStyle.opacity,
                                                  transform: `scale(${interpolatingStyle.scale})`,
                                              }
                                            : {
                                                  opacity: interpolatingStyle.opacity,
                                              }
                                    }
                                    className={
                                        this.state.fileUploaderExpanded ? 'sell-invoice__uploader sell-invoice__uploader--active' : 'sell-invoice__uploader'
                                    }
                                >
                                    <div className="dashboard__greeting">
                                        <h3 className="title title--h3">Ladda upp nya fakturor</h3>
                                    </div>
                                    <FileUploader disabled={this.props.upload.transfer && this.props.upload.transfer.transfering} fileListUpdate={this.fileListUpdate.bind(this)}></FileUploader>
                                </div>
                                <button
                                    className={
                                        this.state.fileUploaderExpanded
                                            ? 'sell-invoice__upload-toggler sell-invoice__upload-toggler--active'
                                            : 'sell-invoice__upload-toggler'
                                    }
                                    onClick={this.handleUploadToggler.bind(this)}
                                >
                                    {!this.state.fileUploaderExpanded ? 'Klicka för att ladda upp filer' : 'Stäng'} <Icon name="down" />
                                </button>
                                <div className="paragraph caption sell-invoice__caption">
                                    Ladda upp enbart fakturor med denna funktion. Bilagor laddas upp genom att klicka på fakturan.
                                </div>
                            </React.Fragment>
                        )}
                    </Motion>
                </div>
                <div className="content__main">
                    <div className="content__wrapper content__wrapper--stuffing" ref={this.transferInvoices}>
                        <h1 className="title title--h1">Ladda upp fakturor</h1>
                        {this.props.upload.transfer && this.props.upload.transfer.transfering ? (
                            <Spinner />
                        ) : this.props.upload.files && this.props.upload.files.length > 0 ? (
                            <React.Fragment>
                                <ul className="files">
                                    {this.props.upload.files.map((fileObj, index) => {
                                        return (
                                            <FileItem
                                                key={`${fileObj.interpretationId || index}`}
                                                fileObj={fileObj}
                                                index={index}
                                                onValidationChanged={this.onInvoiceValidationChanged.bind(this)}
                                            />
                                        );
                                    })}
                                </ul>
                                {this.props.upload.transfer &&
                                this.props.upload.transfer.error &&
                                this.props.upload.transfer.error.type === 'HANDLETRANSFERINVOICEERROR' ? (
                                    <Motion defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }} style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}>
                                        {(value) => (
                                            <div
                                                className="sell-invoice__async-status sell-invoice__async-status--error"
                                                style={{ opacity: value.opacity, top: value.top, transform: `scale(${value.scale})` }}
                                            >
                                                {this.props.upload.transfer.error.message}
                                            </div>
                                        )}
                                    </Motion>
                                ) : null}
                                <div className="content__wrapper--reversed submit-sell-invoice">
                                    <button
                                        className={this.state.allFilesValid && this.fileUploadDone() ? 'button' : 'button button--disabled'}
                                        disabled={!this.fileUploadDone()}
                                        onClick={this.handleTransferInvoices.bind(this)}
                                    >
                                        Överlåt fakturor
                                    </button>
                                </div>
                                <div className="content__wrapper">
                                    {!this.state.allFilesValid ? (
                                        <React.Fragment>
                                            <Motion
                                                defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }}
                                                style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}
                                            >
                                                {(value) => (
                                                    <div
                                                        className="sell-invoice__async-status sell-invoice__async-status--error"
                                                        style={{ opacity: value.opacity, top: value.top, transform: `scale(${value.scale})` }}
                                                    >
                                                        Det finns felaktiga epostadresser angivna.
                                                    </div>
                                                )}
                                            </Motion>
                                        </React.Fragment>
                                    ) : null}
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="sell-invoice__empty">
                                {this.props.upload.isLoading ? (
                                    <Spinner />
                                ) : (
                                    <React.Fragment>
                                        <Icon name="empty-list" />
                                        <h2 className="title title--h2">Alldeles tomt!</h2>
                                        <p className="paragraph">Ladda upp fakturor för att komma vidare.</p>
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        upload: state.upload,
        recipients: state.recipients,
        socket: state.socket,
    };
};

SellInvoicePage = connect(mapStateToProps, {
    modalTrigger,
    uploadUploadFile,
    recipientsInit,
    uploadFinalizeSell,
    uploadInvoicesTransfer,
    uploadFindExistingUploads,
    socketInit,
    socketClose,
})(SellInvoicePage);

export default SellInvoicePage;
