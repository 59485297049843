export const INTEGRATION_UPDATE = 'INTEGRATION_UPDATE';
export const INTEGRATION_COMPLETE = 'INTEGRATION_COMPLETE';
export const INTEGRATION_HAS_COMPLETED = 'INTEGRATION_HAS_COMPLETED';
export const INTEGRATION_INITIATE_ONBOARDING = 'INTEGRATION_INITIATE_ONBOARDING';

export const integrationHasCompleted = () => ({
    type: INTEGRATION_HAS_COMPLETED,
});
export const integrationSetComplete = (state) => ({
    type: INTEGRATION_COMPLETE,
    payload: state,
});

export const integrationUpdate = (state) => ({
    type: INTEGRATION_UPDATE,
    payload: state,
});

export const integrationInitiateOnboarding = (state) => ({
    type: INTEGRATION_INITIATE_ONBOARDING,
    payload: state,
});
