import React, { Component } from 'react';
import { connect } from 'react-redux';

// Quick and easy state displayer. Give it a state, it will give you a state label.
// function = blue state
// approve = green state
// wait = yellow state
// warning = red state
export const stateMapper = {
    Registered: {
        text: 'Registrerad',
        class: 'function',
    },
    Clearing: {
        text: 'Avräkning',
        class: 'approve',
    },
    Payed: {
        text: 'Betald',
        class: 'approve',
    },
    Approved: {
        text: 'Godkänd',
        class: 'approve',
    },
    Ready: {
        text: 'Klar',
        class: 'approve',
    },
    ApprovedForDelivery: {
        text: 'Mottagare godkänt',
        class: 'approve',
    },
    PaymentPlan: {
        text: 'Betalningsplan',
        class: 'wait',
    },
    ManualHandling: {
        text: 'Granskas',
        class: 'wait',
    },
    PendingRecipientApproval: {
        text: 'Inväntar leveransgodkännande',
        class: 'wait',
    },
    Reminder1: {
        text: 'Påminnelse 1',
        class: 'wait',
    },
    Reminder2: {
        text: 'Påminnelse 2',
        class: 'wait',
    },
    Recalled: {
        text: 'Återkallad',
        class: 'wait',
    },
    Rejected: {
        text: 'Avvisad',
        class: 'warning',
    },
    DebtCollector: {
        text: 'Inkasso',
        class: 'warning',
    },
    DebtCollectorWarning: {
        text: 'Inkassovarning',
        class: 'warning',
    },
    RejectedByRecipient: {
        text: 'Mottagare avvisad',
        class: 'warning',
    },
    PaymentIssuerApplication: {
        text: 'Skickad till kronofogden',
        class: 'warning',
    },
    PaymentIssuerDistraint: {
        text: 'Utmätning',
        class: 'warning',
    },
    PaymentIssuerDecision: {
        text: 'Utslag',
        class: 'warning',
    },
    EnforcementAuthorityWarning: {
        text: 'Varning om kronofogde',
        class: 'warning',
    },
    PendingRepurchasing: {
        text: 'Återförd',
        class: 'warning',
    },
    NotSet: {
        text: 'Okänd status',
        class: 'function',
    },
    MessageAdded: {
        text: 'Meddelande',
        class: 'function',
    },
    Reserved: {
        text: 'Hantering pågår',
        class: 'function',
    }
};
class State extends Component {
    findStateInfo(state) {
        return (
            stateMapper[state] || {
                text: 'Okänd status',
                class: '',
            }
        );
    }
    render() {
        const state = this.findStateInfo(this.props.state);
        const classNames = this.props.disabled ? `button button--chip button--disabled button--${state.class}` : `button button--chip button--${state.class}`;
        return <span className={classNames}>{state.text}</span>;
    }
}

const mapStateToProps = (state) => {
    return {
        router: state.router,
    };
};

State = connect(mapStateToProps, {})(State);

export default State;
