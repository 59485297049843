export const UPLOAD_UPDATE = 'UPLOAD_UPDATE';
export const UPLOAD_UPLOAD_FILE = 'UPLOAD_UPLOAD_FILE';
export const UPLOAD_UPLOAD_ATTACHMENT = 'UPLOAD_UPLOAD_ATTACHMENT';
export const UPLOAD_REMOVE_ATTACHMENT = 'UPLOAD_REMOVE_ATTACHMENT';
export const UPLOAD_REMOVED_ATTACHMENT = 'UPLOAD_REMOVED_ATTACHMENT';
export const UPLOAD_ADD_ATTACHMENT = 'UPLOAD_ADD_ATTACHMENT';
export const UPLOAD_ADD_FILES = 'UPLOAD_ADD_FILES';
export const UPLOAD_UPLOAD_FAILURE = 'UPLOAD_UPLOAD_FAILURE';
export const UPLOAD_UPLOAD_PROGRESS = 'UPLOAD_UPLOAD_PROGRESS';
export const UPLOAD_UPLOAD_SUCCESS = 'UPLOAD_UPLOAD_SUCCESS';
export const UPLOAD_REMOVE_FROM_LIST = 'UPLOAD_REMOVE_FROM_LIST';
export const UPLOAD_UPDATED_FILE_LIST = 'UPLOAD_UPDATED_FILE_LIST';
export const UPLOAD_FIND_EXISTING_UPLOADS = 'UPLOAD_FIND_EXISTING_UPLOADS';
export const UPLOAD_GET_RESULT = 'UPLOAD_GET_RESULT';
export const UPLOAD_FETCH_INTERPRETER_DATA = 'UPLOAD_FETCH_INTERPRETER_DATA';
export const UPLOAD_VALIDATE_INVOICE = 'UPLOAD_VALIDATE_INVOICE';
export const UPLOAD_VALIDATE_FAIL = 'UPLOAD_VALIDATE_FAIL';
export const UPLOAD_VALIDATE_SUCCESS = 'UPLOAD_VALIDATE_SUCCESS';
export const UPLOAD_FINALIZE_SELL = 'UPLOAD_FINALIZE_SELL';
export const UPLOAD_FINALIZE_SELL_TRY_AGAIN = 'UPLOAD_FINALIZE_SELL_TRY_AGAIN';
export const UPLOAD_INIT_DETERMINATION = 'UPLOAD_INIT_DETERMINATION';
export const UPLOAD_GET_DETERMINATION = 'UPLOAD_GET_DETERMINATION';
export const UPLOAD_GET_CONFIRMATION = 'UPLOAD_GET_CONFIRMATION';
export const UPLOAD_CONTACT_SUPPORT = 'UPLOAD_CONTACT_SUPPORT';
export const UPLOAD_INVOICES_TRANSFER = 'UPLOAD_INVOICES_TRANSFER';
export const UPLOAD_FILE_INVOICE_SET_SERVICE = 'UPLOAD_FILE_INVOICE_SET_SERVICE';
export const UPLOAD_INVOICES_TRANSFER_FAILURE = 'UPLOAD_INVOICES_TRANSFER_FAILURE';
export const UPLOAD_FILE_UPDATE_EMAIL = 'UPLOAD_FILE_UPDATE_EMAIL';
export const UPLOAD_FILE_ADD_COMMENT = 'UPLOAD_FILE_ADD_COMMENT';
export const UPLOAD_TRANSFER_SET_CHANGE = 'UPLOAD_TRANSFER_SET_CHANGE';

export const uploadUpdate = (state) => ({
    type: UPLOAD_UPDATE,
    payload: state,
});

export const uploadUploadFile = (files) => ({
    type: UPLOAD_UPLOAD_FILE,
    payload: files,
});

export const uploadRemoveAttachment = (file) => ({
    type: UPLOAD_REMOVE_ATTACHMENT,
    payload: file,
});

export const uploadRemovedAttachment = (file) => ({
    type: UPLOAD_REMOVED_ATTACHMENT,
    payload: file,
});

export const uploadUploadAttachment = (files) => ({
    type: UPLOAD_UPLOAD_ATTACHMENT,
    payload: files,
});

export const uploadAddAttachment = (file) => ({
    type: UPLOAD_ADD_ATTACHMENT,
    payload: file,
});

export const uploadAddFiles = (files) => ({
    type: UPLOAD_ADD_FILES,
    payload: files,
});

export const uploadUploadFailure = (file) => ({
    type: UPLOAD_UPLOAD_FAILURE,
    payload: file,
});

export const uploadUploadProgress = (file) => ({
    type: UPLOAD_UPLOAD_PROGRESS,
    payload: file,
});

export const uploadUploadSuccess = (file) => ({
    type: UPLOAD_UPLOAD_SUCCESS,
    payload: file,
});

export const uploadRemoveFromList = (file, index) => ({
    type: UPLOAD_REMOVE_FROM_LIST,
    payload: {
        file: file,
        index: index,
    },
});

export const uploadUpdatedFileList = (files) => ({
    type: UPLOAD_UPDATED_FILE_LIST,
    payload: files,
});

export const uploadFindExistingUploads = () => ({
    type: UPLOAD_FIND_EXISTING_UPLOADS,
});

export const uploadGetResult = (id) => ({
    type: UPLOAD_GET_RESULT,
    payload: id,
});

export const uploadFetchInterpreterData = (id) => ({
    type: UPLOAD_FETCH_INTERPRETER_DATA,
    payload: id,
});

export const uploadValidateInvoice = (invoice, interpretationId) => ({
    type: UPLOAD_VALIDATE_INVOICE,
    payload: {
        invoice: invoice,
        interpretationId: interpretationId,
    },
});

export const uploadValidateFail = (invoice, errors) => ({
    type: UPLOAD_VALIDATE_FAIL,
    payload: {
        invoice: invoice,
        errors: errors,
    },
});

export const uploadValidateSuccess = (invoice) => ({
    type: UPLOAD_VALIDATE_SUCCESS,
    payload: invoice,
});

export const uploadFinalizeSell = () => ({
    type: UPLOAD_FINALIZE_SELL,
});

export const uploadFinalizeSellTryAgain = () => ({
    type: UPLOAD_FINALIZE_SELL_TRY_AGAIN,
});

export const uploadInitDetermination = (ids) => ({
    type: UPLOAD_INIT_DETERMINATION,
    payload: ids,
});

export const uploadGetDetermination = (id) => ({
    type: UPLOAD_GET_DETERMINATION,
    payload: id,
});

export const uploadGetConfirmation = (id) => ({
    type: UPLOAD_GET_CONFIRMATION,
    payload: id,
});

export const uploadContactSupport = (supportQuestion, supportEmail, fileObj) => ({
    type: UPLOAD_CONTACT_SUPPORT,
    payload: {
        question: supportQuestion,
        email: supportEmail,
        fileObj: fileObj,
    },
});

export const uploadInvoicesTransfer = (invoices) => ({
    type: UPLOAD_INVOICES_TRANSFER,
    payload: invoices,
});

export const uploadFileInvoiceSetService = (interpretationId) => ({
    type: UPLOAD_FILE_INVOICE_SET_SERVICE,
    payload: {
        interpretationId,
    },
});

export const uploadInvoicesTransferFailure = (transfer) => ({
    type: UPLOAD_INVOICES_TRANSFER_FAILURE,
    payload: {
        transfer,
    },
});

export const uploadFileAddComment = (comment, index) => ({
    type: UPLOAD_FILE_ADD_COMMENT,
    payload: {
        comment,
        index,
    },
});

export const uploadFileUpdateEmail = (email, index) => ({
    type: UPLOAD_FILE_UPDATE_EMAIL,
    payload: {
        email,
        index,
    },
});

export const uploadTransferSetChange = (index) => ({
    type: UPLOAD_TRANSFER_SET_CHANGE,
    payload: {
        index,
    },
});
