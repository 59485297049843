import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';

import historyReducer from './history';
import loadingReducer from './loading';
import loginReducer from './login';
import modalReducer from './modal';
import organisationReducer from './organisation';
import recipientsReducer from './recipients';
import socketReducer from './socket';
import uploadReducer from './upload';
import transfercostsReducer from './transfercosts';
import maturityStructure from './maturityStructure';
import importReducer from './import';
import settingsReducer from './settings';
import pushNotificationReducer from './pushNotification';
import notificationRouteReducer from './notification';
import integrationRouteReducer from './integration';

export const rootReducer = (routerReducer) =>
    combineReducers({
        history: historyReducer,
        loading: loadingReducer,
        loginInformation: loginReducer,
        maturityStructure: maturityStructure,
        modal: modalReducer,
        oidc: oidcReducer,
        organisation: organisationReducer,
        recipients: recipientsReducer,
        router: routerReducer,
        socket: socketReducer,
        upload: uploadReducer,
        transfercosts: transfercostsReducer,
        import: importReducer,
        settings: settingsReducer,
        pushNotification: pushNotificationReducer,
        notificationRoute: notificationRouteReducer,
        integration: integrationRouteReducer,
    });
