import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from '../../../global/Icon';
import Spinner from '../../../global/Spinner';
import { transfercostsRecordDetailsDownloadDoc, transfercostsRecordDetailsDownloadSieFile } from '../../../actions';

class HistoryTransfercostsDetailsInner extends Component {
    findAndGetFile(event) {
        event.preventDefault();
        this.props.transfercostsRecordDetailsDownloadDoc(this.props.transfercosts.chosenRecord.transferCostId);
    }
    findAndGetSieFile(event) {
        event.preventDefault();
        this.props.transfercostsRecordDetailsDownloadSieFile(this.props.transfercosts.chosenRecord.transferCostId);
    }
    render() {
        const transferCostsItemClasses = (amount) => {
            if (amount < 0) {
                return 'transfer-costs__item transfer-costs__item--large transfer-costs__item--warning';
            } else if (amount === 0) {
                return 'transfer-costs__item transfer-costs__item--large transfer-costs__item--wait';
            }

            return 'transfer-costs__item transfer-costs__item--large';
        };
        return (
            <React.Fragment>
                {this.props.transfercosts && this.props.transfercosts.chosenRecord ? (
                    <div className={this.props.transfercosts.detailsLoading ? 'content__wrapper content__wrapper--loading' : 'content__wrapper'}>
                        {this.props.transfercosts.detailsLoading ? (
                            <div className="content__loader">
                                <Spinner />
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="transfer-costs transfer-costs--no-spacing">
                                    <div className={transferCostsItemClasses(this.props.transfercosts.chosenRecord.transferCost.totalPayout)}>
                                        <div className="transfer-costs__content">
                                            <div className="transfer-costs__state"></div>
                                            <div className="title title--h4">
                                                {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                    Math.round(parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalPayout))
                                                )}
                                            </div>
                                            <div className="caption">
                                                {Intl.DateTimeFormat('se-sv').format(new Date(this.props.transfercosts.chosenRecord.transferCost.createdDate))}
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        disabled={this.props.transfercosts.fileLoading}
                                        className={
                                            this.props.transfercosts.fileLoading
                                                ? 'button button--function button--disabled button--loading'
                                                : 'button button--function'
                                        }
                                        onClick={this.findAndGetFile.bind(this)}
                                    >
                                        <span>Ladda ner PDF</span>
                                        {this.props.transfercosts.fileLoading ? <Spinner /> : <Icon name="download" />}
                                    </button>
                                    <button
                                        disabled={this.props.transfercosts.fileLoading}
                                        className={
                                            this.props.transfercosts.fileLoading
                                                ? 'button button--function button--disabled button--loading'
                                                : 'button button--function'
                                        }
                                        style={{ marginLeft: '1%' }}
                                        onClick={this.findAndGetSieFile.bind(this)}
                                    >
                                        <span>Ladda ner SIE</span>
                                        {this.props.transfercosts.fileLoading ? <Spinner /> : <Icon name="download" />}
                                    </button>
                                    {this.props.transfercosts.fileError ? <p>Ett fel inträffade när filen skulle hämtas</p> : null}
                                </div>
                                <div className="content__section">
                                    <div className="content__section-item">
                                        <h2 className="title title--h3">Vad är en avräkning?</h2>
                                        <p className="paragraph">
                                            Avräkning är ett annat namn för kvitto. Om ni skulle behöva ett kvitto på en försäljning är det avräkningen ni kan
                                            ladda hem eller titta närmare på för att se detaljer. En avräkning behandlar allt från depositioner till moms, och
                                            är till för att vi ska kunna se detaljer för en specifik utbetalning som har gjorts.
                                        </p>
                                    </div>
                                    <div className="content__section-item">
                                        <div className="receipt receipt--small">
                                            <div className="receipt__wrapper receipt__wrapper--spacer-top">
                                                <h3 className="title title--h3">Summering</h3>
                                                {parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalFactoredInvoiceAmount) ? (
                                                    <div className="receipt__row">
                                                        <div className="receipt__key">Totalt fakturabelopp</div>
                                                        <div className="receipt__value receipt__value--large">
                                                            {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                                parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalFactoredInvoiceAmount)
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalDepOut) ? (
                                                    <div className="receipt__row">
                                                        <div className="receipt__key">Utbet dep</div>
                                                        <div className="receipt__value receipt__value--large">
                                                            {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                                parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalDepOut)
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalOther) ? (
                                                    <div className="receipt__row">
                                                        <div className="receipt__key">Övrigt</div>
                                                        <div className="receipt__value receipt__value--large">
                                                            {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                                parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalOther)
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalRegressAmount) ? (
                                                    <div className="receipt__row">
                                                        <div className="receipt__key">Regress</div>
                                                        <div className="receipt__value receipt__value--large">
                                                            {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                                parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalRegressAmount)
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : null}

                                                <div className="receipt__row">
                                                    <div className="receipt__key">Provision</div>
                                                    <div className="receipt__value">
                                                        −
                                                        {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                            parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalProvision)
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="receipt__row">
                                                    <div className="receipt__key">Avgift</div>
                                                    <div className="receipt__value">
                                                        −
                                                        {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                            parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalFee)
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="receipt__row">
                                                    <div className="receipt__key">Avdrag dep</div>
                                                    <div className="receipt__value">
                                                        −
                                                        {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                            parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalDepIn)
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="receipt__row">
                                                    <div className="receipt__key">Moms</div>
                                                    <div className="receipt__value">
                                                        −
                                                        {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                            parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalTax)
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="receipt__row receipt__row--summary">
                                                    <div className="receipt__key">Utbetalas</div>
                                                    <div className="receipt__value">
                                                        {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                            Math.round(parseFloat(this.props.transfercosts.chosenRecord.transferCost.totalPayout))
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                ) : (
                    <div className="content__wrapper content__wrapper--stuffing">
                        <Icon name="clock" extraClasses="icon--huge" />
                        <h1 className="title title--h1">Vad är en avräkning?</h1>
                        <p className="paragraph">
                            Avräkning är ett annat namn för kvitto. Om ni skulle behöva ett kvitto på en försäljning är det avräkningen ni kan ladda hem eller
                            titta närmare på för att se detaljer. En avräkning behandlar allt från depositioner till moms, och är till för att vi ska kunna se
                            detaljer för en specifik utbetalning som har gjorts.
                        </p>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        transfercosts: state.transfercosts,
    };
};

HistoryTransfercostsDetailsInner = connect(mapStateToProps, {
    transfercostsRecordDetailsDownloadDoc,
    transfercostsRecordDetailsDownloadSieFile,
})(HistoryTransfercostsDetailsInner);

export default HistoryTransfercostsDetailsInner;
