import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/sv';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import HistoryTransfercostsDetails from './HistoryTransfercostsDetails';
import Spinner from '../../../global/Spinner';
import Icon from '../../../global/Icon';

import { transfercostsFetchList, transfercostsRecordDetailsFetch, modalTrigger } from '../../../actions';

class HistoryTransfercostsFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chosenMonth: this.getMonthRange(new Date()),
        };

        this.props.transfercostsFetchList(this.state.chosenMonth);

        this.doFilterSubject = new Subject().pipe(debounceTime(300));
        this.subscriber = [];
        this.subscriber.push(
            this.doFilterSubject.subscribe(() => {
                this.props.transfercostsFetchList(this.state.chosenMonth);
            })
        );
    }
    componentDidUpdate(prevProps, prevState) {
        if (!this.props.transfercosts.chosenRecord && prevProps.transfercosts.chosenRecord) {
            this.setState((state) => {
                state.chosenId = undefined;

                return state;
            });
        }
        if (JSON.stringify(prevProps.organisation) === JSON.stringify(this.props.organisation)) {
            return;
        }
        if (!this.props.transfercosts.filter.from && !this.props.transfercosts.filter.to) {
            this.setState(
                (state) => {
                    state.chosenMonth = this.getMonthRange(new Date());

                    return state;
                },
                () => {
                    // Trigger filter subject
                    this.doFilterSubject.next();
                }
            );
        }
    }
    componentWillUnmount() {
        this.subscriber.forEach((sub) => {
            sub.unsubscribe();
        });
    }
    previousMonth() {
        this.setState(
            (state) => {
                let date = moment(this.state.chosenMonth.from).subtract(1, 'months').toDate();
                state.chosenMonth = this.getMonthRange(date);

                return date;
            },
            () => {
                // Trigger filter subject
                this.doFilterSubject.next();
            }
        );
    }
    nextMonth() {
        this.setState(
            (state) => {
                let date = moment(this.state.chosenMonth.from).add(1, 'months').toDate();
                state.chosenMonth = this.getMonthRange(date);

                return date;
            },
            () => {
                // Trigger filter subject
                this.doFilterSubject.next();
            }
        );
    }
    getMonthRange(date) {
        return {
            from: moment(date).startOf('month').toDate(),
            to: moment(date).endOf('month').toDate(),
            monthLabel: moment(date).format('MMMM YYYY'),
        };
    }
    chooseRecord(id, event) {
        event.preventDefault();
        event.target.blur();

        this.setState((state) => {
            state.chosenId = id;
            return state;
        });
        this.props.transfercostsRecordDetailsFetch(id);

        if (window.matchMedia('(max-width: 1080px)').matches) {
            this.openTransferCostAsModal();
        }
    }
    openTransferCostAsModal() {
        this.props.modalTrigger(true, () => <HistoryTransfercostsDetails inModal={true} />);
    }
    render() {
        return (
            <React.Fragment>
                <div className={this.props.transfercosts.loading ? 'filter filter--loading' : 'filter'}>
                    <div className="filter__greeting">
                        <h2 className="title title--h2">Avräkningar</h2>
                    </div>
                    <div className="filter__month-toggler">
                        <button
                            className={
                                moment('2020-01-01').isSame(this.state.chosenMonth.from) ? 'button button--icon button--disabled' : 'button button--icon'
                            }
                            onClick={this.previousMonth.bind(this)}
                            disabled={moment('2020-01-01').isSame(this.state.chosenMonth.from)}
                        >
                            <Icon name="arrow-left-white" />
                            <span className="sr">Föregående månad</span>
                        </button>
                        <div className="title title--h4">{this.state.chosenMonth.monthLabel}</div>
                        <button
                            className={
                                moment(this.state.chosenMonth.to).isSame(new Date(), 'month') ? 'button button--icon button--disabled' : 'button button--icon'
                            }
                            onClick={this.nextMonth.bind(this)}
                            disabled={moment(this.state.chosenMonth.to).isSame(new Date(), 'month')}
                        >
                            <Icon name="arrow-right-white" />
                            <span className="sr">Nästa månad</span>
                        </button>
                    </div>
                    <div className="transfer-costs">
                        {this.props.transfercosts.loading ? (
                            <Spinner />
                        ) : (
                            <React.Fragment>
                                {this.props.transfercosts.records.map((record, index) => {
                                    const buttonClasses = (amount) => {
                                        let classes = 'transfer-costs__item';

                                        if (amount < 0) {
                                            classes += ' transfer-costs__item--warning';
                                        } else if (amount === 0) {
                                            classes += ' transfer-costs__item--wait';
                                        }

                                        if (this.state.chosenId === record.id) {
                                            classes += ' transfer-costs__item--active';
                                        }

                                        return classes;
                                    };
                                    return (
                                        <button className={buttonClasses(record.totalPayout)} key={index} onClick={this.chooseRecord.bind(this, record.id)}>
                                            <div className="transfer-costs__content">
                                                <div className="transfer-costs__state"></div>
                                                <div className="title title--h4">
                                                    {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
                                                        Math.round(parseFloat(record.totalPayout))
                                                    )}
                                                </div>
                                                <div className="caption">{Intl.DateTimeFormat('se-sv').format(new Date(record.createdDate))}</div>
                                            </div>
                                            <Icon name="right" />
                                        </button>
                                    );
                                })}
                                {this.props.transfercosts.records.length === 0 ? (
                                    <div className="transfer-costs__item">
                                        <p className="caption">Hitade inga avräkningar för perioden</p>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        transfercosts: state.transfercosts,
        organisation: state.organisation,
    };
};

HistoryTransfercostsFilter = connect(mapStateToProps, {
    transfercostsFetchList,
    transfercostsRecordDetailsFetch,
    modalTrigger,
})(HistoryTransfercostsFilter);

export default HistoryTransfercostsFilter;
