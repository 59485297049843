import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';

import Icon from '../../../global/Icon';
import ImportInvoiceItemDetails from './ImportInvoiceItemDetails';

import { importInvoiceSetService, importInvoiceSetRemove } from '../../../actions';

class ImportInvoiceItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            detailsValid: true,
        };

        this.handleServiceChange = this.handleServiceChange.bind(this);
        this.handleImportClicked = this.handleImportClicked.bind(this);
        this.onDetailsValidationChanged = this.onDetailsValidationChanged.bind(this);
    }

    handleInvoiceClick(invoice, event) {
        event.preventDefault();

        if (this.state.isOpen) {
            if (this.state.detailsValid === false) {
                return;
            }
        }

        this.setState((state) => {
            state.isOpen = !state.isOpen;
            return state;
        });
    }

    handleServiceChange(checked, event) {
        this.stopPropagation(event);
        this.props.importInvoiceSetService(this.props.invoice, this.props.invoice.invoiceNumber);
    }

    handleImportClicked(event) {
        this.stopPropagation(event);
        this.props.importInvoiceSetRemove(this.props.invoice, this.props.invoice.invoiceNumber);
    }

    stopPropagation(event) {
        event.stopPropagation();
    }

    onDetailsValidationChanged(isDetailsValid) {
        this.setState((state) => {
            state.detailsValid = isDetailsValid;
            return state;
        });

        this.props.onValidationChanged(this.props.index, isDetailsValid);
    }

    render() {
        let classNames = this.state.isOpen ? 'imports__item imports__item--active' : 'imports__item';
        const invoice = this.props.invoice;
        return (
            <li className={classNames} key={this.props.index}>
                <div onClick={this.handleInvoiceClick.bind(this, invoice)} className="imports__item-row">
                    <div className="imports__panel">
                        <p className="paragraph">
                            <small>Markera</small>
                        </p>
                        <div className="paragraph filter__states">
                            <button
                                className={
                                    invoice.import
                                        ? 'button button--chip button--approve button--icon-only'
                                        : 'button button--chip button--approve button--icon-only button--disabled'
                                }
                                onClick={this.handleImportClicked}
                            >
                                <Icon name="check" />
                            </button>
                        </div>
                    </div>
                    <div className="imports__panel">
                        <p className="paragraph">
                            <small>Fakturamottagare</small>
                        </p>
                        <p className="paragraph">
                            <strong>{invoice.recipientName}</strong>
                        </p>
                    </div>
                    <div className="imports__panel">
                        <p className="paragraph">
                            <small>Fakturabelopp</small>
                        </p>
                        <p className="paragraph">
                            <strong>{Intl.NumberFormat('sv-se', { style: 'currency', currency: 'SEK' }).format(invoice.invoiceAmount)}</strong>
                        </p>
                    </div>
                    <div className="imports__panel">
                        <p className="paragraph">
                            <small>Förfallodatum</small>
                        </p>
                        <p className="paragraph">
                            <strong>{Intl.DateTimeFormat('se-sv').format(new Date(invoice.invoiceDueDate))}</strong>
                        </p>
                    </div>
                    <div className="imports__panel">
                        <p className="paragraph">
                            <small>Fakturanummer</small>
                        </p>
                        <p className="paragraph">
                            <strong>{invoice.invoiceNumber}</strong>
                        </p>
                    </div>
                    <div className="imports__panel">
                        <p className="paragraph">
                            <small>{invoice.sell ? 'Finansieras' : 'Administreras'}</small>
                        </p>
                        <div className="paragraph imports__item-row--switch" onClick={this.stopPropagation}>
                            <Switch
                                onChange={this.handleServiceChange}
                                checked={invoice.sell}
                                disabled={invoice.isCreditInvoice}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={20}
                                height={12}
                                width={40}
                                onColor="#179e5a"
                                offColor="#3268ba"
                            />
                        </div>
                    </div>
                    <div className="imports__item-arrow">
                        <div className="imports__arrow-img">
                            <Icon name="down" />
                        </div>
                    </div>
                </div>
                {this.state.isOpen ? (
                    <ImportInvoiceItemDetails invoice={this.props.invoice} index={this.props.index} onValidationChanged={this.onDetailsValidationChanged} />
                ) : null}
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        import: state.import,
    };
};

ImportInvoiceItem = connect(mapStateToProps, {
    importInvoiceSetService,
    importInvoiceSetRemove,
})(ImportInvoiceItem);

export default ImportInvoiceItem;
