import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadRemoveAttachment } from '../../../actions';
import Icon from '../../../global/Icon';

class AttachmentUploader extends Component {
    constructor(props) {
        super(props);
        this.attachmentUploader = React.createRef();
        this.state = { errorText: '', loading: false };
    }

    overrideEventDefaults(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    onSelectFileClick() {
        this.attachmentUploader && this.attachmentUploader.click();
    }

    onFileChanged(event) {
        if (event.target.files && event.target.files.length > 10) {
            this.setState({ error: true, errorText: 'Max 10 filer i taget' });
            return;
        }
        if (event.target.files && event.target.files) {
            this.setState({ file: event.target.files, error: false, errorText: '' }, this.newFile);
        }
    }
    newFile() {
        if (!Array.from(this.state.file).every((file) => (file.type === 'application/pdf' ? true : false))) {
            this.setState({ file: this.state.file, error: true, errorText: 'Vänligen ladda endast upp fakturor i pdf format' });
        } else if (this.state.file) {
            this.props.fileListUpdate(this.state.file);
        }
    }
    removeAttachment(filename) {
        const fileObj = this.props.upload.files[this.props.index];

        this.props.uploadRemoveAttachment({ fileName: filename, interpretationId: fileObj.interpretationId });
    }

    render() {
        return (
            <div>
                <span className="files attachment-header">Bilagor</span>
                <div className="files attachment-icon">
                    <Icon name="attachment" />
                </div>

                <ul className="files attachment-list">
                    {this.props.upload.files[this.props.index].attachments &&
                        this.props.upload.files[this.props.index].attachments.map((key, i) => (
                            <React.Fragment key={i}>
                                <li>
                                    <span>{key}</span>
                                    <div className="attachment-icon">
                                        <button
                                            aria-label="Ta bort bilaga"
                                            className="trash attachment-button button--chip"
                                            onClick={this.removeAttachment.bind(this, key)}
                                        >
                                            <Icon name="trash" />
                                        </button>
                                    </div>
                                </li>
                            </React.Fragment>
                        ))}
                </ul>
                <button className="button button--function upload-file__button button--chip button--warning" onClick={this.onSelectFileClick.bind(this)}>
                    Ladda upp bilaga
                </button>
                <input
                    type="file"
                    name="upload-file-mini"
                    id="upload-file-mini"
                    className="upload-file__input"
                    onChange={this.onFileChanged.bind(this)}
                    ref={(el) => (this.attachmentUploader = el)}
                />

                {this.props.upload.attachmentErrorText ? (
                    <div className=" upload-file__text--error caption">{this.props.upload.attachmentErrorText}</div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        upload: state.upload,
        oidc: state.oidc,
        organisation: state.organisation,
    };
};

AttachmentUploader = connect(mapStateToProps, {
    uploadRemoveAttachment,
})(AttachmentUploader);

export default AttachmentUploader;
