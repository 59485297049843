import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { historyInit, historyPaginate, historyFilter, historyUpdate, historyClear } from '../../actions';

import Icon from '../../global/Icon';
import Spinner from '../../global/Spinner';
import InvoiceState from '../../global/InvoiceState';

import HistoryInvoiceFilter from './components/HistoryInvoiceFilter';

import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Motion, spring } from 'react-motion';

class HistoryInvoicesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterExpanded: false,
        };

        this.searchInput = React.createRef();
        this.sideMenu = React.createRef();

        if ((props.history.invoices.length === 0 || typeof props.history.scrollPosition !== 'number') && !props.location?.search) {
            props.historyInit();
        }
        // Create observers for scroll and resize events
        const scrollObs = merge(fromEvent(window, 'wheel', { passive: true }), fromEvent(window, 'scrollEnd', { passive: true }));
        const resizeObs = fromEvent(window, 'resize', { passive: true });
        const scroll = fromEvent(window, 'touchmove');
        const mergedObs = merge(resizeObs, scrollObs, scroll).pipe(
            debounceTime(25),
            map((d) => {
                return document.body.scrollTop;
            }),
            distinctUntilChanged((prev, curr) => {
                return prev === curr;
            })
        );
        // Triggered when scroll or window size change
        this.scrollSub = mergedObs.subscribe((event) => {
            // If we reach correct scroll distance (400 pixels from bottom) then trigger pagination automatically
            const startFetchOffset = 400;
            const reachedTriggerHeight = document.body.scrollTop + document.body.clientHeight + startFetchOffset >= document.body.scrollHeight;
            const allIsLoaded = this.props.history.invoices.length === this.props.history.totalHits;
            if (!allIsLoaded && !this.props.history.loading && reachedTriggerHeight) {
                this.props.historyPaginate();
            }
        });
    }
    componentDidMount() {
        // If there is a search query, update input field to show it
        if (this.props.history.query) {
            this.searchInput.current.value = this.props.history.query;
        }
        // Setup listeners for when user searches with query
        this.searchTermSub = fromEvent(this.searchInput.current, 'input')
            .pipe(debounceTime(150))
            .subscribe((event) => {
                const query = this.searchInput.current.value;
                if (this.props.history.query !== query) {
                    this.triggerFilter();
                }
            });
        // If an old scroll position has been saved set scroll position to that.
        if (this.props.history.scrollPosition > 0) {
            document.body.scrollTop = this.props.history.scrollPosition;
        }
    }
    componentWillUnmount() {
        if (this.scrollSub) {
            this.scrollSub.unsubscribe();
        }
        const nextRoutePath = decodeURI(window.location.pathname);
        const currentRoutePathname = this.props.location.pathname;
        // If user has navigated somewhere that isn't this route or a child route
        if (nextRoutePath.indexOf(currentRoutePathname) === -1) {
            document.body.scrollTop = 0; // reset scroll to avoid animation glitches
            this.props.historyClear();
        } else {
            this.props.historyUpdate({ scrollPosition: document.body.scrollTop });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.organisation && prevProps.organisation && this.props.organisation.name !== prevProps.organisation.name) {
            this.props.historyInit();
        }
    }
    triggerFilter() {
        const query = this.searchInput.current.value;
        const filter = { ...this.props.history.filter };
        this.props.historyFilter(query, filter);
    }
    handleFilterToggler(event) {
        event.preventDefault();
        document.body.scrollTo({ top: 0, behavior: 'smooth' });
        this.setState((state) => {
            state.filterExpanded = !this.state.filterExpanded;
            return state;
        });
    }
    render() {
        return (
            <div className="content__body history">
                <div className="content__menu content__menu--filter content__menu--sticky" ref={this.sideMenu}>
                    <Motion
                        defaultStyle={{
                            opacity: 0,
                            scale: 0.95,
                        }}
                        style={{
                            opacity: spring(1),
                            scale: spring(1, { stiffness: 300, damping: 18 }),
                        }}
                    >
                        {(interpolatingStyle) => (
                            <React.Fragment>
                                <div
                                    style={
                                        interpolatingStyle.scale !== 1
                                            ? {
                                                  opacity: interpolatingStyle.opacity,
                                                  transform: `scale(${interpolatingStyle.scale})`,
                                              }
                                            : {
                                                  opacity: interpolatingStyle.opacity,
                                              }
                                    }
                                    className={this.state.filterExpanded ? 'history__filter history__filter--active' : 'history__filter'}
                                >
                                    <HistoryInvoiceFilter location={this.props.location} />
                                </div>
                                <button
                                    className={
                                        this.state.filterExpanded ? 'history__filter-toggler history__filter-toggler--active' : 'history__filter-toggler'
                                    }
                                    onClick={this.handleFilterToggler.bind(this)}
                                >
                                    {!this.state.filterExpanded ? 'Klicka för att visa filter' : 'Stäng'} <Icon name="down" />
                                </button>
                            </React.Fragment>
                        )}
                    </Motion>
                </div>
                <div className="content__main content__main--stuffing">
                    <div className="content__wrapper">
                        <h1 className="title title--h1">Historik</h1>
                        <div className="history__search">
                            <div className="input">
                                <input
                                    aria-label="Sökterm"
                                    name="search"
                                    ref={this.searchInput}
                                    type="search"
                                    placeholder="Sök efter fakturanummer, företagsnamn, eller organsiationsnummer"
                                    className="input__field"
                                />
                            </div>
                            <button className="button button--function">Sök</button>
                        </div>
                        {this.props.history.highlights && this.props.history.highlights.length > 0 ? (
                            <React.Fragment>
                                <h2 className="title title--h3">Highlights</h2>
                                <div className="invoice-list">
                                    <ul className="invoice-list__list">
                                        {this.props.history.highlights.map((invoice, index) => (
                                            <li className="invoice-list__item" key={index}>
                                                <div className="invoice-list__link invoice-list__link--non-clickable">
                                                    <div className="invoice-list__panel invoice-list__panel--icon">
                                                        <Icon name="wait" />
                                                    </div>
                                                    <div className="invoice-list__item-content">
                                                        <div className="invoice-list__panel">
                                                            <React.Fragment>
                                                                <p className="paragraph">
                                                                    <strong
                                                                        dangerouslySetInnerHTML={(() => {
                                                                            let dangerousHtml = {
                                                                                __html: invoice.recipient,
                                                                            };

                                                                            return dangerousHtml;
                                                                        })()}
                                                                    ></strong>
                                                                </p>
                                                                <p className="caption">
                                                                    <small>{Intl.DateTimeFormat('se-sv').format(new Date(invoice.created))}</small>
                                                                </p>
                                                            </React.Fragment>
                                                        </div>
                                                        <div className="invoice-list__panel">
                                                            <p className="paragraph">
                                                                <small>Fakturabelopp</small>
                                                            </p>
                                                            <p className="paragraph">
                                                                <strong>
                                                                    {Intl.NumberFormat('sv-se', {
                                                                        style: 'currency',
                                                                        currency: 'SEK',
                                                                    }).format(invoice.invoiceAmount)}
                                                                </strong>
                                                            </p>
                                                        </div>
                                                        <div className="invoice-list__panel">
                                                            <p className="paragraph">
                                                                <small>Fakturanummer</small>
                                                            </p>
                                                            <p className="paragraph">
                                                                <strong>{invoice.invoiceNumber}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </React.Fragment>
                        ) : null}
                        <h2 className="title title--h3">Tidigare försäljning</h2>
                        <Motion
                            defaultStyle={{ opacity: 0, translateY: 25 }}
                            style={{ opacity: spring(1), translateY: spring(0, { stiffness: 250, damping: 20 }) }}
                        >
                            {(interpolatingStyle) => (
                                <div className="invoice-list">
                                    <ul className="invoice-list__list">
                                        {this.props.history.invoices.map((invoice, index) => (
                                            <li className="invoice-list__item" key={index}>
                                                <NavLink to={'/historik/fakturor/' + invoice.id} className="invoice-list__link">
                                                    <div className="invoice-list__item-content">
                                                        <div className="invoice-list__panel">
                                                            <React.Fragment>
                                                                <p className="paragraph">
                                                                    <strong
                                                                        dangerouslySetInnerHTML={(() => {
                                                                            let dangerousHtml = {
                                                                                __html: invoice.recipient,
                                                                            };

                                                                            return dangerousHtml;
                                                                        })()}
                                                                    ></strong>
                                                                </p>
                                                                <p className="caption">
                                                                    <small>{Intl.DateTimeFormat('se-sv').format(new Date(invoice.created))}</small>
                                                                </p>
                                                            </React.Fragment>
                                                        </div>
                                                        <div className="invoice-list__panel invoice-list__panel--state">
                                                            <InvoiceState state={invoice.latestState} />
                                                        </div>
                                                        <div className="invoice-list__panel">
                                                            <p className="paragraph">
                                                                <small>Fakturabelopp</small>
                                                            </p>
                                                            <p className="paragraph">
                                                                <strong>
                                                                    {Intl.NumberFormat('sv-se', {
                                                                        style: 'currency',
                                                                        currency: 'SEK',
                                                                    }).format(invoice.invoiceAmount)}
                                                                </strong>
                                                            </p>
                                                        </div>
                                                        <div className="invoice-list__panel">
                                                            <p className="paragraph">
                                                                <small>Återstående fakturabelopp</small>
                                                            </p>
                                                            <p className="paragraph">
                                                                <strong>
                                                                    {Intl.NumberFormat('sv-se', {
                                                                        style: 'currency',
                                                                        currency: 'SEK',
                                                                    }).format(invoice.remainingAmount)}
                                                                </strong>
                                                            </p>
                                                        </div>
                                                        <div className="invoice-list__panel">
                                                            <p className="paragraph">
                                                                <small>Fakturanummer</small>
                                                            </p>
                                                            <p className="paragraph">
                                                                <strong>{invoice.invoiceNumber}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="invoice-list__panel invoice-list__panel--icon">
                                                        <Icon name="right" />
                                                    </div>
                                                </NavLink>
                                            </li>
                                        ))}
                                        {this.props.history.loading || this.props.history.invoices.length !== this.props.history.totalHits ? (
                                            <li className="invoice-list__item invoice-list__item--loader">
                                                <Spinner />
                                            </li>
                                        ) : null}
                                        {!this.props.history.loading && this.props.history.invoices && this.props.history.invoices.length === 0 ? (
                                            <li className="invoice-list__item invoice-list__item--loader">
                                                <p className="caption">Hittade inga fakturor</p>
                                            </li>
                                        ) : null}
                                    </ul>
                                    {this.props.history.invoices && this.props.history.invoices.length > 0 ? (
                                        <div className="invoice-list__total-count">
                                            Visar {this.props.history.invoices.length} av {this.props.history.totalHits}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </Motion>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        messages: state.messages,
        router: state.router,
        history: state.history,
        organisation: state.organisation,
    };
};

HistoryInvoicesPage = connect(mapStateToProps, {
    push,
    historyInit,
    historyPaginate,
    historyFilter,
    historyUpdate,
    historyClear,
})(HistoryInvoicesPage);

export default HistoryInvoicesPage;
