import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Motion, spring } from 'react-motion';
import { NavLink } from 'react-router-dom';
import Icon from '../../global/Icon';
import Spinner from '../../global/Spinner';
import ImportInvoiceItem from './components/ImportInvoiceItem';
import InformationModal from '../../global/modals/InformationModal';

import { modalTrigger, importGetNonImported, importInvoicesTransfer } from '../../actions';

class ImportInvoicePage extends Component {
    constructor(props) {
        super(props);

        this.importInvoices = React.createRef();
        this.sideMenu = React.createRef();

        this.invoiceValidationList = [];

        this.state = {
            importExpanded: false,
            allInvoicesValid: true,
            confirmDelete: false,
        };
    }
    componentDidMount() {
        this.props.importGetNonImported();
    }
    componentDidUpdate(prevProps) {
        // open info dialog if we successfully transfered invoices
        if (this.props.import && this.props.import.successfullyTransferred) {
            if (prevProps.import) {
                if (this.props.import.successfullyTransferred !== prevProps.import.successfullyTransferred) {
                    if (this.props.import.successfullyTransferred && this.props.import.isDeletion) {
                        this.openInvoicesRemovedInformation();
                    } else {
                        this.openInvoicesTransferredInformation();
                    }
                }
            } else {
                this.openInvoicesTransferredInformation();
            }
        }
    }
    importInvoiceListUpdate(invoices) {
        Array.from(invoices).forEach((invoice) => {
            this.props.import({ invoice: invoice });
            this.setState((state) => {
                state.importExpanded = false;
                return state;
            });
        });
    }

    handleUploadToggler(event) {
        event.preventDefault();
        document.body.scrollTo({ top: 0, behavior: 'smooth' });
        this.setState((state) => {
            state.importExpanded = !state.importExpanded;

            return state;
        });
    }

    sendInvoices(event) {
        event.preventDefault();

        this.importInvoices.current.dispatchEvent(new Event('blur'));
        const selectedInvoices = this.props.import.invoices.filter((invoice) => {
            return invoice.import === true;
        });

        if (selectedInvoices.length > 0) {
            this.props.importInvoicesTransfer(selectedInvoices);
        }
        // todo else notify ui... same in import flow
    }

    confirmDelete() {
        this.setState((state) => {
            state.confirmDelete = false;
            return state;
        });
    }

    removeInvoices(event) {
        event.preventDefault();
        if (!this.state.confirmDelete) {
            this.setState((state) => {
                state.confirmDelete = true;
                return state;
            });
            return;
        }

        this.importInvoices.current.dispatchEvent(new Event('blur'));
        let selectedInvoices = this.props.import.invoices.filter((invoice) => {
            return invoice.import === true;
        });

        selectedInvoices.forEach((invoice) => {
            invoice.sell = false;
            invoice.import = false;
        });

        if (selectedInvoices.length > 0) {
            this.props.importInvoicesTransfer(selectedInvoices);
            this.setState((state) => {
                state.allInvoicesValid = true;
                state.confirmDelete = false;
                return state;
            });
        }
        // todo else notify ui... same in import flow
    }

    onInvoiceValidationChanged(index, isValid) {
        this.invoiceValidationList[index] = isValid;

        this.setState((state) => {
            state.allInvoicesValid = this.invoiceValidationList.every((v) => v !== false);
            return state;
        });
    }
    openInvoicesTransferredInformation() {
        this.props.modalTrigger(true, () => (
            <InformationModal
                header="Dina fakturor har skickats till Factoringgruppen"
                body="En handläggare kommer börja arbeta med dessa så fort som möjligt. En avräkning skickas per e-post så fort de är färdigbehandlade. Vid frågor ring 031-719 99 20 eller maila kund@fg.se"
            />
        ));
    }
    openInvoicesRemovedInformation() {
        this.props.modalTrigger(true, () => (
            <InformationModal header="Dina fakturor har tagits bort" body="Fakturorna har tagits bort. Vid frågor ring 031-719 99 20 eller maila kund@fg.se" />
        ));
    }
    render() {
        return (
            <div className="content__body imports">
                <div className="content__main">
                    <div className="content__wrapper content__wrapper--stuffing" ref={this.importInvoices}>
                        {this.props.organisation.settings.importFeature !== true ? (
                            <div className="imports__empty">
                                <React.Fragment>
                                    <Icon name="empty-list" />
                                    <h2 className="title title--h2">Saknar koppling till ert affärssystem</h2>
                                    <p className="paragraph">Vill du att vi hämtar dina nya fakturor automatiskt?</p>
                                    <NavLink to={'/inställningar/integrationer/'} className="button button--function">
                                        Gå till Inställningar och aktivera!
                                    </NavLink>
                                    <button className="button button--function" onClick={this.handleUploadToggler.bind(this)}>
                                        {!this.state.importExpanded ? 'Gå vidare' : 'Stäng hämtning'}
                                    </button>
                                </React.Fragment>
                            </div>
                        ) : this.props.import.loading ? (
                            <Spinner />
                        ) : (
                            <React.Fragment>
                                <h1 className="title title--h1">Fakturor</h1>
                                {this.props.import && this.props.import.invoices && this.props.import.invoices.length > 0 ? (
                                    <ul className="files">
                                        {this.props.import.invoices.map((invoice, index) => {
                                            return (
                                                <ImportInvoiceItem
                                                    key={`${index}`}
                                                    invoice={invoice}
                                                    index={index}
                                                    onValidationChanged={this.onInvoiceValidationChanged.bind(this)}
                                                />
                                            );
                                        })}
                                    </ul>
                                ) : (
                                    <div className="imports__empty">
                                        <React.Fragment>
                                            <Icon name="empty-list" />
                                            <h2 className="title title--h2">Alldeles tomt!</h2>
                                            <p className="paragraph">Hämtning av bokförda fakturor sker automatiskt.</p>
                                            <button className="button button--function" onClick={this.handleUploadToggler.bind(this)}>
                                                {!this.state.importExpanded ? 'Gå vidare' : 'Stäng hämtning'}
                                            </button>
                                        </React.Fragment>
                                    </div>
                                )}
                                {this.props.import && this.props.import.invoices && this.props.import.invoices.length > 0 ? (
                                    <React.Fragment>
                                        {this.props.import.error && this.props.import.error.type === 'HANDLEIMPORTINVOICETERROR' ? (
                                            <Motion
                                                defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }}
                                                style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}
                                            >
                                                {(value) => (
                                                    <div
                                                        className="imports__async-status imports__async-status--error"
                                                        style={{ opacity: value.opacity, top: value.top, transform: `scale(${value.scale})` }}
                                                    >
                                                        {this.props.import.error.message}
                                                    </div>
                                                )}
                                            </Motion>
                                        ) : null}
                                        <div className="content__wrapper--reversed submit-imports">
                                            <button
                                                className={this.state.allInvoicesValid ? 'button' : 'button button--disabled'}
                                                onClick={this.sendInvoices.bind(this)}
                                                disabled={!this.state.allInvoicesValid}
                                            >
                                                Överlåt fakturor
                                            </button>
                                            {this.state.confirmDelete ? (
                                                <div className="confirm-action confirm-action--right confirm-action--lesstop">
                                                    <p className="paragraph">Är du säker på att du vill ta bort markerade fakturor?</p>
                                                    <button className="close close--alt" onClick={this.confirmDelete.bind(this)}>
                                                        <span className="close__icon"></span>
                                                    </button>
                                                    <button className="button button--warning button--chip" onClick={this.removeInvoices.bind(this)}>
                                                        Ta bort
                                                    </button>
                                                </div>
                                            ) : null}
                                            <button className="button button--marginleft button--warning" onClick={this.removeInvoices.bind(this)}>
                                                Ta bort fakturor
                                            </button>
                                        </div>
                                        <div className="content__wrapper">
                                            {!this.state.allInvoicesValid ? (
                                                <React.Fragment>
                                                    <Motion
                                                        defaultStyle={{ opacity: 0, scale: 0.9, top: 10 }}
                                                        style={{ opacity: spring(1), scale: spring(1), top: spring(0) }}
                                                    >
                                                        {(value) => (
                                                            <div
                                                                className="imports__async-status imports__async-status--error"
                                                                style={{ opacity: value.opacity, top: value.top, transform: `scale(${value.scale})` }}
                                                            >
                                                                Det finns felaktiga email-adresser angivna.
                                                            </div>
                                                        )}
                                                    </Motion>
                                                </React.Fragment>
                                            ) : null}
                                        </div>
                                    </React.Fragment>
                                ) : null}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        import: state.import,
        organisation: state.organisation,
    };
};

ImportInvoicePage = connect(mapStateToProps, {
    modalTrigger,
    importGetNonImported,
    importInvoicesTransfer,
})(ImportInvoicePage);

export default ImportInvoicePage;
