export const NOTIFICATION_ROUTE_FETCH = 'NOTIFICATION_ROUTE_FETCH';
export const NOTIFICATION_ROUTE_UPDATE = 'NOTIFICATION_ROUTE_UPDATE';

export const notificationRouteFetch = (messageId) => ({
    type: NOTIFICATION_ROUTE_FETCH,
    payload: messageId,
});

export const notificationRouteUpdate = (state) => ({
    type: NOTIFICATION_ROUTE_UPDATE,
    payload: state,
});
