import React, { Component } from 'react';
import Nouislider from 'nouislider-react';

// The old classic range slider that most projects tend to end up using in filters.
// It's based on an external component but with a wrapper so we can create some custom stuff.
class RangeSlider extends Component {
    constructor(props) {
        super(props);
        this.slider = null;
        this.state = {
            min: this.props.startMin,
            max: this.props.startMax,
        };
        if (this.props.resetSubject) {
            this.subscriber = this.props.resetSubject.subscribe(() => {
                this.setState(
                    {
                        min: this.props.startMin,
                        max: this.props.startMax,
                    },
                    () => {
                        this.slider.noUiSlider.set([this.state.min, this.state.max]);
                    }
                );
            });
        }
    }
    componentWillUnmount() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }
    onRangeChange(render, handleIndex, value, un, percent) {
        if (this.props.callBack) {
            this.props.callBack(this.state);
        }
    }
    onRangeUpdate(render, handleIndex, value, un, percent) {
        this.setState((state) => {
            state = {
                ...state,
                render: render,
                handleIndex: handleIndex,
                value: value,
                un: un,
                percent: percent,
                min: Math.round(value[0]),
                max: Math.round(value[1]),
            };
            return state;
        });
    }
    onInputUpdate(event) {
        let input = event.target;
        this.setState(
            (state) => {
                state[input.name] = input.value;

                return state;
            },
            () => {
                if (this.slider && this.slider.noUiSlider) {
                    this.slider.noUiSlider.set([this.state.min, this.state.max]);
                }
                if (this.props.callBack) {
                    this.props.callBack(this.state);
                }
            }
        );
    }
    render() {
        let { isValid } = this.props;
        let inputClasses = () => {
            let className = 'input input--small input--full input--range';
            // console.log(this.props);
            if (typeof isValid !== 'undefined' && !isValid) {
                className += ' input--error';
            }
            return className;
        };
        return (
            <div className={inputClasses()}>
                {this.props.label ? <div className="input__label">{this.props.label}</div> : null}
                <div className="input__range-inputs">
                    <input
                        name="min"
                        type="number"
                        size="10"
                        onChange={this.onInputUpdate.bind(this)}
                        placeholder="Min"
                        value={this.state.min}
                        className="input__field"
                    />
                    <input
                        name="max"
                        type="number"
                        size="10"
                        onChange={this.onInputUpdate.bind(this)}
                        placeholder="Max"
                        value={this.state.max}
                        className="input__field"
                    />
                </div>
                <Nouislider
                    instanceRef={(instance) => {
                        if (instance && !this.slider) {
                            this.slider = instance;
                        }
                    }}
                    accessibility
                    range={{
                        min: this.props.startMin,
                        max: this.props.startMax,
                    }}
                    connect={true}
                    start={[this.props.startMin, this.props.startMax]}
                    onChange={this.onRangeChange.bind(this)}
                    onUpdate={this.onRangeUpdate.bind(this)}
                />
            </div>
        );
    }
}

export default RangeSlider;
