import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { onBackgroundMessage } from 'firebase/messaging/sw';

var firebaseConfig = {
    apiKey: 'AIzaSyBqoIpSr7yPAR2QWdh-AFNaHvm2R7VT0hk',
    authDomain: 'skynet-a96fc.firebaseapp.com',
    projectId: 'skynet-a96fc',
    storageBucket: 'skynet-a96fc.appspot.com',
    messagingSenderId: '6120000490',
    appId: '1:6120000490:web:2d6c432d7a923ce1538093',
    measurementId: 'G-VVBENERYSZ',
};

const app = initializeApp(firebaseConfig);
let messaging = null;
isSupported()
    .then((isSupport) => {
        if (isSupport) messaging = getMessaging(app);
    })
    .catch((err) => {
        console.log(err);
    });
export const getTokenRequest = () => {
    if (!messaging) {
        throw new Error('Din webbläsare eller enhet har inte stöd för notifieringar');
    }
    registerServiceWorker();
    return getToken(messaging)
        .then((currentToken) => {
            if (currentToken) {
                return currentToken;
            } else {
                throw new Error('Vi kunde inte hämta ett notifierings token, försök igen');
            }
        })
        .catch((err) => {
            throw new Error('Vi kunde inte hämta ett notifierings token, kontrollera att du tillåter notifieringar');
        });
};
function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('./firebase-messaging-sw.js')
            .then(function () {
                onMessage(messaging, (payload) => {
                    // TODO: Dispatch message to someone
                });
                onBackgroundMessage(messaging, (payload) => {
                    // We don't need to dispatch message since this is handle by os
                });
            })
            .catch(function (err) {
                // TODO: Dispatch message to someone "Service Worker registration failed"
            });
    }
}
