import { LOADING_UPDATE } from '../actions/index';

const initialState = {
    isLoading: false,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_UPDATE:
            return { ...state, ...action.payload };
        default:
            return { ...state };
    }
}
