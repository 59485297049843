export const ORGANISATION_UPDATE = 'ORGANISATION_UPDATE';
export const ORGANISATION_GET_COMMITMENTS = 'ORGANISATION_GET_COMMITMENTS';
export const ORGANISATION_CHOOSE_COMMITMENTS = 'ORGANISATION_CHOOSE_COMMITMENTS';
export const ORGANISATION_GET_TASKS = 'ORGANISATION_GET_TASKS';
export const ORGANISATION_CREATE_ONBOARDING = 'ORGANISATION_CREATE_ONBOARDING';
export const ORGANISATION_ADD_CONTACT_DETAILS = 'ORGANISATION_ADD_CONTACT_DETAILS';
export const ORGANISATION_GET_DIRECTORSHIP = 'ORGANISATION_GET_DIRECTORSHIP';
export const ORGANISATION_GET_SETTINGS = 'ORGANISATION_GET_SETTINGS';

export const organisationUpdate = (organisation) => ({
    type: ORGANISATION_UPDATE,
    payload: organisation,
});

export const organisationGetCommitments = () => ({
    type: ORGANISATION_GET_COMMITMENTS,
});

export const organisationChooseCommitment = (commitment) => ({
    type: ORGANISATION_CHOOSE_COMMITMENTS,
    payload: commitment,
});

export const organisationGetTasks = () => ({
    type: ORGANISATION_GET_TASKS,
});

export const organisationCreateOnboarding = (commitment, productType) => ({
    type: ORGANISATION_CREATE_ONBOARDING,
    payload: {
        commitment: commitment,
        productType: productType,
    },
});

export const organisationAddContactDetails = (contactName, contactEmailAddress, contactPhoneNumber) => ({
    type: ORGANISATION_ADD_CONTACT_DETAILS,
    payload: {
        contactName: contactName,
        contactEmailAddress: contactEmailAddress,
        contactPhoneNumber: contactPhoneNumber,
    },
});

export const organisationGetDirectorship = () => ({
    type: ORGANISATION_GET_DIRECTORSHIP,
});

export const organisationGetSettings = () => ({
    type: ORGANISATION_GET_SETTINGS,
});
