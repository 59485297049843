import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { push } from 'redux-first-history';
import { matchPath } from 'react-router';

import { organisationGetCommitments, organisationChooseCommitment } from '../actions';

import Icon from './Icon';
import UserSettings from './user-settings/UserSettings';
import { routes } from '../RouteSettings';
import logoImage from '../img/logo.svg';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuIsActive: false,
        };
        this.hamburger = React.createRef();
        this.navigation = React.createRef();

        if (props.oidc.user && props.organisation.list.length === 0) {
            props.organisationGetCommitments();
        }
    }
    componentDidUpdate(prevProps) {
        // If user has chosen a new commitment
        if (JSON.stringify(prevProps.organisation) !== JSON.stringify(this.props.organisation)) {
            // Find out where we are navigation wise
            let currentIndex = routes.findIndex((route) => {
                if (route.name.split('/')[1].length > 0) {
                    return this.props.router.location.pathname.indexOf(route.name.split('/')[1]) > -1;
                }
                return false;
            });
            if (currentIndex > -1 && routes[currentIndex].availableForClientType.indexOf(this.props.organisation.productType) === -1) {
                let filteredRotues = [];
                if (this.props.organisation && this.props.organisation.productType) {
                    filteredRotues = routes.filter((route) => {
                        return route.availableForClientType.indexOf(this.props.organisation.productType) > -1 && !route.isHidden && route.includeInNav;
                    });
                }
                if (filteredRotues.length > 0) {
                    // push is a function from connected-react-router for forcing a navigation change. I know, it's a weird name.
                    this.props.push({ pathname: filteredRotues[0].name });
                }
            }
        }
    }
    navigate() {
        this.hamburger.current.classList.remove('hamburger--active');
        this.navigation.current.classList.remove('navigation--active');
    }
    toggleMenu(event) {
        this.hamburger.current.classList.toggle('hamburger--active');
        this.navigation.current.classList.toggle('navigation--active');
    }
    render() {
        let filteredRotues = [];
        if (this.props.organisation && this.props.organisation.productType) {
            filteredRotues = routes.filter((route) => {
                return route.availableForClientType.indexOf(this.props.organisation.productType) > -1 && !route.isHidden && route.includeInNav;
            });
        }
        return (
            <div className="navigation" ref={this.navigation}>
                <div className="navigation__header">
                    <button
                        aria-label="Meny-knapp"
                        ref={this.hamburger}
                        className="hamburger hamburger--spin"
                        onClick={this.toggleMenu.bind(this)}
                        type="button"
                    >
                        <span className="hamburger__box">
                            <span className="hamburger__inner"></span>
                        </span>
                    </button>
                    <div className="navigation__logo-small">
                        <img src={logoImage} alt="Logotype för Factoringgruppen" />
                    </div>
                </div>
                <div className="navigation__menu">
                    <div className="navigation__top">
                        <div className="navigation__logo">
                            <img src={logoImage} alt="Logotype för Factoringgruppen" />
                        </div>
                        {filteredRotues.map((route, index) => {
                            let partiallyMatched = matchPath(this.props.location.pathname, {
                                path: route.name,
                                exact: false,
                                strict: false,
                            });
                            return (
                                <div key={index} className={partiallyMatched ? 'navigation__section navigation__section--active' : 'navigation__section'}>
                                    <NavLink
                                        onClick={this.navigate.bind(this)}
                                        to={route.name}
                                        activeClassName="navigation__item--active"
                                        className="navigation__item"
                                    >
                                        {route.notificationCounter && route.notificationCounter(this.props) > 0 ? (
                                            <div className="navigation__notification">
                                                <span className="navigation__notification-count">{route.notificationCounter(this.props)}</span>
                                            </div>
                                        ) : null}
                                        <div className="navigation__icon">
                                            <Icon name={route.icon.name} />
                                        </div>
                                        <div className="navigation__icon navigation__icon--active">
                                            <Icon name={route.icon.activeName} />
                                        </div>
                                        <span className="navigation__text">{route.linkText}</span>
                                        {route.childRoutes && route.childRoutes.length > 0 ? (
                                            <div
                                                className={this.state.openIndex === index ? 'navigation__arrow navigation__arrow--active' : 'navigation__arrow'}
                                            >
                                                <Icon name="down" />
                                            </div>
                                        ) : null}
                                    </NavLink>
                                    {partiallyMatched && route.childRoutes && route.childRoutes.length > 0 ? (
                                        <div className="navigation__sub-menu">
                                            {route.childRoutes.map((childRoute, childRouteIndex) => {
                                                if (
                                                    childRoute.availableForClientType.indexOf(this.props.organisation.productType) > -1 &&
                                                    !childRoute.isHidden &&
                                                    childRoute.includeInNav
                                                ) {
                                                    return (
                                                        <NavLink
                                                            key={`childroute-${childRouteIndex}`}
                                                            onClick={this.navigate.bind(this)}
                                                            to={childRoute.name}
                                                            activeClassName="navigation__item--active"
                                                            className="navigation__item"
                                                        >
                                                            <span className="navigation__text">
                                                                {childRoute.linkText}
                                                                {childRoute.notificationCounter && childRoute.notificationCounter(this.props) > 0 ? (
                                                                    <div className="navigation__notification navigation__notification--child">
                                                                        <span className="navigation__notification-count">
                                                                            {childRoute.notificationCounter(this.props)}
                                                                        </span>
                                                                    </div>
                                                                ) : null}
                                                            </span>
                                                        </NavLink>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                    <div className="navigation__bottom">
                        <UserSettings toggleMenu={this.toggleMenu.bind(this)} />
                    </div>
                </div>
                <div className="navigation__close-handler" onClick={this.toggleMenu.bind(this)} />
                <div className="navigation__content">{this.props.children}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        organisation: state.organisation,
        oidc: state.oidc,
        upload: state.upload,
        router: state.router,
        import: state.import,
    };
};

Navigation = connect(mapStateToProps, {
    push,
    organisationGetCommitments,
    organisationChooseCommitment,
})(Navigation);

export default Navigation;
