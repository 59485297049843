import { RECIPIENTS_UPDATE, RECIPIENTS_CLEAR_ERRORS, RECIPIENTS_CLEAR } from '../actions/index';
import { USER_EXPIRED, SILENT_RENEW_ERROR } from 'redux-oidc';
const initialState = {
    recipients: [],
    loading: false,
    query: null,
    page: 1,
    size: 20,
    totalHits: 0,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case RECIPIENTS_UPDATE:
            return { ...state, ...action.payload };
        case RECIPIENTS_CLEAR_ERRORS:
            delete state.createError;
            delete state.createErrorText;
            return { ...state };
        case RECIPIENTS_CLEAR:
            return initialState;
        case USER_EXPIRED:
        case SILENT_RENEW_ERROR:
            return {
                ...initialState,
            };
        default:
            return { ...state };
    }
}
