export const LOGIN_STATUS = 'LOGIN_STATUS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_DEMO = 'LOGIN_DEMO';

export const loginStatus = (status) => ({
    type: LOGIN_STATUS,
    payload: status,
});

export const loginSuccess = (status) => ({
    type: LOGIN_SUCCESS,
    payload: status,
});

export const loginDemo = (username, password) => ({
    type: LOGIN_DEMO,
    payload: {
        username,
        password,
    },
});
