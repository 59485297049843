// type PresentationalProps = {
//   dragging: boolean;
//   file: File | null;
//   onSelectFileClick: () => void;
//   onDrag: (event: React.DragEvent<HTMLDivElement>) => void;
//   onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
//   onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void;
//   onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
//   onDragEnter: (event: React.DragEvent<HTMLDivElement>) => void;
//   onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
//   onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
// };
import React from 'react';
import Icon from '../../../global/Icon';

// This was an initial attempt to code using presentational components.
// It did not result in me coding using this pattern since it felt redundant.
const FileUploaderPresentationalComponent = (props) => {
    const { dragging, error, errorText, onSelectFileClick, onDrag, onDragStart, onDragEnd, onDragOver, onDragEnter, onDragLeave, onDrop, disabled } = props;

    let uploaderClasses = 'upload-file';
    if (dragging) {
        uploaderClasses += ' upload-file--dragging';
    } else if (error) {
        uploaderClasses += ' upload-file--error';
    }

    let uploadButtonText = 'Eller klicka här';
    let label = document.querySelector('.upload-file__label');
    let dragDropAreaVisible = true;
    if (label != null) {
        let labelStyle = window.getComputedStyle(label);
        if (labelStyle != null && labelStyle.display === 'none') {
            uploadButtonText = 'Ladda upp';
            dragDropAreaVisible = false;
        }
    }

    return (
        <div className={uploaderClasses}>
            <label
                className={disabled ? 'upload-file__label upload-file__label--disabled' : 'upload-file__label'}
                htmlFor="upload-file"
                onDrag={onDrag}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                {props.children}
                <div className="upload-file__icon">
                    <Icon name="upload" />
                </div>
                {dragging ? (
                    <div className="data upload-file__text upload-file__text--dragging">Släpp filerna för att starta uppladdning</div>
                ) : (
                    <div className="data upload-file__text">Dra och släpp för att ladda upp</div>
                )}
                {errorText && dragDropAreaVisible ? <div className="upload-file__text upload-file__text--error caption">{errorText}</div> : null}                
            </label>
            <button className={disabled ? 'button button--function upload-file__button button--disabled' : 'button button--function upload-file__button'}
             onClick={onSelectFileClick}>                
                {uploadButtonText}
            </button>
            {errorText && !dragDropAreaVisible ? <div className="upload-file__text upload-file__text--error caption">{errorText}</div> : null}
        </div>
    );
};

export default FileUploaderPresentationalComponent;
