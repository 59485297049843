import { combineEpics } from 'redux-observable';
import { modalEpic } from './modalEpic';
import { oidcTimeOutEpic, oidcUserFoundEpic } from './oidcEpic';

import {
    uploadUploadFileListEpic,
    uploadUploadAttachmentListEpic,
    uploadFindExistingUploadsEpic,
    uploadRemoveFromListEpic,
    uploadGetResultEpic,
    uploadUploadSuccessEpic,
    uploadInvoicesTransferEpic,
    uploadUploadRemoveAttachmentEpic,
} from './uploadEpic';

import {
    uploadFetchInterpreterDataEpic,
    uploadValidateInvoiceEpic,
    uploadInitDeterminationEpic,
    uploadGetDeterminationEpic,
    uploadFinalizeSellEpic,
    uploadFinalizeSellTryAgainEpic,
    uploadContactSupportEpic,
    uploadGetConfirmation,
} from './uploadAtivoEpic';

import { loginSuccessEpic, loginDemoEpic } from './loginEpic';

import { historyInitEpic, historyPaginateEpic, historyFilterEpic, historyGetDetailsEpic } from './historyEpic';

import {
    organisationGetCommitmentsEpic,
    organisationUpdateEpic,
    organisationChooseCommitmentEpic,
    organisationGetTasksEpic,
    organisationAddContactDetailsEpic,
    organisationGetSettingsEpic,
} from './organisationEpic';

import { recipientsInitEpic, recipientsFilterEpic, recipientsPaginateEpic } from './recipientsEpic';

import { socketInitEpic, socketSetupMuliplexEpic, socketCloseEpic } from './socketEpic';

import {
    transfercostsFetchListEpic,
    transfercostsRecordDetailsFetchEpic,
    transfercostsRecordDetailsDowloadDocEpic,
    transfercostRecordDetailsDownloadSieEpic,
} from './transfercostsEpic';

import { maturityStructureFetchEpic } from './maturityStructureEpic';

import { importGetNonImportedEpic, importInvoicesTransferEpic } from './importEpic';
import {
    getNotificationSettingsEpic,
    getUserSettingsEpic,
    updateUserSettingEpic,
    updateNotificationSettingEpic,
    getIntegrationSettingsEpic,
    saveIntegrationSettingsEpic,
} from './settingsEpic';

import {
    pushNotificationDeclineEpic,
    pushNotificationGetSettingEpic,
    pushNotificationSaveTokenEpic,
    pushNotificationResetAnswerEpic,
} from './pushNotificationEpic';

import { checkForNewVersionEpic } from './versionCheckEpic';

import { notificationRouteFetchEpic } from './notificationEpic';

import {
    integrationSetCompleteEpic,
    integrationHasCompletedEpic,
    integrationInitiateOnboardingEpic,
} from './integrationEpic';

export const rootEpic = combineEpics(
    // Upload
    uploadUploadFileListEpic,
    uploadUploadAttachmentListEpic,
    uploadFindExistingUploadsEpic,
    uploadRemoveFromListEpic,
    uploadGetResultEpic,
    uploadFetchInterpreterDataEpic,
    uploadValidateInvoiceEpic,
    uploadUploadSuccessEpic,
    uploadInitDeterminationEpic,
    uploadGetDeterminationEpic,
    uploadFinalizeSellEpic,
    uploadFinalizeSellTryAgainEpic,
    uploadContactSupportEpic,
    uploadGetConfirmation,
    uploadInvoicesTransferEpic,
    uploadUploadRemoveAttachmentEpic,
    // Modal
    modalEpic,
    // Login
    loginSuccessEpic,
    loginDemoEpic,
    // Organisation
    organisationGetCommitmentsEpic,
    organisationUpdateEpic,
    organisationChooseCommitmentEpic,
    organisationGetTasksEpic,
    organisationAddContactDetailsEpic,
    organisationGetSettingsEpic,
    // oidc
    oidcTimeOutEpic,
    oidcUserFoundEpic,
    // history
    historyInitEpic,
    historyPaginateEpic,
    historyFilterEpic,
    historyGetDetailsEpic,
    // Recipients
    recipientsInitEpic,
    recipientsFilterEpic,
    recipientsPaginateEpic,
    // Socket
    socketInitEpic,
    socketSetupMuliplexEpic,
    socketCloseEpic,
    // Transfercosts
    transfercostsFetchListEpic,
    transfercostsRecordDetailsFetchEpic,
    transfercostsRecordDetailsDowloadDocEpic,
    transfercostRecordDetailsDownloadSieEpic,
    // Maturity Structure
    maturityStructureFetchEpic,
    // Import/Integration
    importGetNonImportedEpic,
    importInvoicesTransferEpic,
    // Settings
    getNotificationSettingsEpic,
    getUserSettingsEpic,
    updateUserSettingEpic,
    updateNotificationSettingEpic,
    getIntegrationSettingsEpic,
    saveIntegrationSettingsEpic,
    // Push Notification
    pushNotificationGetSettingEpic,
    pushNotificationDeclineEpic,
    pushNotificationSaveTokenEpic,
    pushNotificationResetAnswerEpic,
    // Version Check
    checkForNewVersionEpic,
    // Notification
    notificationRouteFetchEpic,
    // Integration
    integrationSetCompleteEpic,
    integrationHasCompletedEpic,
    integrationInitiateOnboardingEpic
);
