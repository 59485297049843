export const IMPORT_UPDATE = 'IMPORT_UPDATE';
export const IMPORT_UPDATE_LIST = 'IMPORT_UPDATE_LIST';
export const IMPORT_INVOICES_TRANSFER = 'IMPORT_INVOICES_TRANSFER';
export const IMPORT_INVOICE_SET_SERVICE = 'IMPORT_INVOICE_SET_SERVICE';
export const IMPORT_INVOICE_SET_REMOVE = 'IMPORT_INVOICE_SET_REMOVE';
export const IMPORT_INVOICE_ADD_COMMENT = 'IMPORT_INVOICE_ADD_COMMENT';
export const IMPORT_INVOICE_UPDATE_EMAIL = 'IMPORT_INVOICE_UPDATE_EMAIL';
export const IMPORT_GET_NON_IMPORTED = 'IMPORT_GET_NON_IMPORTED';

export const importUpdate = (state) => ({
    type: IMPORT_UPDATE,
    payload: state,
});

export const importUpdateList = (invoices) => ({
    type: IMPORT_UPDATE_LIST,
    payload: invoices,
});

export const importInvoicesTransfer = (state) => ({
    type: IMPORT_INVOICES_TRANSFER,
    payload: state,
});

export const importInvoiceSetService = (invoice, id) => ({
    type: IMPORT_INVOICE_SET_SERVICE,
    payload: {
        invoice,
        id,
    },
});

export const importInvoiceSetRemove = (invoice, id) => ({
    type: IMPORT_INVOICE_SET_REMOVE,
    payload: {
        invoice,
        id,
    },
});

export const importInvoiceAddComment = (comment, id) => ({
    type: IMPORT_INVOICE_ADD_COMMENT,
    payload: {
        comment,
        id,
    },
});

export const importInvoiceUpdateEmail = (email, id) => ({
    type: IMPORT_INVOICE_UPDATE_EMAIL,
    payload: {
        email,
        id,
    },
});

export const importGetNonImported = () => ({
    type: IMPORT_GET_NON_IMPORTED,
    payload: {},
});
