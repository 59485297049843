import { MODAL_TRIGGER } from '../actions/index';

const initialState = {
    isOpen: false,
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case MODAL_TRIGGER:
            return {
                ...state,
                isOpen: action.payload.isOpen,
                component: action.payload.component,
                persist: action.payload.persist,
                passedProps: action.payload.passedProps,
            };
        default:
            return state;
    }
}
