import { of, concat } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { isSafari, isAndroidWebView } from '../global/Helpers';
import { ajax } from 'rxjs/ajax';
import {
    PUSH_NOTIFICATION_DECLINE,
    PUSH_NOTIFICATION_GET_SETTINGS,
    PUSH_NOTIFICATION_SAVE_TOKEN,
    PUSH_NOTIFICATION_RESET_ANSWER,
    pushNotificationUpdateSetting,
} from '../actions';

let endpoint = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    endpoint = process.env.REACT_APP_API_URL;
}
const mutePush = !isAndroidWebView() && isSafari && !isPushApiSupported();
export const pushNotificationGetSettingEpic = (action$, store) =>
    action$.ofType(PUSH_NOTIFICATION_GET_SETTINGS).pipe(
        switchMap(() => {
            let declined = localStorage.getItem('notificationDeclined' + getHashedSub(store)) === 'true';
            let granted = localStorage.getItem('notificationGranted' + getHashedSub(store)) === 'true';
            return of(
                pushNotificationUpdateSetting({
                    showPush: !declined && !granted && !mutePush,
                    mutePush: mutePush,
                    hasSavedUserToken: localStorage.getItem('hasSavedUserToken' + getHashedSub(store)) === 'true',
                })
            );
        })
    );

export const pushNotificationDeclineEpic = (action$, store) =>
    action$.ofType(PUSH_NOTIFICATION_DECLINE).pipe(
        switchMap(() => {
            localStorage.setItem('notificationDeclined' + getHashedSub(store), true);
            return of(pushNotificationUpdateSetting({ declined: true }));
        })
    );

export const pushNotificationResetAnswerEpic = (action$, store) =>
    action$.ofType(PUSH_NOTIFICATION_RESET_ANSWER).pipe(
        switchMap(() => {
            localStorage.removeItem('notificationDeclined' + getHashedSub(store));
            return of(pushNotificationUpdateSetting({ declined: false, showPush: true }));
        })
    );

export const pushNotificationSaveTokenEpic = (action$, store) =>
    action$.ofType(PUSH_NOTIFICATION_SAVE_TOKEN).pipe(
        switchMap((action) => {
            if (action.payload.error) {
                return of(
                    pushNotificationUpdateSetting({
                        saving: false,
                        error: { type: 'SAVEFAIL' },
                    })
                );
            }
            let { oidc } = store.value;
            const request = {
                notificationToken: action.payload.userToken,
            };

            localStorage.setItem('notificationGranted' + getHashedSub(store), true);
            localStorage.setItem('hasSavedUserToken' + getHashedSub(store), true);
            return concat(
                of(pushNotificationUpdateSetting({ saving: true })),
                ajax
                    .post(`${endpoint}api/user/notificationtoken`, request, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        switchMap((response) => {
                            if (response.status === 200) {
                                return ajax
                                    .post(`${endpoint}api/user/testsendnotification`, request, {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${oidc.user.access_token}`,
                                        'organisation-number': store.value.organisation.organisationNumber,
                                    })
                                    .pipe(
                                        switchMap(() => {
                                            return of(
                                                pushNotificationUpdateSetting({
                                                    saving: false,
                                                    showPush: false,
                                                    userToken: action.payload.userToken,
                                                    hasSavedUserToken: true,
                                                })
                                            );
                                        })
                                    );
                            }
                            return of(
                                pushNotificationUpdateSetting({
                                    saving: false,
                                    showPush: false,
                                    userToken: action.payload.userToken,
                                })
                            );
                        }),
                        catchError((error) => {
                            return of(
                                pushNotificationUpdateSetting({
                                    saving: false,
                                    error: { type: 'SAVEFAIL' },
                                })
                            );
                        })
                    )
            );
        })
    );
function getHashedSub(store) {
    return btoa(store.value.oidc.user.profile.sub);
}
function isPushApiSupported() {
    return 'PushManager' in window;
}
