export const HISTORY_UPDATE = 'HISTORY_UPDATE';
export const HISTORY_INIT = 'HISTORY_INIT';
export const HISTORY_INIT_ERROR = 'HISTORY_INIT_ERROR';
export const HISTORY_FILTER = 'HISTORY_FILTER';
export const HISTORY_PAGINATE = 'HISTORY_PAGINATE';
export const HISTORY_GET_DETAILS = 'HISTORY_GET_DETAILS';
export const HISTORY_CLEAR = 'HISTORY_CLEAR';

export const historyUpdate = (history) => ({
    type: HISTORY_UPDATE,
    payload: history,
});

export const historyInit = (initObj) => {
    return {
        type: HISTORY_INIT,
        payload: initObj,
    };
};

export const historyInitError = (error) => {
    return {
        type: HISTORY_INIT_ERROR,
        payload: error,
    };
};

export const historyFilter = (query, filter) => {
    return {
        type: HISTORY_FILTER,
        payload: {
            filter: filter,
            query: query,
        },
    };
};

export const historyPaginate = (pagingObj) => {
    return {
        type: HISTORY_PAGINATE,
        payload: pagingObj,
    };
};

export const historyGetDetails = (invoiceId) => {
    return {
        type: HISTORY_GET_DETAILS,
        payload: invoiceId,
    };
};

export const historyClear = (invoiceId) => {
    return {
        type: HISTORY_CLEAR,
    };
};
