import React, { Component } from 'react';
import { connect } from 'react-redux';

import { modalTrigger } from '../../actions';

class InformationModal extends Component {
    closeModal(event) {
        event.preventDefault();
        this.props.modalTrigger(false);
    }

    render() {
        let { header, body } = this.props;
        return (
            <React.Fragment>
                <div className="information-modal">
                    <h2 className="title title--h2">{header}</h2>
                    <div className="information-modal_panel">
                        <p className="paragraph">
                            <strong>{body}</strong>
                        </p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

InformationModal = connect(mapStateToProps, {
    modalTrigger,
})(InformationModal);

export default InformationModal;
