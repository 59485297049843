import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { createBrowserHistory } from 'history';

import { rootReducer } from './reducers';
import { rootEpic } from './epics';

import { createEpicMiddleware } from 'redux-observable';
import { createReduxHistoryContext } from 'redux-first-history';

import { createUserManager, loadUser } from 'redux-oidc';

import { WebStorageStateStore } from 'oidc-client';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const epicMiddleware = createEpicMiddleware();
let middlewares = [];
middlewares.push(applyMiddleware(epicMiddleware));
middlewares.push(applyMiddleware(routerMiddleware));

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

export const store = createStore(rootReducer(routerReducer), composeEnhancers(...middlewares));

export const history = createReduxHistory(store);

let userManagerConfig = {
    authority: window.location.origin,
    client_id: 'MyPages',
    redirect_uri: window.location.origin,
    response_type: 'id_token token',
    scope: 'openid profile genisys',
    post_logout_redirect_uri: window.location.origin,
    prompt: false,
    silent_redirect_uri: `${window.location.origin}/auth/silent_refresh.html`,
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: window.localStorage })
};

if (process.env.NODE_ENV === 'development') {
    userManagerConfig = {
        ...userManagerConfig,
        authority: process.env.REACT_APP_AUTH_URL,
        redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URL,
        post_logout_redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URL,
        silent_redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URL}/auth/silent_refresh.html`,
    };
}

export const userManager = createUserManager(userManagerConfig);
loadUser(store, userManager);

epicMiddleware.run(rootEpic);
