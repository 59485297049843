export const MODAL_TRIGGER = 'MODAL_TRIGGER';

export const modalTrigger = (isOpen, component, persist = false, passedProps = {}) => ({
    type: MODAL_TRIGGER,
    payload: {
        isOpen: isOpen,
        component: component,
        persist: persist,
        passedProps: passedProps,
    },
});
