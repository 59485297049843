import { LOCATION_CHANGE } from 'redux-first-history';
import { TRANSFERCOSTS_FETCH_LIST, TRANSFERCOSTS_UPDATE } from '../actions/index';
import { USER_EXPIRED, SILENT_RENEW_ERROR } from 'redux-oidc';
const initialState = {
    records: [],
    loading: false,
    filter: {
        date: {
            from: null,
            to: null,
        },
    },
    queryId: '',
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case TRANSFERCOSTS_UPDATE:
            if (action.payload === null) {
                return initialState;
            } else {
                return { ...state, ...action.payload };
            }
        case LOCATION_CHANGE:
        case USER_EXPIRED:
        case SILENT_RENEW_ERROR:
            return {
                ...initialState,
            };
        case TRANSFERCOSTS_FETCH_LIST:
        default:
            return { ...state };
    }
}
